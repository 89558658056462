import { XCircleIcon } from "@heroicons/react/20/solid";
import { Dispatch, SetStateAction, useState } from "react";

import { CodingStandard } from "../standards/Common";
import { Stage } from "../../pages/ProjectRuleSettings";
import { EditorWoSidebarLayout } from "../layouts/EditorLayout";
import { RSButton, RSButtonWhite } from "../components/RSButton";
import RuleOptionsEditor from "../containers/RuleOptionsEditor";
import { encodeToCheckRules } from "../standards/Misra";
import { api } from "../../api";

interface PreviewPageProps {
  standards: CodingStandard[];
  originalStandards: CodingStandard[];
  nameSelectedStatusMap: Map<string, boolean>;
  unknownRules: string[];
  updateStandards: Dispatch<SetStateAction<CodingStandard[]>>;
  updateStage: Dispatch<SetStateAction<Stage>>;
}

const PreviewPage: React.FC<PreviewPageProps> = ({
  standards,
  originalStandards,
  nameSelectedStatusMap,
  unknownRules,
  updateStandards,
  updateStage,
}) => {
  const mergedStandards = originalStandards
    .filter((standard) => !nameSelectedStatusMap.get(standard.name))
    .concat(standards);
  const checkRules =
    (unknownRules.length !== 0 ? unknownRules.join("\n") + "\n" : "") +
    encodeToCheckRules(mergedStandards);
  const path = window.location.pathname;
  const params = new URLSearchParams(window.location.search);
  const projectID = params.get(path === "/project" ? "id" : "project_id");
  if (projectID === null) {
    throw new Error("project id is missing");
  }
  const [submitError, setSubmitError] = useState("");
  const click = () => {
    api
      .NewService()
      .UpdateCheckRules({
        projectID,
        checkRules,
      })
      .then((reply) => {
        if (reply.status === "ok") {
          window.location.assign(`/project?id=${projectID}`);
        } else if (reply.status === "error") {
          setSubmitError("保存失败：" + reply.reason);
        }
      })
      .catch((err) => {
        console.error(err);
        setSubmitError("网络错误");
      });
  };

  return (
    <>
      <title>规则设置工具 - 预览</title>
      <EditorWoSidebarLayout
        editor={
          <>
            <div
              className={
                submitError === "" ? "hidden" : "mb-4 rounded-md bg-red-50 p-4"
              }
            >
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircleIcon
                    className="h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-red-800">{submitError}</p>
                </div>
              </div>
            </div>
            {standards.map((standard) => (
              <RuleOptionsEditor
                key={standard.name}
                readOnly
                standard={standard}
                updateStandards={updateStandards}
              />
            ))}
          </>
        }
        footer={
          <div className="flex w-full items-center justify-between py-6 px-4">
            <div className="mt-4 mt-0 ml-5 flex space-x-6">
              <RSButtonWhite
                className="bg-white text-black hover:bg-white"
                label="上一步"
                onClick={() => updateStage("edit settings")}
                disabled={false}
              />
            </div>
            <div className="mt-4 mt-0 mr-10 flex space-x-6 text-right">
              <RSButton className="w-24" label="保存" onClick={click} />
            </div>
          </div>
        }
      />
    </>
  );
};

export default PreviewPage;
