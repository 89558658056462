import {
  Catagory,
  Decidability,
  MisraRule,
  getRulesFromChapter,
} from "../standards/Misra";
import { CodingStandard } from "../standards/Common";
import RSCheckboxIcon from "../components/RSCheckboxIcon";

interface SidebarProps {
  standards: CodingStandard[];
  showDecidability: boolean;
  onSelectedByCategory: (category: Catagory, selected: boolean) => void;
  onSelectedByDecidability: (
    decidability: Decidability,
    selected: boolean
  ) => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  standards,
  showDecidability,
  onSelectedByCategory,
  onSelectedByDecidability,
}) => {
  const countRulesWithFilter = (filter: (rule: MisraRule) => boolean) => {
    return standards.reduce(
      (accum, standard) =>
        accum +
        standard.chapters.reduce(
          (accum, chapter) =>
            accum +
            getRulesFromChapter(chapter).filter((rule) => filter(rule)).length,
          0
        ),
      0
    );
  };
  const categories: Map<Catagory, string> = new Map([
    ["Mandatory", "强制的"],
    ["Required", "必须的"],
    ["Advisory", "建议的"],
  ]);
  const decidabilities: Map<Decidability, string> = new Map([
    ["Decidable", "可判定"],
    ["Undecidable", "不可判定"],
  ]);
  return (
    <div className="flex flex-col items-center space-y-10 pt-16">
      <div className="w-[10rem] space-y-4">
        <div className="text-xl font-semibold">类别</div>
        <div className="flex flex-col space-y-2 p-3 dark:bg-slate-900">
          {Array.from(categories).map(([category, name]) => {
            const selectedRulesNumber = countRulesWithFilter(
              (rule) => rule.selected && rule.category === category
            );
            return (
              <label
                key={category}
                className="flex cursor-pointer items-center"
              >
                <input
                  className="hidden"
                  type="checkbox"
                  checked={selectedRulesNumber > 0}
                  onChange={(e) =>
                    onSelectedByCategory(category, e.target.checked)
                  }
                />
                <RSCheckboxIcon
                  max={countRulesWithFilter(
                    (rule) => rule.category === category
                  )}
                  cur={selectedRulesNumber}
                />
                <span className="pl-6 text-base text-gray-500">
                  {name} ({selectedRulesNumber})
                </span>
              </label>
            );
          })}
        </div>
      </div>
      <div
        className={"w-[10rem] space-y-4" + (showDecidability ? "" : " hidden")}
      >
        <div className="text-xl font-semibold">可判定性</div>
        <div className="flex flex-col space-y-2 p-3 dark:bg-slate-900">
          {Array.from(decidabilities).map(([decidability, name]) => {
            const selectedRulesNumber = countRulesWithFilter(
              (rule) => rule.selected && rule.decidability === decidability
            );
            return (
              <label
                key={decidability}
                className="flex cursor-pointer items-center"
              >
                <input
                  className="hidden"
                  type="checkbox"
                  checked={selectedRulesNumber > 0}
                  onChange={(e) =>
                    onSelectedByDecidability(decidability, e.target.checked)
                  }
                />
                <RSCheckboxIcon
                  max={countRulesWithFilter(
                    (rule) => rule.decidability === decidability
                  )}
                  cur={selectedRulesNumber}
                />
                <span className="pl-6 text-base text-gray-500">
                  {name} ({selectedRulesNumber})
                </span>
              </label>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
