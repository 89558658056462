/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import { XCircleIcon } from "@heroicons/react/20/solid";
import { FormEvent, useState } from "react";
import { PrimaryButton } from "../uilib/buttons";
import { HelpText, TextField } from "../uilib/forms";
import { MainContentWithTitle } from "../uilib/layouts";
import { HomeLayout } from "../components/ProjectLayout";

interface APIPortalCreateUser {
  buildbotPublicKey: string;
  webhookProxy: string;
  gerritSupportEnabled: boolean;
  gitlabSupportEnabled: boolean;
  githubSupportEnabled: boolean;
}

declare global {
  interface Window {
    apiPortalCreateUser: APIPortalCreateUser;
  }
}

function CreateUser() {
  const [userName, setUserName] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userEmailError, setUserEmailError] = useState("");
  const [userFullName, setUserFullName] = useState<Map<string, string>>(
    new Map<string, string>([
      ["FirstNameZH", ""],
      ["LastNameZH", ""],
      ["FirstNameEN", ""],
      ["LastNameEN", ""],
    ])
  );
  const [userFullNameError, setUserFullNameError] = useState<
    Map<string, string>
  >(
    new Map<string, string>([
      ["FirstNameZH", ""],
      ["LastNameZH", ""],
      ["FirstNameEN", ""],
      ["LastNameEN", ""],
    ])
  );
  const nameMap = new Map<string, string>([
    ["LastNameZH", "中文姓"],
    ["FirstNameZH", "中文名"],
    ["LastNameEN", "英文姓"],
    ["FirstNameEN", "英文名"],
  ]);
  const [submitPending, setSubmitPending] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let hasErrors = false;
    let reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/;
    if (userName === "") {
      setUserNameError("请填写用户名称");
      hasErrors = true;
    } else if (userName.length > 255) {
      setUserNameError("用户名称的长度不能超过255");
      hasErrors = true;
    }

    if (userEmail === "") {
      setUserEmailError("请填写工作邮箱");
      hasErrors = true;
    } else if (!reg.test(userEmail)) {
      setUserEmailError("邮箱格式有误");
      hasErrors = true;
    }

    Array.from(userFullName).forEach(([key, value]) => {
      if (value === "") {
        setUserFullNameError((old) => {
          let _old = new Map();
          old.forEach((val, key) => _old.set(key, val));
          _old.set(key, "请填写" + nameMap.get(key));
          return _old;
        });
      } else if (value!.length > 255) {
        setUserFullNameError((old) => {
          let _old = new Map();
          old.forEach((val, key) => _old.set(key, val));
          _old.set(key, nameMap.get(key) + "的长度不能超过255");
          return _old;
        });
      } else {
        setUserFullNameError((old) => {
          let _old = new Map();
          old.forEach((val, key) => _old.set(key, val));
          _old.set(key, "");
          return _old;
        });
      }
    });

    if (hasErrors) {
      return;
    }
    setSubmitPending(true);
    // TODO: call API to create users
    setSubmitError("服务暂不可用");
  };

  return (
    <HomeLayout>
      <MainContentWithTitle title="新建用户">
        <form className="space-y-8 divide-y divide-gray-200" onSubmit={submit}>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
            <>
              <div className="sm:col-span-4">
                <TextField
                  id="user-name"
                  value={userName}
                  onChange={setUserName}
                  error={userNameError}
                  setError={setUserNameError}
                >
                  用户名称
                </TextField>
              </div>
              <div className="hidden sm:col-span-8 sm:block"></div>
              <div className="sm:col-span-4">
                <TextField
                  id="user-email"
                  value={userEmail}
                  onChange={setUserEmail}
                  error={userEmailError}
                  setError={setUserEmailError}
                >
                  工作邮箱
                </TextField>
              </div>
              <div className="hidden sm:col-span-8 sm:block"></div>
              {Array.from(nameMap).map(([key, value]) => {
                return (
                  <>
                    <div className="sm:col-span-4">
                      <TextField
                        id={key}
                        value={userFullName.get(key)!}
                        onChange={(newval: string) => {
                          setUserFullName((old) => {
                            let _old = new Map();
                            old.forEach((val, k) => _old.set(k, val));
                            _old.set(key, newval);
                            return _old;
                          });
                        }}
                        error={userFullNameError.get(key)}
                      >
                        {value}
                      </TextField>
                      {key === "LastNameEN" ? (
                        <HelpText>
                          <div>因部分系统不支持中文，故需填写英文名</div>
                        </HelpText>
                      ) : null}
                    </div>
                    {key === "FirstNameZH" ? (
                      <div className="hidden sm:col-span-4 sm:block"></div>
                    ) : null}
                  </>
                );
              })}
            </>
          </div>

          <div className="pt-5">
            <div
              className={
                submitError === "" ? "hidden" : "mb-4 rounded-md bg-red-50 p-4"
              }
            >
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircleIcon
                    className="h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-red-800">{submitError}</p>
                </div>
              </div>
            </div>
            <div className="flex">
              <PrimaryButton type="submit" pending={submitPending}>
                新建
              </PrimaryButton>
            </div>
          </div>
        </form>
      </MainContentWithTitle>
    </HomeLayout>
  );
}

export default CreateUser;
