import React, { useEffect } from "react";
import { Dispatch, SetStateAction } from "react";

import { CodingStandard, CodingStandardChapter } from "../standards/Common";
import {
  MisraRule,
  getRulesFromChapter,
  GetNameSelectedStatusMap,
} from "../standards/Misra";
import { Stage } from "../../pages/ProjectRuleSettings";
import PreviewPage from "./PreviewPage";
import RuleSelectionPage from "./RuleSelectionPage";
import SettingsEditorPage from "./SettingsEditorPage";
import { decodeFromCheckRules } from "../standards/Misra";
import SetSelectionPage from "./SetSelectionPage";

interface RulesettingsProps {
  stage: Stage;
  updateStage: Dispatch<SetStateAction<Stage>>;
  cweEnabled: boolean;
  certEnabled: boolean;
  gjbEnabled: boolean;
}

const Rulesettings: React.FC<RulesettingsProps> = ({
  stage,
  updateStage,
  cweEnabled,
  certEnabled,
  gjbEnabled,
}) => {
  const [initialized, setInitialized] = React.useState<Boolean>(false);

  const [standards, setStandards] = React.useState<CodingStandard[]>([]);
  const [originalStandards, setOriginalStandards] = React.useState<
    CodingStandard[]
  >([]);
  const [unknownRules, setUnknownRules] = React.useState<string[]>([]);

  const [nameSelectedStatusMap, setNameSelectedStatusMap] = React.useState<
    Map<string, boolean>
  >(new Map<string, boolean>());

  const [numLastSelectedRules, setNumLastSelectedRules] = React.useState<
    Map<string, number>
  >(new Map<string, number>());
  const [numTotalRules, setNumTotalRules] = React.useState<Map<string, number>>(
    new Map<string, number>()
  );

  const countRulesWithFilter = (
    standard: CodingStandard,
    filter: (rule: MisraRule) => boolean
  ) => {
    return standard.chapters.reduce(
      (accum, chapter) =>
        accum +
        getRulesFromChapter(chapter).filter((rule) => filter(rule)).length,
      0
    );
  };

  if (!initialized) {
    const [currentStandards, currentUnknownRules] = decodeFromCheckRules(
      window.apiPortalProjectRuleSettings.checkRules
    ) as [CodingStandard[], string[]];
    setOriginalStandards([...currentStandards]);
    setUnknownRules([...currentUnknownRules]);
    setInitialized(true);
    setNameSelectedStatusMap(GetNameSelectedStatusMap(standards));
  }

  useEffect(() => {
    originalStandards.forEach((standard: CodingStandard) => {
      setNumLastSelectedRules(
        (previous) =>
          new Map(
            previous.set(
              standard.name,
              countRulesWithFilter(standard, (r) => r.selected)
            )
          )
      );
      setNumTotalRules(
        (previous) =>
          new Map(
            previous.set(
              standard.name,
              countRulesWithFilter(standard, (r) => true)
            )
          )
      );
    });
    setStandards(originalStandards);
  }, [originalStandards]);

  const onSelectRule = (rule: MisraRule, selected: boolean) => {
    rule.selected = selected;
    setStandards([...standards]);
  };

  const onSelectChapter = (
    chapter: CodingStandardChapter,
    selected: boolean
  ) => {
    getRulesFromChapter(chapter).forEach((rule) => (rule.selected = selected));
    setStandards([...standards]);
  };

  const onSelectStandard = (standard: CodingStandard, selected: boolean) => {
    standard.chapters.forEach((chapter) =>
      getRulesFromChapter(chapter).forEach((rule) => (rule.selected = selected))
    );
    setStandards([...standards]);
  };

  const onSelectStandardToSet = (
    standard: CodingStandard,
    selected: boolean
  ) => {
    const updatedNameSelectedStatusMap = new Map(nameSelectedStatusMap);
    updatedNameSelectedStatusMap.set(standard.name, selected);
    setNameSelectedStatusMap(updatedNameSelectedStatusMap);
  };

  // TODO: use HashRouter to support "Go back one page"
  return (
    <div>
      {stage === "select sets" && (
        <SetSelectionPage
          standards={standards}
          nameSelectedStatusMap={nameSelectedStatusMap}
          numLastSelectedRules={numLastSelectedRules}
          numTotalRules={numTotalRules}
          cweEnabled={cweEnabled}
          certEnabled={certEnabled}
          gjbEnabled={gjbEnabled}
          updateStage={updateStage}
          onSelectStandardToSet={onSelectStandardToSet}
        />
      )}
      {stage === "select rules" && (
        <RuleSelectionPage
          standards={standards.filter((standard) =>
            nameSelectedStatusMap.get(standard.name)
          )}
          originalStandards={originalStandards}
          nameSelectedStatusMap={nameSelectedStatusMap}
          updateStandards={setStandards}
          updateStage={updateStage}
          onSelectRule={onSelectRule}
          onSelectChapter={onSelectChapter}
          onSelectStandard={onSelectStandard}
        />
      )}
      {stage === "edit settings" && (
        <SettingsEditorPage
          standards={standards.filter((standard) =>
            nameSelectedStatusMap.get(standard.name)
          )}
          updateStandards={setStandards}
          updateStage={updateStage}
          onSelectRule={onSelectRule}
          onSelectChapter={onSelectChapter}
          onSelectStandard={onSelectStandard}
        />
      )}
      {stage === "preview" && (
        <PreviewPage
          standards={standards.filter((standard) =>
            nameSelectedStatusMap.get(standard.name)
          )}
          originalStandards={originalStandards}
          nameSelectedStatusMap={nameSelectedStatusMap}
          unknownRules={unknownRules}
          updateStage={updateStage}
          updateStandards={setStandards}
        />
      )}
    </div>
  );
};

export default Rulesettings;
