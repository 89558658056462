import { CodingStandard, CodingStandardChapter, Section } from "./Common";

export type Catagory = "Mandatory" | "Required" | "Advisory" | "Document";
export type Decidability = "Decidable" | "Undecidable";

// apply to Misra/Google/CWE
export interface MisraRule {
  id: string;
  ident: string;
  link?: string; // for google cpp
  category: Catagory;
  decidability: Decidability;
  scope?: "Single Translation Unit" | "System";
  appliesToC90?: boolean;
  appliesToC99?: boolean;
  docsId: string;
  subject: string;
  support: boolean;
  selected: boolean;
  visible: boolean;
  options: MisraRuleOptions;
}

// apply to both Misra and Google
interface MisraRuleOptions {
  caseSensitive?: RuleOption<boolean>;
  standard?: RuleOption<"C90" | "C99">;
  externalIdentifierSignificance?: RuleOption<number>;
  maxLoop?: RuleOption<number>;
  aggressiveMode?: RuleOption<boolean>;
  CSAAnalyzerInliningMode?: RuleOption<boolean>;
  maximumInlineFuncLine?: RuleOption<number>;
  maximumAllowedFuncLine?: RuleOption<number>;
  maximumAllowedReturnNum?: RuleOption<number>;
  projectName?: RuleOption<string>;
  rootDir?: RuleOption<string>;
}

export interface RuleOption<Type> {
  key: string;
  defaultValue: Type | null;
  previousValue: Type | null;
  currentValue: Type | null;
  affectedBy?: string;
  apply?: boolean;
  previousApply?: boolean;
}

export const getRulesFromChapter = (c: CodingStandardChapter) => {
  var rules: MisraRule[] = [];
  if (c.rules !== undefined) {
    // MISRA C:2012/Google/CWE/GJB5367/GJB8114
    rules = c.rules;
  } else {
    if (c.sections !== undefined) {
      // nested rules like MISRA C++:2008
      c.sections.forEach((section) => {
        rules = rules.concat(section.rules);
      });
    }
  }
  return rules;
};

export const GetNameSelectedStatusMap = (standards: CodingStandard[]) => {
  const selectedStatusMap = new Map<string, boolean>();
  standards.forEach((standard) => selectedStatusMap.set(standard.name, false));
  return selectedStatusMap;
};

export const encodeToCheckRules = (standards: CodingStandard[]) => {
  return standards
    .reduce(
      (accum, standard) =>
        accum +
        standard.chapters
          .flatMap((c) =>
            getRulesFromChapter(c)
              .filter((r) => r.selected)
              .map((r) => {
                const options = (
                  Object.entries(r.options) as [string, RuleOption<any>][]
                ).reduce((obj, [_, option]) => {
                  if (
                    !(
                      option.key === "max-report-num" ||
                      option.key === "project-name" ||
                      option.key === "root-dir" ||
                      option.key === "severity"
                    ) ||
                    option.apply
                  ) {
                    return {
                      ...obj,
                      [option.key]: option.currentValue ?? option.defaultValue,
                    };
                  }
                  return obj;
                }, {});
                return `${r.id}${
                  Object.keys(options).length === 0
                    ? ""
                    : " " + JSON.stringify(options)
                }`;
              })
          )
          .join("\n") +
        "\n",
      ""
    )
    .trim();
};

export const decodeFromCheckRules = (data: string) => {
  const standards: CodingStandard[] = [
    require("./MisraCStandard.json"),
    require("./MisraCPPStandard.json"),
    require("./GoogleCPPStandard.json"),
    require("./CERTStandard.json"),
    require("./CWEStandard.json"),
    require("./AutosarStandard.json"),
    require("./GJB5369Standard.json"),
    require("./GJB8114Standard.json"),
    require("./GOStandard.json"),
    require("./TypeScriptStandard.json"),
    require("./JavaScriptStandard.json"),
  ];
  standards.forEach((standard) =>
    standard.chapters.forEach((chapter: CodingStandardChapter) => {
      if (chapter.rules !== undefined) {
        // MISRA C:2012/Google/CWE/GJB5367/GJB8114
        chapter.rules = chapter.rules.filter((rule: MisraRule) => rule.support);
      } else {
        if (chapter.sections !== undefined) {
          // nested rules like MISRA C++:2008
          chapter.sections.forEach((section: Section) => {
            section.rules = section.rules.filter(
              (rule: MisraRule) => rule.support
            );
            section.rules.forEach((rule: MisraRule) => {
              if (rule.category === "Document") {
                rule.category = "Mandatory";
              }
            });
          });
        }
      }
    })
  );
  const unknownRules: string[] = [];
  const allRules = standards.flatMap((s) =>
    s.chapters.flatMap((c) => getRulesFromChapter(c))
  );
  data.split(/\r?\n/).forEach((line) => {
    if (line.length === 0) return;
    // For `misra_2012/rule_1_1 {"opt":"val"}`
    const ruleId =
      line.indexOf(" ") > 0 ? line.substring(0, line.indexOf(" ")) : line;
    const jsonOption =
      line.indexOf(" ") > 0 ? line.substring(line.indexOf(" ") + 1).trim() : "";
    const rule = allRules.find((r) => r.id === ruleId);
    if (rule) rule.selected = true;
    else unknownRules.push(line);
    if (rule && jsonOption.length > 0) {
      const optionObj: any = JSON.parse(jsonOption);
      (
        Object.entries(optionObj) as [
          string, // Option Key
          any // Option Value
        ][]
      ).forEach(([key, value]) => {
        (
          Object.entries(rule.options) as [
            string, // Name
            RuleOption<any>
          ][]
        ).forEach(([_, option]) => {
          if (option.key === key) {
            option.currentValue = value;
            option.previousValue = value;
            if (
              key === "max-report-num" ||
              key === "project-name" ||
              key === "root-dir" ||
              key === "severity"
            ) {
              option.previousApply = true;
              option.apply = true;
            }
          }
        });
      });
    }
  });
  return [standards, unknownRules];
};
