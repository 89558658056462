import { CodingStandard } from "../standards/Common";
import cwelogo from "../../uilib/cwe_logo.jpg";
import { decodeFromCheckRules } from "../standards/Misra";
import { HomeLayout } from "../../components/ProjectLayout";

function CWEDocumentation() {
  const standards = decodeFromCheckRules("") as [CodingStandard[], string[]];
  const cweStandards = standards[0].filter(
    (standard) => standard.name === "CWE"
  );

  return (
    <HomeLayout>
      <div className="my-6 flex h-[49rem] w-full flex-col divide-y rounded-lg bg-white dark:text-slate-200">
        <div className="flex-grow overflow-y-auto p-6">
          <div className="mb-4 flex flex-col items-center bg-slate-50 py-12 pl-4">
            <div className="w-4/5">
              <img
                src={cwelogo}
                width="153"
                height="55"
                className="float-left mr-[6px] border-0"
                alt="CWE"
              />
              <h1 className="mx-[0.1em] my-auto text-2xl font-bold text-[#314a8d]">
                Common Weakness Enumeration
              </h1>
              <p className="my-auto mt-[0.1em] font-serif text-base italic text-[#314a8d]">
                A Community-Developed List of Software &amp; Hardware Weakness
                Types
              </p>
            </div>
            <div className="mt-4 w-3/4">
              <p className="my-4">CWE简介：</p>
              <p className="my-4 text-xs">
                CWE是社区开发的软件和硬件弱点类型列表。它是一种通用语言，是安全工具的衡量标准，也是弱点识别、缓解和预防工作的基准
              </p>
              <p className="my-4">CWE官网：</p>
              <p className="my-4 text-xs text-[#314a8d]">
                <a href="https://cwe.mitre.org/index.html">
                  https://cwe.mitre.org/index.html
                </a>
              </p>
            </div>
          </div>
          {cweStandards[0]!.chapters[0]!.rules!.map((rule) => (
            <tr key={rule.id}>
              <td className="h-12 justify-between py-3 pl-4 text-[#102A43] hover:text-sky-600 dark:text-slate-200 dark:hover:bg-[#2A5F90] dark:hover:text-slate-200">
                <a
                  href={`https://cwe.mitre.org/data/definitions/${rule.ident.substring(
                    4
                  )}.html`}
                  className="inline-block flex h-full w-full cursor-pointer items-center"
                >
                  {`${rule.ident} ${rule.subject}`}
                </a>
              </td>
            </tr>
          ))}
        </div>
      </div>
    </HomeLayout>
  );
}

export default CWEDocumentation;
