import React, { Dispatch, SetStateAction } from "react";

import { CodingStandard, CodingStandardChapter } from "../standards/Common";
import { MisraRule, getRulesFromChapter } from "../standards/Misra";
import RSCheckboxIcon from "../components/RSCheckboxIcon";
import RSArrorIcon from "../components/RSArrorIcon";

interface RuleSetProps {
  chapter: CodingStandardChapter;
  readOnly?: boolean;
  onSelectRule: (rule: MisraRule, selected: boolean) => void;
  onSelectChapter: (chapter: CodingStandardChapter, selected: boolean) => void;
  rulesToApply?: Map<string, boolean>;
  setFocusedRuleId?: Dispatch<SetStateAction<string | null>>;
}

const RuleSet: React.FC<RuleSetProps> = ({
  chapter,
  readOnly,
  onSelectRule,
  onSelectChapter,
  rulesToApply,
  setFocusedRuleId,
}) => {
  const selectedRules = readOnly
    ? getRulesFromChapter(chapter).filter((r) => r.selected).length
    : getRulesFromChapter(chapter).filter((r) =>
        rulesToApply ? rulesToApply.get(r.id) : r.selected
      ).length;
  const [collapsed, setCollapsed] = React.useState(selectedRules === 0);

  const generateRuleSet = (chapter: CodingStandardChapter) => {
    if (chapter.rules !== undefined) {
      // MISRA C:2012
      return chapter.rules.map(
        (rule) =>
          rule.selected && (
            <div
              key={rule.id}
              className="flex h-8 flex-row items-center justify-between pl-2 text-gray-500 hover:text-sky-600 dark:text-slate-200 dark:hover:bg-[#2A5F90] dark:hover:text-slate-200"
              onClick={() => {
                if (!setFocusedRuleId) return;
                setFocusedRuleId((id) => {
                  // update focused rule id, or clear it if it's already focused
                  if (id === rule.id) return null;
                  return rule.id;
                });
              }}
            >
              <label
                className="flex w-full cursor-pointer items-center"
                htmlFor=""
              >
                {!readOnly && (
                  <input
                    id={rule.id}
                    className="hidden"
                    type="checkbox"
                    checked={rulesToApply && rulesToApply.get(rule.id)}
                    onChange={(e) => onSelectRule(rule, e.target.checked)}
                  />
                )}
                {!readOnly && (
                  <label className="mr-2" htmlFor={rule.id}>
                    <RSCheckboxIcon
                      max={1}
                      cur={rulesToApply && rulesToApply.get(rule.id) ? 1 : 0}
                    />
                  </label>
                )}
                <div className="group relative flex justify-center">
                  <div className="border-1 absolute bottom-[25px] z-30 hidden transform rounded-lg border border-gray-300 bg-white p-1 text-gray-500 group-hover:block">
                    {rule.ident} {rule.subject}
                  </div>
                  <div className="w-[9rem] truncate pl-2 text-gray-500 hover:text-sky-600">
                    {rule.ident} {rule.subject}
                  </div>
                </div>
              </label>
            </div>
          )
      );
    } else {
      // nested rules like MISRA C++:2008
      if (chapter.sections === undefined) return [];
      return chapter.sections.map(
        (section) =>
          section.rules.filter((r) => r.selected).length > 0 && (
            <RuleSet
              key={section.name}
              readOnly={readOnly}
              onSelectRule={onSelectRule}
              onSelectChapter={onSelectChapter}
              chapter={section}
              rulesToApply={rulesToApply}
              setFocusedRuleId={setFocusedRuleId}
            />
          )
      );
    }
  };

  return (
    <div>
      <div className="-ml-2 flex flex-row items-center gap-2 pl-2 dark:hover:bg-slate-900">
        {!readOnly && (
          <label>
            <input
              className="hidden"
              type="checkbox"
              checked={selectedRules > 0}
              onChange={(e) => {
                onSelectChapter(chapter, e.target.checked);
              }}
            />
            <RSCheckboxIcon
              max={getRulesFromChapter(chapter).length}
              cur={selectedRules}
            />
          </label>
        )}
        <div
          className="group flex h-8 w-full items-center justify-between"
          onClick={() => {
            setCollapsed(!collapsed);
          }}
        >
          <label className="inline-block flex w-full cursor-pointer items-center justify-between truncate pl-2 text-gray-600">
            {chapter.name} ({selectedRules})
            <RSArrorIcon
              className="mr-4 inline-block w-4 text-white group-hover:text-black"
              direction="down"
              color="black"
            />
          </label>
        </div>
      </div>
      <div className="ml-2 pl-2">{!collapsed && generateRuleSet(chapter)}</div>
    </div>
  );
};

interface RuleListProps {
  standard: CodingStandard;
  readOnly?: boolean;
  rulesToApply?: Map<string, boolean>;
  setRulesToApply?: Dispatch<SetStateAction<Map<string, boolean>>>;
  setFocusedRuleId?: Dispatch<SetStateAction<string | null>>;
}

const RuleList: React.FC<RuleListProps> = ({
  standard,
  readOnly,
  rulesToApply,
  setRulesToApply,
  setFocusedRuleId,
}) => {
  const selectedRules = readOnly
    ? standard.chapters.reduce(
        (accum, chapter) =>
          accum +
          getRulesFromChapter(chapter).filter((rule) => rule.selected).length,
        0
      )
    : standard.chapters.reduce(
        (accum, chapter) =>
          accum +
          getRulesFromChapter(chapter).filter((rule) =>
            rulesToApply ? rulesToApply.get(rule.id) : rule.selected
          ).length,
        0
      );
  const [collapsed, setCollapsed] = React.useState(false);
  const onSelectRule = (rule: MisraRule, selected: boolean) => {
    if (setRulesToApply) {
      setRulesToApply((prev) => new Map(prev.set(rule.id, selected)));
    }
  };

  const onSelectChapter = (
    chapter: CodingStandardChapter,
    selected: boolean
  ) => {
    getRulesFromChapter(chapter)
      .filter((r) => r.selected)
      .forEach((r) => onSelectRule(r, selected));
  };

  const onSelectStandard = (standard: CodingStandard, selected: boolean) => {
    standard.chapters.forEach((c) => onSelectChapter(c, selected));
  };

  return (
    <div className="m-6">
      <label
        className="flex cursor-pointer items-center gap-2 text-gray-600"
        htmlFor=""
      >
        {!readOnly && (
          <input
            id={standard.name}
            className="hidden"
            type="checkbox"
            checked={selectedRules > 0}
            onChange={(e) => onSelectStandard(standard, e.target.checked)}
          />
        )}
        {!readOnly && (
          <label htmlFor={standard.name}>
            <RSCheckboxIcon
              max={standard.chapters.reduce(
                (accum, chapter) =>
                  accum +
                  getRulesFromChapter(chapter).filter((r) => r.selected).length,
                0
              )}
              cur={selectedRules}
            />
          </label>
        )}
        <div
          className="group flex h-8 w-full items-center justify-between pl-2"
          onClick={() => {
            setCollapsed(!collapsed);
          }}
        >
          {standard.name} ({selectedRules})
          <RSArrorIcon
            className="mr-4 inline-block w-4 text-white group-hover:text-black"
            direction="down"
            color="black"
          />
        </div>
      </label>
      {!collapsed && (
        <div className="ml-4">
          {standard.chapters.map(
            (chapter) =>
              getRulesFromChapter(chapter).filter((r) => r.selected).length >
                0 && (
                <RuleSet
                  key={chapter.name}
                  readOnly={readOnly}
                  onSelectRule={onSelectRule}
                  onSelectChapter={onSelectChapter}
                  chapter={chapter}
                  rulesToApply={rulesToApply}
                  setFocusedRuleId={setFocusedRuleId}
                />
              )
          )}
        </div>
      )}
    </div>
  );
};

export default RuleList;
