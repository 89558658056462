/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import { PlusIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { EmptyUserPage } from "../components/EmptyPage";
import { ToolbarLinkButton } from "../uilib/buttons";
import { Switch, Listbox } from "@headlessui/react";
import { MainContentWithTitle } from "../uilib/layouts";
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHead,
  TableRow,
} from "../uilib/tables";
import { HomeLayout } from "../components/ProjectLayout";

interface User {
  id: string;
  name: string;
  email: string;
  position: string;
  state: string;
}

interface APIPortaUsertList {
  userList: User[];
  pageSize: number;
  itemCount: number;
  totalCount: number;
  userManagementEnabled: boolean;
}

declare global {
  interface Window {
    apiPortalUserList: APIPortaUsertList;
  }
}

function CheckedIcon(
  props: React.ComponentProps<"svg"> & { title?: string; titleId?: string }
): JSX.Element {
  const className = props.className as string;
  return (
    <svg
      className={className + " float-right h-4 w-4"}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke="#4F46E5"
        stroke-width="2"
        d="M4.5 12.75l6 6 9-13.5"
      />
    </svg>
  );
}

function UserManagement() {
  const [users, setUsers] = useState<User[]>([]);
  const [userManagementEnabled, setUserManagementEnabled] = useState(false);
  const [checked, setChecked] = useState("All");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [itemCount, setItemCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [searchStr, setSearchStr] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const pageStr = params.get("page");
    if (pageStr === null) {
      setPage(1);
    } else {
      setPage(parseInt(pageStr));
    }
    setPageSize(window.apiPortalUserList.pageSize);
    setItemCount(window.apiPortalUserList.itemCount);
    setTotalCount(window.apiPortalUserList.totalCount);
    setSearchStr(window.location.search);
    if (window.apiPortalUserList.userList !== undefined) {
      setUsers(window.apiPortalUserList.userList);
    }
    setUserManagementEnabled(window.apiPortalUserList.userManagementEnabled);
  }, []);

  return (
    <HomeLayout>
      <MainContentWithTitle title="用户管理">
        <div className="my-4 flex justify-between">
          <ToolbarLinkButton href="/user_management/new" icon={PlusIcon}>
            新建用户
          </ToolbarLinkButton>
          <div className="text-sm text-gray-500">
            总人数：{window.apiPortalUserList.userList.length}人 当前活跃人数：
            {
              window.apiPortalUserList.userList.filter(
                (user) => user.state === "Active"
              ).length
            }
            人
          </div>
        </div>
        {window.apiPortalUserList.userList.length === 0 ? (
          <EmptyUserPage />
        ) : (
          <Table
            page={page}
            pageSize={pageSize}
            itemCount={itemCount}
            totalCount={totalCount}
            searchStr={searchStr}
          >
            <TableHead>
              <TableColumn first textAlign="center">
                用户名
              </TableColumn>
              <TableColumn textAlign="center">邮箱</TableColumn>
              <TableColumn textAlign="center">角色</TableColumn>
              <TableColumn last textAlign="center">
                <Listbox
                  onChange={(value) => {
                    setChecked(value);
                    if (value === "All") {
                      setUsers(window.apiPortalUserList.userList);
                    } else if (value === "Active") {
                      setUsers(
                        window.apiPortalUserList.userList.filter(
                          (user) => user.state === "Active"
                        )
                      );
                    } else {
                      setUsers(
                        window.apiPortalUserList.userList.filter(
                          (user) => user.state === "Disable"
                        )
                      );
                    }
                  }}
                >
                  <Listbox.Button className="outline-none">
                    <div className="flex justify-center hover:cursor-pointer">
                      <span>状态</span>
                      <svg
                        className="pt-1"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.76395 10.0967L3.90262 6.23535C3.85592 6.18877 3.82409 6.12938 3.81116 6.06471C3.79823 6.00003 3.80477 5.93297 3.82997 5.87201C3.85516 5.81106 3.89788 5.75895 3.9527 5.72228C4.00752 5.68561 4.07199 5.66603 4.13795 5.66602H11.862C11.9279 5.66603 11.9924 5.68561 12.0472 5.72228C12.102 5.75895 12.1447 5.81106 12.1699 5.87201C12.1951 5.93297 12.2017 6.00003 12.1887 6.06471C12.1758 6.12938 12.144 6.18877 12.0973 6.23535L8.23528 10.0967C8.17277 10.1592 8.08801 10.1943 7.99962 10.1943C7.91123 10.1943 7.82646 10.1592 7.76395 10.0967Z"
                          fill="#6B7280"
                        />
                      </svg>
                    </div>
                  </Listbox.Button>
                  <Listbox.Options className="relative">
                    <div className="absolute right-0 top-0 z-10 w-28 rounded border border-gray-200 bg-white shadow-sm">
                      <Listbox.Option
                        className="px-2 py-1 text-left text-xs font-normal hover:cursor-pointer hover:bg-sky-500 hover:text-white"
                        value="All"
                      >
                        全部
                        <CheckedIcon
                          className={checked !== "All" ? "hidden" : ""}
                        />
                      </Listbox.Option>
                      <Listbox.Option
                        className="px-2 py-1 text-left text-xs font-normal hover:cursor-pointer hover:bg-sky-500 hover:text-white"
                        value="Active"
                      >
                        活跃
                        <CheckedIcon
                          className={checked !== "Active" ? "hidden" : ""}
                        />
                      </Listbox.Option>
                      <Listbox.Option
                        className="mb-1 px-2 py-1 text-left text-xs font-normal hover:cursor-pointer hover:bg-sky-500 hover:text-white"
                        value="Disable"
                      >
                        禁用
                        <CheckedIcon
                          className={checked !== "Disable" ? "hidden" : ""}
                        />
                      </Listbox.Option>
                    </div>
                  </Listbox.Options>
                </Listbox>
              </TableColumn>
              <TableColumn></TableColumn>
            </TableHead>
            {users.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} highlight>
                  <p className="text-normal my-5 border-none text-center font-normal text-gray-500">
                    暂无用户
                  </p>
                </TableCell>
              </TableRow>
            ) : (
              <TableBody>
                {users.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell first textAlign="center">
                      {user.name}
                    </TableCell>
                    {userManagementEnabled ? (
                      <>
                        <TableCell textAlign="center">{user.email}</TableCell>
                        <TableCell textAlign="center">
                          {user.position === "Admin" ? "管理员" : "组员"}
                        </TableCell>
                        <TableCell textAlign="center">
                          <div className="pr-6">
                            {user.state === "Active" ? "活跃" : "禁用"}
                          </div>
                        </TableCell>
                        <TableCell last textAlign="center">
                          <Switch
                            checked={user.state === "Active"}
                            onChange={(newEnabled: boolean) => {
                              setUsers((oldUsers) => {
                                let _oldUsers = [...oldUsers];
                                _oldUsers.forEach((oldUser) => {
                                  if (oldUser.id === user.id) {
                                    oldUser.state = newEnabled
                                      ? "Active"
                                      : "Disable";
                                  }
                                });
                                return _oldUsers;
                              });
                            }}
                            className={`${
                              user.state === "Active"
                                ? "bg-emerald-400"
                                : "bg-gray-200"
                            } relative inline-flex h-4 w-9 items-center rounded-full align-middle outline-none`}
                          >
                            <span
                              className={`${
                                user.state === "Active"
                                  ? "translate-x-[1.1rem]"
                                  : "translate-x-0"
                              } inline-block h-5 w-5 transform rounded-full border bg-white transition`}
                            />
                          </Switch>
                        </TableCell>
                      </>
                    ) : (
                      <TableCell colSpan={9} last>
                        <div className="text-center text-gray-300">
                          当前使用许可不包括用户管理功能
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        )}
      </MainContentWithTitle>
    </HomeLayout>
  );
}

export default UserManagement;
