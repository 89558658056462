/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import {
  ChevronDownIcon,
  ChevronRightIcon,
  DocumentIcon,
  FolderIcon,
  FolderOpenIcon,
} from "@heroicons/react/24/outline";
import { PropsWithChildren, useState } from "react";
import { ClassNames } from "../common/classnames";
import { SeverityDisplay } from "./Severity";

export interface Measurement {
  fileName: string;
  linesOfCode: number;
  exeLinesOfCode: number;
  functionCount: number;
  commentPercent: string;
  issueCount: number;
  highestSeverityCount: number;
  highSeverityCount: number;
  mediumSeverityCount: number;
  lowSeverityCount: number;
  lowestSeverityCount: number;
  unknownSeverityCount: number;
  issueDensity: string;
  headerCount?: number;
  headerLinesOfCode?: number;
  sourceCount?: number;
  sourceLinesOfCode?: number;
  cyclomaticComplexity: number;
  /* TODO(r/13460): support these code measurement values
  essentialCyclomaticComplexity: number;
  fanIn: number;
  fanOut: number;
  */
  children?: Measurement[];
}

export function Table(
  props: PropsWithChildren<{
    page?: number;
    pageSize?: number;
    itemCount?: number;
    totalCount?: number;
    searchStr?: string;
  }>
) {
  return (
    <div className="my-4">
      <div className="overflow-x-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
        <table className="min-w-full border-separate border-spacing-0">
          {props.children}
        </table>
      </div>
    </div>
  );
}

export function TableHead(props: PropsWithChildren<{}>) {
  return (
    <thead className="bg-gray-50">
      <tr className="relative">{props.children}</tr>
    </thead>
  );
}

export function TableColumn(
  props: PropsWithChildren<{ first?: boolean; last?: boolean }>
) {
  return (
    <th
      scope="col"
      className={ClassNames(
        "z-10 whitespace-nowrap border-b border-b-gray-300 py-3.5 px-8 text-center text-sm font-semibold text-gray-900",
        props.first
          ? "sticky left-0 min-w-[20rem] border-r border-r-gray-200 bg-gray-50 pl-4 pr-3 sm:pl-6"
          : props.last
          ? "relative m-8 pl-3 pr-4 sm:pr-6"
          : "m-8 px-3"
      )}
    >
      {props.children}
    </th>
  );
}

export function TableBody(props: PropsWithChildren<{}>) {
  return <tbody className="bg-white">{props.children}</tbody>;
}

export function TableRow(props: PropsWithChildren<{}>) {
  return (
    <tr
      className="relative bg-white focus:bg-blue-50 focus:outline-none"
      tabIndex={0}
    >
      {props.children}
    </tr>
  );
}

export function TableCell(
  props: PropsWithChildren<{
    first?: boolean;
    last?: boolean;
    center?: boolean;
  }>
) {
  return (
    <td
      className={ClassNames(
        "relative whitespace-nowrap border-b border-b-gray-200 px-8 py-4 text-sm text-gray-500",
        props.first
          ? "sticky left-0 z-10 border-r border-r-gray-200 bg-inherit pl-4 pr-3 sm:pl-6"
          : props.last
          ? "pl-3 pr-4 sm:pr-6"
          : "px-3",
        props.center ? "text-center" : props.first ? "text-left" : "text-right"
      )}
    >
      {props.children}
    </td>
  );
}

function MeasurementCells(props: { measurement: Measurement }) {
  return (
    <>
      <TableCell>{props.measurement.linesOfCode}</TableCell>
      <TableCell>{props.measurement.exeLinesOfCode}</TableCell>
      <TableCell>{props.measurement.functionCount}</TableCell>
      <TableCell>{props.measurement.commentPercent}</TableCell>
      <TableCell>{props.measurement.issueCount}</TableCell>
      <TableCell center>
        <div className="flex">
          <SeverityDisplay task={props.measurement} />
        </div>
      </TableCell>
      <TableCell>{props.measurement.issueDensity}</TableCell>
      <TableCell>
        {props.measurement.children ? props.measurement.headerCount : "-"}
      </TableCell>
      <TableCell>
        {props.measurement.children ? props.measurement.headerLinesOfCode : "-"}
      </TableCell>
      <TableCell>
        {props.measurement.children ? props.measurement.sourceCount : "-"}
      </TableCell>
      <TableCell>
        {props.measurement.children ? props.measurement.sourceLinesOfCode : "-"}
      </TableCell>
      <TableCell>{props.measurement.cyclomaticComplexity}</TableCell>
      {/* <TableCell>{props.measurement.essentialCyclomaticComplexity}</TableCell>
      <TableCell>{props.measurement.fanIn}</TableCell>
      <TableCell>{props.measurement.fanOut}</TableCell> */}
    </>
  );
}

export function MeasurementRow(props: {
  measurement: Measurement;
  level?: number;
}) {
  const [open, setOpen] = useState(false);
  const padding = props.level ? `${props.level * 0.25}rem` : "0";

  const clickHandler = () => {
    setOpen(!open);
  };
  return (
    <>
      <TableRow>
        <TableCell first>
          {props.measurement.children ? (
            <span style={{ paddingLeft: padding }}>
              {open ? (
                <>
                  <ChevronDownIcon
                    className="mr-1 inline h-4 w-4 cursor-pointer"
                    onClick={clickHandler}
                  />
                  <FolderOpenIcon className="mr-1 inline h-4 w-4" />
                </>
              ) : (
                <>
                  <ChevronRightIcon
                    className="mr-1 inline h-4 w-4 cursor-pointer"
                    onClick={clickHandler}
                  />
                  <FolderIcon className="mr-1 inline h-4 w-4" />
                </>
              )}
              {props.measurement.fileName}
            </span>
          ) : (
            <span style={{ paddingLeft: padding }}>
              <DocumentIcon className="mr-1 inline h-4 w-4" />
              {props.measurement.fileName}
            </span>
          )}
        </TableCell>
        <MeasurementCells measurement={props.measurement} />
      </TableRow>
      {open &&
        props.measurement.children?.map((m, idx) => (
          <MeasurementRow
            measurement={m}
            level={props.level ? props.level + 5 : 5}
            key={idx}
          />
        ))}
    </>
  );
}
