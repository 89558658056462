/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import { RadioGroup } from "@headlessui/react";
import { PropsWithChildren, useState } from "react";
import { ClipboardIcon } from "@heroicons/react/20/solid";
import { ClassNames } from "../common/classnames";
import { BasicButton } from "./BasicButton";

enum State {
  past = 0,
  current,
  wait,
}

function RunnerStepHeader(props: {
  state: State;
  stepDisplay: string;
  stepTitle: string;
}) {
  return (
    <div className="flex w-full items-center">
      <span
        className={ClassNames(
          "flex h-12 w-12 items-center justify-center rounded-full border-2 border-solid",
          props.state === State.wait
            ? "border-gray-400 text-gray-400"
            : "border-blue-400 text-blue-400"
        )}
      >
        {props.stepDisplay}
      </span>
      <span
        className={ClassNames(
          "ml-8 font-bold",
          props.state === State.wait ? "text-gray-400" : ""
        )}
      >
        {props.stepTitle}
      </span>
    </div>
  );
}

export function CreateRunnerStep(
  props: PropsWithChildren<{
    id: number;
    currentStep: number;
    setCurrentStep: (value: React.SetStateAction<number>) => void;
    stepDisplay: string;
    stepTitle: string;
    last?: boolean;
    disabled?: boolean;
  }>
) {
  const nextStep = () => {
    props.setCurrentStep(props.currentStep + 1);
  };
  return (
    <div
      className={ClassNames(
        "my-6 w-full bg-white p-8",
        props.last ? "" : "mb-4"
      )}
    >
      <RunnerStepHeader
        state={
          props.id === props.currentStep
            ? State.current
            : props.id < props.currentStep
            ? State.past
            : State.wait
        }
        stepDisplay={props.stepDisplay}
        stepTitle={props.stepTitle}
      />
      {props.id === props.currentStep && (
        <div className="ml-20">
          <div className="mb-8">{props.children}</div>
          {!props.last && (
            <BasicButton disabled={props.disabled} onClick={nextStep}>
              下一步
            </BasicButton>
          )}
        </div>
      )}
    </div>
  );
}

export function RatioButton(
  props: PropsWithChildren<{
    first?: boolean;
    value: string;
  }>
) {
  return (
    <RadioGroup.Option
      value={props.value}
      className={ClassNames(
        "inline cursor-pointer focus:outline-none",
        props.first ? "" : "ml-4"
      )}
    >
      {({ checked }) => (
        <span
          className={ClassNames(
            "min-w-fit rounded-md p-2 text-sm",
            checked ? "bg-blue-100 text-blue-800" : ""
          )}
        >
          {props.children}
        </span>
      )}
    </RadioGroup.Option>
  );
}

export function CodeBlock(props: { code: string }) {
  const [clickCopySuccess, setClickCopySuccess] = useState(false);

  return (
    <div className="my-6 flex w-full items-start rounded-md bg-gray-100 px-8 py-4">
      <span className="grow">
        <pre className="whitespace-break-spaces">
          <code>{props.code}</code>
        </pre>
      </span>
      <BasicButton
        icon={ClipboardIcon}
        onClick={() => {
          navigator.clipboard.writeText(props.code);
          setClickCopySuccess(true);
        }}
      >
        {clickCopySuccess ? "已复制" : "复制"}
      </BasicButton>
    </div>
  );
}
