import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import { PropsWithChildren } from "react";
import { ClassNames } from "../common/classnames";

export function SettingField(
  props: PropsWithChildren<{
    span: string;
    space?: string;
    className?: string;
    hidden?: boolean;
  }>
) {
  return (
    <>
      <div
        className={ClassNames(
          `sm:${props.span}`,
          props.className ? props.className : "",
          props.hidden ? "hidden" : ""
        )}
      >
        {props.children}
      </div>
      {props.hidden ? (
        <></>
      ) : (
        props.space && (
          <div
            className={`sm:${props.space} hidden border-none sm:block`}
          ></div>
        )
      )}
    </>
  );
}

export function RepoHelpText(props: {
  repoKind: string;
  gerritSupportEnabled: boolean;
  gitlabSupportEnabled: boolean;
  githubSupportEnabled: boolean;
  setShowGerritCreateAccountHelp: (_: React.SetStateAction<boolean>) => void;
  setShowGitlabCreateWebhookHelp: (_: React.SetStateAction<boolean>) => void;
  setShowGithubCreateWebhookHelp: (_: React.SetStateAction<boolean>) => void;
}) {
  if (props.repoKind === "none") {
    return <div className="hidden sm:col-span-8 sm:block"></div>;
  } else if (props.repoKind === "gerrit") {
    return (
      <div className="sm:col-span-8">
        {props.gerritSupportEnabled ? (
          <p className="text-sm text-gray-500 sm:mt-8">
            请创建一个专用于代码扫描的 Gerrit 账户
            <QuestionMarkCircleIcon
              className="ml-1 -mt-0.5 inline-block h-4 w-4 cursor-pointer text-sky-500"
              onClick={() => props.setShowGerritCreateAccountHelp(true)}
            />
          </p>
        ) : (
          <p className="text-sm text-gray-500 sm:mt-8">
            当前使用许可不包括<span className="mx-0.5">Gerrit</span>
            相关功能
          </p>
        )}
      </div>
    );
  } else if (props.repoKind === "gitlab") {
    return (
      <div className="sm:col-span-8">
        {props.gitlabSupportEnabled ? (
          <p className="text-sm text-gray-500 sm:mt-8">
            请在项目设置中创建访问令牌，并且配置 webhook
            <QuestionMarkCircleIcon
              className="ml-1 -mt-0.5 inline-block h-4 w-4 cursor-pointer text-sky-500"
              onClick={() => props.setShowGitlabCreateWebhookHelp(true)}
            />
          </p>
        ) : (
          <p className="text-sm text-gray-500 sm:mt-8">
            当前使用许可不包括<span className="mx-0.5">GitLab</span>
            相关功能
          </p>
        )}
      </div>
    );
  } else if (props.repoKind === "github") {
    return (
      <div className="sm:col-span-8">
        {props.githubSupportEnabled ? (
          <p className="text-sm text-gray-500 sm:mt-8">
            请在个人设置中创建访问令牌，并且配置 webhook
            <QuestionMarkCircleIcon
              className="ml-1 -mt-0.5 inline-block h-4 w-4 cursor-pointer text-sky-500"
              onClick={() => props.setShowGithubCreateWebhookHelp(true)}
            />
          </p>
        ) : (
          <p className="text-sm text-gray-500 sm:mt-8">
            当前使用许可不包括<span className="mx-0.5">GitHub</span>
            相关功能
          </p>
        )}
      </div>
    );
  } else {
    return <div className="hidden sm:col-span-8 sm:block"></div>;
  }
}
