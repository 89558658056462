/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

function formatTime(time: number) {
  let formated = `0${time}`;
  return formated.substring(formated.length - 2);
}

export function DateTime(props: { time: number }) {
  let time = new Date(props.time);
  let year = time.getFullYear();
  let month = formatTime(time.getMonth() + 1);
  let date = formatTime(time.getDate());
  let hour = formatTime(time.getHours());
  let min = formatTime(time.getMinutes());
  let sec = formatTime(time.getSeconds());
  return <div>{`${year}-${month}-${date} ${hour}:${min}:${sec}`}</div>;
}
