/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import { useEffect, useState } from "react";
import ProjectLayout from "../components/ProjectLayout";
import {
  BreadcrumbItem,
  Breadcrumbs,
  MainContentWithTitle,
} from "../uilib/layouts";
import {
  Table,
  TableCell,
  TableColumn,
  TableHead,
  TableRow,
} from "../uilib/tables";

interface SeverityCount {
  highest?: number;
  high?: number;
  medium?: number;
  low?: number;
  lowest?: number;
  unknown?: number;
}

interface CompareScanTask {
  id: string;
  startTime?: string;
  endTime?: string;
  linesOfCode?: number;
  issuesFound?: number;
  severityCountList?: SeverityCount[];
}

interface APIPortalCompareScanTasks {
  projectName: string;
  tasks: CompareScanTask[];
}

declare global {
  interface Window {
    apiPortalCompareScanTasks: APIPortalCompareScanTasks;
  }
}

function handleSeverityCount(n?: number): string | undefined {
  if (n === -1) {
    return undefined;
  }
  if (n === 0 || n === undefined) {
    return String(0);
  }
  return String(n);
}

function getSeverityCount(
  l: number,
  s: keyof SeverityCount,
  t?: CompareScanTask
): string | undefined {
  if (t?.severityCountList === undefined) {
    return "-";
  }
  if (t?.severityCountList.length <= l) {
    return undefined;
  }
  return handleSeverityCount(t?.severityCountList[l][s]);
}

function ProjectCompareScanTasks() {
  const [tasks, setCompareScanTasks] = useState<CompareScanTask[]>([]);
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);
  const colTitle = ["新漏洞", "复发的漏洞", "解决的漏洞"];

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setBreadcrumbs([
      {
        name: window.apiPortalCompareScanTasks.projectName,
        href: "/project?id=" + params.get("project_id"),
        current: false,
      },
      {
        name: "对比信息",
        href: "",
        current: false,
      },
    ]);
    setCompareScanTasks(window.apiPortalCompareScanTasks.tasks);
  }, [setBreadcrumbs]);

  return (
    <ProjectLayout>
      <Breadcrumbs pages={breadcrumbs} />
      <MainContentWithTitle title={"对比信息"}>
        {
          <>
            <div className="my-4">{/* TODO toolbar */}</div>
            <Table>
              <TableHead>
                <TableColumn first textAlign="center"></TableColumn>
                {tasks.map((task) => (
                  <TableColumn textAlign="center">{task?.id}</TableColumn>
                ))}
              </TableHead>

              <TableRow>
                <TableCell first textAlign="center" highlight={true}>
                  开始时间
                </TableCell>
                {tasks.map((task) => (
                  <TableCell textAlign="center">
                    {task?.startTime &&
                      new Date(task.startTime).toLocaleString("en-US", {
                        hour12: false,
                        timeZoneName: "short",
                      })}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell first textAlign="center" highlight={true}>
                  结束时间
                </TableCell>
                {tasks.map((task) => (
                  <TableCell textAlign="center">
                    {task?.endTime &&
                      new Date(task?.endTime).toLocaleString("en-US", {
                        hour12: false,
                        timeZoneName: "short",
                      })}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell first textAlign="center" highlight={true}>
                  代码总行数
                </TableCell>
                {tasks.map((task) => (
                  <TableCell textAlign="center">{task?.linesOfCode}</TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell first textAlign="center" highlight={true}>
                  漏洞总数
                </TableCell>
                {tasks.map((task) => (
                  <TableCell textAlign="center">
                    {handleSeverityCount(task?.issuesFound)}
                  </TableCell>
                ))}
              </TableRow>

              {colTitle.map((title, index) => (
                <>
                  <TableRow>
                    <TableCell first colSpan={3} highlight={true}>
                      {title}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell first textAlign="center" highlight={true}>
                      最高
                    </TableCell>
                    {tasks.map((task) => (
                      <TableCell textAlign="center">
                        {getSeverityCount(index, "highest", task)}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell first textAlign="center" highlight={true}>
                      高
                    </TableCell>
                    {tasks.map((task) => (
                      <TableCell textAlign="center">
                        {getSeverityCount(index, "high", task)}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell first textAlign="center" highlight={true}>
                      中
                    </TableCell>
                    {tasks.map((task) => (
                      <TableCell textAlign="center">
                        {getSeverityCount(index, "medium", task)}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell first textAlign="center" highlight={true}>
                      低
                    </TableCell>
                    {tasks.map((task) => (
                      <TableCell textAlign="center">
                        {getSeverityCount(index, "low", task)}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell first textAlign="center" highlight={true}>
                      最低
                    </TableCell>
                    {tasks.map((task) => (
                      <TableCell textAlign="center">
                        {getSeverityCount(index, "lowest", task)}
                      </TableCell>
                    ))}
                  </TableRow>
                </>
              ))}
            </Table>
          </>
        }
      </MainContentWithTitle>
    </ProjectLayout>
  );
}

export default ProjectCompareScanTasks;
