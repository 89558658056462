/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import { useEffect, useState } from "react";
import ProjectLayout from "../components/ProjectLayout";
import {
  BreadcrumbItem,
  Breadcrumbs,
  MainContentWithTitle,
} from "../uilib/layouts";

interface APIPortalResultsViewer {
  resultsViewerEnabled: boolean;
  resultsViewerURL: string;
  projectName: string;
}

declare global {
  interface Window {
    apiPortalResultsViewer: APIPortalResultsViewer;
  }
}

function ResultsViewer() {
  const [resultsViewerURL, setResultsViewerURL] = useState("");
  const [resultsViewerError, setResultsViewerError] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);
  const [subName, setSubName] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setSubName(params.get("from") || "扫描任务");
    setResultsViewerURL(window.apiPortalResultsViewer.resultsViewerURL);
    setResultsViewerError(false);
    setBreadcrumbs([
      {
        name: window.apiPortalResultsViewer.projectName,
        href: "/project?id=" + params.get("project_id"),
        current: false,
      },
      {
        name: "扫描任务",
        href: "",
        current: false,
      },
      {
        name: "当前任务",
        href: "",
        current: true,
      },
    ]);
  }, []);

  return (
    <ProjectLayout currentSubName={subName}>
      {window.apiPortalResultsViewer.resultsViewerEnabled ? (
        resultsViewerError ? (
          <>
            <Breadcrumbs pages={breadcrumbs} />
            <MainContentWithTitle title={"加载失败"}></MainContentWithTitle>
          </>
        ) : (
          <div className="h-screen">
            <iframe
              title="resultsviewer"
              id="resultsViewer"
              src={resultsViewerURL}
              width="100%"
              height="100%"
              sandbox="allow-same-origin allow-scripts allow-popups"
            ></iframe>
          </div>
        )
      ) : (
        <MainContentWithTitle title="">
          <div className="my-4 text-gray-500">
            当前使用许可不包括在线查看结果功能
          </div>
        </MainContentWithTitle>
      )}
    </ProjectLayout>
  );
}

export default ResultsViewer;
