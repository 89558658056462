import { CodingStandard, CodingStandardChapter } from "../standards/Common";
import { Dispatch, SetStateAction, useState } from "react";

import { MisraRule, getRulesFromChapter } from "../standards/Misra";
import ChapterMenu from "./ChapterMenu";
import RSCheckboxIcon from "../components/RSCheckboxIcon";
import RSArrorIcon from "../components/RSArrorIcon";

interface StandardListProps {
  standard: CodingStandard;
  onSelectRule: (rule: MisraRule, selected: boolean) => void;
  onSelectChapter: (chapter: CodingStandardChapter, selected: boolean) => void;
  onSelectStandard: (standard: CodingStandard, selected: boolean) => void;
  focusedRuleId: string | null;
  setFocusedRuleId: Dispatch<SetStateAction<string | null>>;
}

const StandardList: React.FC<StandardListProps> = ({
  standard,
  onSelectRule,
  onSelectChapter,
  onSelectStandard,
  focusedRuleId,
  setFocusedRuleId,
}) => {
  const initCollapsed = (standard: CodingStandard) => {
    let collapsed = new Map<string, boolean>(
      standard.chapters.map((c) => [c.name, false]) as [string, boolean][]
    );
    collapsed = collapsed.set(standard.name, false);
    return collapsed;
  };

  const [collapsed, setCollapsed] = useState(
    new Map<string, boolean>(initCollapsed(standard))
  );
  const currentCollapsed = collapsed.get(standard.name) ?? false;
  return (
    <div className="mb-8">
      <div className="my-4 ml-10 mr-8 flex flex-row items-center space-x-4">
        <label className="items-center text-lg font-normal">
          <input
            className="hidden"
            type="checkbox"
            checked={
              standard.chapters.reduce(
                // Accumulate all selected rules in all chapters
                (accum, chapter) =>
                  accum +
                  getRulesFromChapter(chapter).filter((rule) => rule.selected)
                    .length,
                0
              ) > 0
            }
            onChange={(e) => onSelectStandard(standard, e.target.checked)}
          />
          <RSCheckboxIcon
            max={standard.chapters.reduce(
              (accum, chapter) => accum + getRulesFromChapter(chapter).length,
              0
            )}
            cur={standard.chapters.reduce(
              (accum, chapter) =>
                accum +
                getRulesFromChapter(chapter).filter((rule) => rule.selected)
                  .length,
              0
            )}
          />
        </label>
        <span
          className="font-inter ml-4 cursor-pointer text-xl font-bold text-sky-600"
          onClick={() => {
            setCollapsed(
              (prev) => new Map(prev.set(standard.name, !currentCollapsed))
            );
          }}
        >
          <RSArrorIcon
            className="ml-4 mr-4 inline-block w-4"
            direction={currentCollapsed ? "right" : "down"}
            color="blue"
          />
          {standard.name}
        </span>
      </div>
      {!currentCollapsed && (
        <div className="ml-14 mr-8">
          {standard.chapters.map((chapter) => (
            <ChapterMenu
              key={chapter.name}
              chapter={chapter}
              onSelectChapter={onSelectChapter}
              onSelectRule={onSelectRule}
              collapsed={collapsed}
              setCollapsed={setCollapsed}
              focusedRuleId={focusedRuleId}
              setFocusedRuleId={setFocusedRuleId}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default StandardList;
