/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/20/solid";

function ProjectGuideTour(props: {
  dialogKey: string;
  privatizationModalEnabled: boolean;
}) {
  const [openDialog, setOpenDialog] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);

  const confirmButtonRef = useRef(null);

  useEffect(() => {
    if (!props.privatizationModalEnabled) {
      setOpenDialog(false);
      setOpenAlert(true);
    }
  }, [props.privatizationModalEnabled]);

  return (
    <div>
      <Transition.Root show={openDialog} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={confirmButtonRef}
          onClose={() => {
            setOpenDialog(false);
            setOpenAlert(true);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <div className="mt-2">
                          <div className="text-sm text-gray-500">
                            你的代码将在云端进行扫描。如需私有化部署，请
                            <button
                              type="button"
                              className="inline-flex w-full justify-center text-sm text-blue-600 outline-none hover:bg-gray-50 sm:mt-0 sm:w-auto"
                              onClick={() => {
                                setOpenDialog(false);
                                setOpenAlert(true);
                              }}
                            >
                              <a
                                href="https://jinshuju.net/f/GyZyxJ"
                                target="_blank"
                                rel="noreferrer"
                              >
                                联系我们
                              </a>
                            </button>
                            。
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm outline-none hover:bg-sky-500 sm:ml-3 sm:w-auto"
                      onClick={() => {
                        setOpenDialog(false);
                        setOpenAlert(true);
                      }}
                      ref={confirmButtonRef}
                    >
                      好的
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition show={openAlert} as={Fragment}>
        <Dialog
          as="div"
          className="z-5 relative"
          onClose={() => {
            setOpenAlert(false);
            localStorage.setItem(props.dialogKey, "false");
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="z-5">
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 left-10 top-7 flex max-w-full pl-10">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="transform overflow-hidden transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                      <div className="relative top-20 rounded-md bg-blue-50 p-4">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <InformationCircleIcon
                              className="h-5 w-5 text-blue-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-3 flex-1 md:flex md:justify-between">
                            <p className="text-sm text-blue-700">
                              请点击”默认项目“
                              <span aria-hidden="true"> &rarr;</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export default ProjectGuideTour;
