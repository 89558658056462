/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { PropsWithChildren } from "react";
import { ClassNames } from "../common/classnames";

export function TextField(
  props: PropsWithChildren<{
    id: string;
    type?: "text" | "number" | "password";
    value: string | number;
    onChange?: (_: string) => void;
    error?: string;
    setError?: (_: string) => void;
    disabled?: boolean;
  }>
) {
  return (
    <>
      <label
        htmlFor={props.id}
        className="block text-sm font-medium text-gray-700"
      >
        {props.children}
      </label>
      <div className="relative mt-1">
        <input
          type={props.type || "text"}
          name={props.id}
          id={props.id}
          autoComplete={props.id}
          className={ClassNames(
            "block w-full rounded-md shadow-sm sm:text-sm",
            props.error
              ? "border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500"
              : "border-gray-300 focus:border-sky-500 focus:ring-sky-500",
            props.disabled ? "bg-gray-100 text-gray-600" : ""
          )}
          value={props.value}
          readOnly={!props.onChange}
          disabled={props.disabled}
          onChange={(e) => {
            props.onChange?.(e.target.value);
            props.setError?.("");
          }}
        />
        <div
          className={
            props.error
              ? "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
              : "hidden"
          }
        >
          <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
        </div>
      </div>
      <p className={props.error ? "mt-2 text-sm text-red-600" : "hidden"}>
        {props.error}
      </p>
    </>
  );
}

export function SelectField(
  props: PropsWithChildren<{
    id: string;
    label: string;
    value: string;
    onChange: (_: string) => void;
    disabled?: boolean;
  }>
) {
  return (
    <>
      <label
        htmlFor={props.id}
        className="block text-sm font-medium text-gray-700"
      >
        {props.label}
      </label>
      <div className="mt-1">
        <select
          id={props.id}
          name={props.id}
          autoComplete={props.id}
          className={ClassNames(
            "block w-full rounded-md border-gray-300 shadow-sm sm:text-sm",
            props.disabled
              ? "bg-gray-100 text-gray-600"
              : "focus:border-sky-500 focus:ring-sky-500"
          )}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          disabled={props.disabled}
        >
          {props.children}
        </select>
      </div>
    </>
  );
}

export function TextAreaField(
  props: PropsWithChildren<{
    rows: number;
    id: string;
    value: string;
    onChange?: (_: string) => void;
    error?: string;
    setError?: (_: string) => void;
  }>
) {
  return (
    <>
      <label
        htmlFor={props.id}
        className="block text-sm font-medium text-gray-700"
      >
        {props.children}
      </label>
      <div className="relative mt-1">
        <textarea
          id={props.id}
          name={props.id}
          rows={props.rows}
          className={
            "block w-full break-all rounded-md font-mono shadow-sm sm:text-sm " +
            (props.error
              ? "border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500"
              : "border-gray-300 focus:border-sky-500 focus:ring-sky-500")
          }
          value={props.value}
          readOnly={!props.onChange}
          onChange={(e) => {
            props.onChange?.(e.target.value);
            props.setError?.("");
          }}
        />
      </div>
      <p className={props.error ? "mt-2 text-sm text-red-600" : "hidden"}>
        {props.error}
      </p>
    </>
  );
}

export function CheckboxField(
  props: PropsWithChildren<{
    id: string;
    label: string;
    defaultChecked: boolean;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
  }>
) {
  return (
    <>
      <div className="relative flex items-start">
        <div className="flex h-6 items-center">
          <input
            id={props.id}
            name={props.id}
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-sky-500 focus:ring-sky-500"
            onChange={props.onChange}
            defaultChecked={props.defaultChecked}
          />
        </div>
        <div className="ml-3 text-sm leading-6">
          <label htmlFor={props.id} className="font-medium text-gray-900">
            {props.label}
          </label>
        </div>
      </div>
    </>
  );
}

export function HelpText(props: PropsWithChildren<{}>) {
  return <p className="mt-2 text-sm text-gray-500">{props.children}</p>;
}
