import { CodingStandard } from "../standards/Common";

import RSCheckboxIcon from "../components/RSCheckboxIcon";

interface StandardCardProps {
  standard: CodingStandard;
  numInUse: number;
  numTotal: number;
  nameSelectedStatusMap: Map<string, boolean>;
  onSelectStandardToSet: (standard: CodingStandard, selected: boolean) => void;
}

const StandardCard: React.FC<StandardCardProps> = ({
  standard,
  numInUse,
  numTotal,
  nameSelectedStatusMap,
  onSelectStandardToSet,
}) => {
  const language: Map<string, string> = new Map([
    ["MISRA C++:2008", "C++ (C++03、C++11、C++14)"],
    ["MISRA C:2012", "C（C89、C90、C99、C11）"],
    ["Google C++ Style Guide", "C++ (C++03、C++11、C++14)"],
    ["CERT", "C（C89、C90、C99、C11）, C++ (C++03、C++11、C++14)"],
    ["CWE C/C++", "C（C89、C90、C99、C11）, C++ (C++03、C++11、C++14)"],
    ["AUTOSAR C++14", "C++ (C++03、C++11、C++14)"],
    ["GJB 5369", "C（C89、C90、C99、C11）"],
    ["GJB 8114", "C（C89、C90、C99、C11），C++ (C++03、C++11、C++14)"],
    ["GO", ""],
    ["TypeScript", ""],
    ["JavaScript", ""],
  ]);

  return (
    <div className="my-4 flex min-h-[8rem] items-center space-x-8 rounded border border-gray-200 p-6 sm:h-[14rem] md:h-[8rem] lg:h-[10rem] xl:h-[8rem]">
      <label className="items-center text-lg font-normal">
        <input
          className="hidden"
          type="checkbox"
          onChange={(e) => onSelectStandardToSet(standard, e.target.checked)}
        />
        <RSCheckboxIcon
          max={1}
          cur={nameSelectedStatusMap.get(standard.name) ? 1 : 0}
        />
      </label>
      <div className="">
        {standard.name === "CWE" ? (
          <p
            className="cursor-pointer font-medium text-gray-900"
            onClick={() => window.location.assign("/cwedocumentation")}
          >
            {standard.name}
          </p>
        ) : (
          <p className="font-medium text-gray-900">{standard.name}</p>
        )}
        <p className="font-normal text-gray-500">
          在用&nbsp;<span className="font-semibold">{numInUse}</span>
          &nbsp;条，共&nbsp;
          <span className="font-semibold">{numTotal}</span>&nbsp;条规则。
        </p>
        {language.get(standard.name) !== "" && (
          <p className="font-normal text-gray-500">
            支持的语言：{language.get(standard.name)}
          </p>
        )}
      </div>
    </div>
  );
};

export default StandardCard;
