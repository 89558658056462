/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

function ProjectDashboardGuideTour(props: {
  dialogKey: string;
  desktopScanEnabled: boolean;
}) {
  const [openDesktopScanAlert, setOpenDesktopScanAlert] = useState(
    props.desktopScanEnabled
  );
  const [openScanTaskAlert, setOpenScanTaskAlert] = useState(
    !props.desktopScanEnabled
  );

  return (
    <div>
      <Transition show={openDesktopScanAlert} as={Fragment}>
        <Dialog
          as="div"
          className="z-5 relative"
          onClose={() => {
            setOpenDesktopScanAlert(false);
            setOpenScanTaskAlert(true);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="z-5">
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 left-20 top-20 flex max-w-full pl-10">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="transform overflow-hidden transition-all sm:mb-8 sm:w-full sm:max-w-sm sm:p-6">
                      <div className="relative top-10">
                        <div className="relative top-20 flex rounded-md bg-blue-50 p-4">
                          <div className="flex-1 md:flex md:justify-between">
                            <p className="text-sm text-blue-700">
                              <span aria-hidden="true"> &larr;</span>
                              请点击“桌面扫描”
                            </p>
                          </div>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition show={openScanTaskAlert} as={Fragment}>
        <Dialog
          as="div"
          className="z-5 relative"
          onClose={() => {
            setOpenScanTaskAlert(false);
            localStorage.setItem(props.dialogKey, "false");
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="z-5">
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 left-20 flex max-w-full pl-10">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="transform overflow-hidden transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                      <div className="relative top-10 flex rounded-md bg-blue-50 p-4">
                        <div className="flex-1 md:flex md:justify-between">
                          <p className="text-sm text-blue-700">
                            <span aria-hidden="true"> &larr;</span>
                            {
                              " 如果想通过上传代码包进行扫描，请点击”扫描任务“->“发布前”->“新建扫描任务”"
                            }
                          </p>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export default ProjectDashboardGuideTour;
