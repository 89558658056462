import { ClassNames } from "../common/classnames";
import { ScanTask } from "../common/ScanTask";
import { Measurement } from "./MeasurementTable";

interface Severity {
  id: string;
  label: string;
  default: boolean;
}

// -1: set as '-1' when failed to get severity counts
const errorFlag = -1;
// -2: set as '-2' when the scan task is New or Running
const blankFlag = -2;

export const SeverityList: Severity[] = [
  { id: "highest", label: "最高", default: true },
  { id: "high", label: "高", default: true },
  { id: "medium", label: "中", default: true },
  { id: "low", label: "低", default: true },
  { id: "lowest", label: "最低", default: true },
  { id: "unknown", label: "未定义", default: false },
];

function handleSeverityCount(n?: number): string | undefined {
  if (n === errorFlag || n === blankFlag) {
    // should not reach here because has been checked outside
    return undefined;
  }
  // 0 or undefined: default value, or literal '0' parsed without errors
  if (n === 0 || n === undefined) {
    return String(0);
  }
  return String(n);
}

function SeverityCount(props: {
  severity: string;
  task: ScanTask | Measurement;
  last?: boolean;
}) {
  let backgroundColor = "";
  let count: number | undefined = undefined;
  switch (props.severity) {
    case "highest":
      backgroundColor = "bg-red-700";
      count = props.task.highestSeverityCount;
      break;
    case "high":
      backgroundColor = "bg-red-400";
      count = props.task.highSeverityCount;
      break;
    case "medium":
      backgroundColor = "bg-gray-500";
      count = props.task.mediumSeverityCount;
      break;
    case "low":
      backgroundColor = "bg-gray-400";
      count = props.task.lowSeverityCount;
      break;
    case "lowest":
      backgroundColor = "bg-gray-300";
      // TODO: remove props.task.unknownSeverityCount in the future
      count = 0;
      if (props.task.lowestSeverityCount !== undefined) {
        count += props.task.lowestSeverityCount;
      }
      if (props.task.unknownSeverityCount !== undefined) {
        count += props.task.unknownSeverityCount;
      }
      break;
    default:
      break;
  }
  return (
    <span
      className={ClassNames(
        "inline-flex-row mx-0.5 w-6 flex-1 items-center rounded py-0.5 text-center text-xs font-medium text-white",
        backgroundColor,
        props.last ? "" : "mr-1"
      )}
    >
      {handleSeverityCount(count)}
    </span>
  );
}

export function SeverityDisplay(props: { task: ScanTask | Measurement }) {
  return (
    <>
      {SeverityList.map(
        (s, idx) =>
          s.default && (
            <SeverityCount
              severity={s.id}
              task={props.task}
              key={idx}
              last={idx === SeverityList.length - 2}
            />
          )
      )}
    </>
  );
}
