/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import { useEffect } from "react";
import QRCode from "../components/QRCode";
import analyzeLogo from "../uilib/nsb.svg";

interface APIPortalLoginConfig {
  qrcodeURL: string;
  fetchCreds: RequestCredentials;
  pollInterval: number;
}

declare global {
  interface Window {
    apiPortalLoginConfig: APIPortalLoginConfig;
  }
}

function Login() {
  useEffect(() => {
    const interval = setInterval(() => {
      fetch("/login-state", {
        method: "GET",
        credentials: window.apiPortalLoginConfig.fetchCreds,
      })
        .then((resp) => {
          if (resp.status !== 200) {
            console.error("failed to get login state", resp);
            return { loginState: "Error" };
          }
          return resp.json();
        })
        .then((data) => {
          if (data.loginState === "Completed") {
            window.location.reload();
            return;
          }
          if (data.loginState === "Error") {
            if (window.confirm("登录超时，是否要刷新重试？")) {
              window.location.reload();
              return;
            }
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }, window.apiPortalLoginConfig.pollInterval);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex min-h-screen flex-col">
      <header className="bg-white">
        <nav
          className="flex items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <span className="sr-only"></span>
            <a href="https://www.naivesystems.com/">
              <img
                className="h-5 w-auto"
                src={analyzeLogo}
                alt="NaiveSystems Analyze"
              />
            </a>
          </div>
        </nav>
      </header>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="mt-8 md:mx-auto md:w-full md:max-w-lg">
          <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <p className="mt-6 text-center text-2xl font-bold tracking-tight text-gray-900">
                微信扫码登录
              </p>
              <p className="m-2 text-center text-sm text-gray-700">
                请用微信扫一扫登录{" "}
                <button
                  onClick={() => window.location.reload()}
                  className="cursor-pointer font-medium text-sky-500 hover:text-sky-600"
                  id="refresh"
                >
                  刷新
                </button>
              </p>
            </div>
            <div className="relative m-6 flex justify-center text-sm">
              <span className="inline-flex font-semibold text-sky-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className=" h-5 w-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5"
                  />
                </svg>
                <p className="ml-1">请关注公众号完成登录</p>
              </span>
            </div>
            <div className="m-3">
              <div className="relative">
                <div className="flex justify-center text-sm">
                  <div style={{ width: "120px" }}>
                    <QRCode text={window.apiPortalLoginConfig.qrcodeURL} />
                  </div>
                </div>
              </div>
            </div>
            <div className="m-6 flex justify-center text-sm">
              <span className="bg-white px-2 text-gray-700">
                登录遇到问题？
              </span>
            </div>
            <div className="relative mt-6 flex justify-center text-sm">
              <span className="bg-white px-2 text-gray-500">
                原登录方式不可用、密码无法找回、账号管理员变动，请
                <a
                  className="cursor-pointer text-sky-500 hover:text-sky-600"
                  href="mailto:support@naivesystems.com"
                >
                  联系我们
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <footer className="mt-auto bg-white">
        <div className="mx-auto px-6 py-12 md:flex md:items-center md:justify-start lg:px-8">
          <div className="mt-8 pr-4 md:order-1 md:mt-0">
            <p className="text-center text-xs leading-5 text-gray-500">
              &copy; 2023 上海那一科技有限公司 &middot;{" "}
              <a href="https://beian.miit.gov.cn/">沪ICP备2021005986号-2</a>{" "}
              &middot;{" "}
              <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202013465">
                沪公网安备31011202013465号
              </a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Login;
