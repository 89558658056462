import { Dispatch, SetStateAction } from "react";

import { CodingStandard } from "../standards/Common";
import { Stage } from "../../pages/ProjectRuleSettings";
import Footer from "../containers/Footer";
import { EditorWoSidebarLayout } from "../layouts/EditorLayout";
import StandardCard from "../containers/StandardCard";

interface SetSelectionPageProps {
  standards: CodingStandard[];
  nameSelectedStatusMap: Map<string, boolean>;
  numLastSelectedRules: Map<string, number>;
  numTotalRules: Map<string, number>;
  cweEnabled: boolean;
  certEnabled: boolean;
  gjbEnabled: boolean;
  updateStage: Dispatch<SetStateAction<Stage>>;
  onSelectStandardToSet: (standard: CodingStandard, selected: boolean) => void;
}

const enabledStandard = (
  standard: CodingStandard,
  cweEnabled: boolean,
  certEnabled: boolean,
  gjbEnabled: boolean
) => {
  if (standard.name === "CWE C/C++") return cweEnabled;
  if (standard.name === "CERT") return certEnabled;
  if (standard.name.startsWith("GJB")) return gjbEnabled;
  return true;
};

const SetSelectionPage: React.FC<SetSelectionPageProps> = ({
  standards,
  nameSelectedStatusMap,
  numLastSelectedRules,
  numTotalRules,
  cweEnabled,
  certEnabled,
  gjbEnabled,
  updateStage,
  onSelectStandardToSet,
}) => {
  const StandardCardList = (standards: CodingStandard[]) => {
    const standardCard = (standard: CodingStandard) => {
      const numInUse = numLastSelectedRules.get(standard.name) ?? 0;
      const numTotal = numTotalRules.get(standard.name) ?? 0;
      return (
        <StandardCard
          key={standard.name}
          standard={standard}
          numInUse={numInUse}
          numTotal={numTotal}
          nameSelectedStatusMap={nameSelectedStatusMap}
          onSelectStandardToSet={onSelectStandardToSet}
        />
      );
    };
    return (
      <div className="w-ful flex-row space-x-0 px-10 pt-2 sm:flex sm:space-x-10 md:block md:space-x-0 lg:flex lg:space-x-10">
        <div className="w-full flex-1 sm:flex-1 md:w-full lg:flex-1">
          {standards.filter((_, index) => index % 2 === 0).map(standardCard)}
        </div>
        <div className="w-full flex-1 sm:flex-1 md:w-full lg:flex-1">
          {standards.filter((_, index) => index % 2 === 1).map(standardCard)}
        </div>
      </div>
    );
  };

  return (
    <>
      <title>规则设置工具 - 添加检查项</title>
      <EditorWoSidebarLayout
        editor={
          <>
            <p className="my-6 ml-10 text-sm font-normal">
              选择需要修改的规则集，点击下一步进行设置
            </p>
            <p className="mx-10 mt-6 h-10 border-b border-gray-200 text-lg font-medium">
              启用中
            </p>
            {StandardCardList(
              standards.filter(
                (standard) =>
                  (numLastSelectedRules.get(standard.name) ?? -1) > 0 &&
                  enabledStandard(standard, cweEnabled, certEnabled, gjbEnabled)
              )
            )}
            <p className="mx-10 mt-6 h-10 border-b border-gray-200 text-lg font-medium">
              未启用
            </p>
            {StandardCardList(
              standards.filter(
                (standard) =>
                  (numLastSelectedRules.get(standard.name) ?? -1) === 0 &&
                  enabledStandard(standard, cweEnabled, certEnabled, gjbEnabled)
              )
            )}
          </>
        }
        footer={
          <Footer
            onClickNextStep={() => updateStage("select rules")}
            disabled={
              !Array.from(nameSelectedStatusMap.values()).some((value) => value)
            }
          >
            <div></div>
          </Footer>
        }
      />
    </>
  );
};

export default SetSelectionPage;
