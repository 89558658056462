/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import React, { useEffect, useState, useMemo } from "react";
import ProjectLayout from "../components/ProjectLayout";
import {
  BreadcrumbItem,
  Breadcrumbs,
  MainContentWithTitle,
} from "../uilib/layouts";
import Rulesettings from "../rulesettings/pages/Rulesettings";

interface APIPortalProjectRuleSettings {
  ruleSettingsEnabled: boolean;
  cweEnabled: boolean;
  certEnabled: boolean;
  gjbEnabled: boolean;
  projectName: string;
  checkRules: string;
}

declare global {
  interface Window {
    apiPortalProjectRuleSettings: APIPortalProjectRuleSettings;
  }
}

export type Stage =
  | "select sets"
  | "select rules"
  | "edit settings"
  | "preview";

function ProjectRuleSettings() {
  const stages: Map<Stage, string> = useMemo(() => {
    return new Map([
      ["select sets", "选择规则集"],
      ["select rules", "添加检查项"],
      ["edit settings", "批量设定"],
      ["preview", "预览"],
    ]);
  }, []);
  const [stage, setStage] = React.useState<Stage>("select sets");
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);
  const [cweEnabled, setCweEnabled] = useState(true);
  const [certEnabled, setCertEnabled] = useState(true);
  const [gjbEnabled, setGjbEnabled] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setBreadcrumbs([
      {
        name: window.apiPortalProjectRuleSettings.projectName,
        href: "/project?id=" + params.get("project_id"),
        current: false,
      },
      {
        name: "规则设置",
        href: "",
        current: false,
      },
      {
        name: stages.get(stage) ?? "",
        href: "",
        current: true,
      },
    ]);
    setCweEnabled(window.apiPortalProjectRuleSettings.cweEnabled);
    setCertEnabled(window.apiPortalProjectRuleSettings.certEnabled);
    setGjbEnabled(window.apiPortalProjectRuleSettings.gjbEnabled);
  }, [stage, stages]);

  return (
    <ProjectLayout>
      <Breadcrumbs pages={breadcrumbs} />
      <MainContentWithTitle title={stages.get(stage) ?? ""}>
        {window.apiPortalProjectRuleSettings.ruleSettingsEnabled ? (
          <Rulesettings
            stage={stage}
            updateStage={setStage}
            cweEnabled={cweEnabled}
            certEnabled={certEnabled}
            gjbEnabled={gjbEnabled}
          />
        ) : (
          <div className="my-4 text-gray-500">
            当前使用许可不包括项目规则设置功能
          </div>
        )}
      </MainContentWithTitle>
    </ProjectLayout>
  );
}

export default ProjectRuleSettings;
