interface EditorLayoutProps {
  sidebar: JSX.Element;
  editor: JSX.Element;
  footer: JSX.Element;
  sidebarHidden: boolean;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const EditorLayout: React.FC<EditorLayoutProps> = ({
  sidebar,
  editor,
  footer,
  sidebarHidden,
}) => {
  return (
    <div className="max-h-86vh my-6 flex min-h-[49rem] flex-col divide-y rounded-lg bg-white dark:text-slate-200">
      <div className="flex flex-row divide-x">
        <div
          className={classNames(
            sidebarHidden === true ? "hidden" : "",
            "max-h-86vh min-h-[49rem] basis-1/4 overflow-y-auto dark:bg-slate-800"
          )}
        >
          {sidebar}
        </div>
        <div className="flex flex-grow basis-3/4 flex-col divide-y dark:bg-slate-900">
          <div className="max-h-[76vh] min-h-[45rem] flex-grow overflow-y-auto p-6">
            {editor}
          </div>
          <div className="flex h-[4rem] dark:bg-slate-800">{footer}</div>
        </div>
      </div>
    </div>
  );
};

interface EditorWoSidebarLayoutProps {
  children?: React.ReactNode;
  editor: JSX.Element;
  footer: JSX.Element;
}

export const EditorWoSidebarLayout: React.FC<EditorWoSidebarLayoutProps> = ({
  editor,
  footer,
}) => {
  return (
    <div className="my-6 flex h-[49rem] w-full flex-col divide-y rounded-lg bg-white dark:text-slate-200">
      <div className="flex-grow overflow-y-auto p-6">{editor}</div>
      <div className="static sticky bottom-0 flex h-[4rem] dark:bg-slate-800">
        {footer}
      </div>
    </div>
  );
};
