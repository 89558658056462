/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import { useEffect, useState } from "react";
import ProjectLayout from "../components/ProjectLayout";
import {
  BreadcrumbItem,
  Breadcrumbs,
  MainContentWithTitle,
} from "../uilib/layouts";
import { WhiteButton } from "../uilib/buttons";

interface apiPortalDesktopScan {
  desktopScanEnabled: boolean;
  projectName: string;
}

declare global {
  interface Window {
    apiPortalDesktopScan: apiPortalDesktopScan;
  }
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

function ExtensionManual() {
  const [clickCopySuccess, setClickCopySuccess] = useState(false);
  const gitCloneCommand =
    "git clone https://github.com/naivesystems/analyze-demo.git";

  return (
    <div>
      <div className="text-lg font-semibold leading-6 text-gray-900">
        使用 VS Code 插件
      </div>
      <div className="my-4 text-gray-500">
        <div className="my-2">
          <div>（1）准备测试代码</div>
          <div>
            首次使用时，如果需要测试代码，可以在 VS Code
            面板的终端中运行以下指令：
          </div>
          <div className="sm:flex sm:items-start sm:justify-between">
            <div className="mt-2 rounded-md bg-gray-50 px-6 py-5 text-sm text-gray-500">
              <p>{gitCloneCommand}</p>
            </div>
          </div>
          <div className="mt-5 py-2 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
            <button
              type="button"
              onClick={() => {
                navigator.clipboard.writeText(gitCloneCommand);
                setClickCopySuccess(true);
              }}
              className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {clickCopySuccess ? "已复制" : "复制"}
            </button>
          </div>
        </div>
        <div className="my-2">
          <div>（2）配置 VS Code 插件</div>
          <div>
            打开侧边栏的“API”，点击“API Key”中的复制按钮，复制 API access key：
          </div>
        </div>
        <img src="/desktop_scan/api_access_key.png" alt="" />
        <div className="my-2">
          打开 VS Code 设置，搜索 naivesystems，将复制到的 API access key 填入
          Naivesystems: Api Access Key：
        </div>
        <img className="my-2" src="/desktop_scan/settings.png" alt="" />
        <img
          className="my-2"
          src="/desktop_scan/settings_api_access_key.png"
          alt=""
        />
        <div className="my-2">
          <div>（3）使用 VS Code 插件启动扫描</div>
          <div>在 VS Code 中打开 analyze-demo 文件夹：</div>
        </div>
        <img src="/desktop_scan/open_folder.jpg" alt="" />
        <div className="my-2">
          打开后左侧 sidebar 会展示 analyze-demo 目录：
        </div>
        <img src="/desktop_scan/file_contents.jpg" alt="" />
        <div className="my-2">点击面板中的按钮 “在 demo1 上运行静态分析”：</div>
        <img src="/desktop_scan/run_analysis.png" alt="" />
        <div className="my-2">运行完成后的静态分析结果会陈列在面板中：</div>
        <img src="/desktop_scan/results.png" alt="" />
      </div>
    </div>
  );
}

function VSCodeManual() {
  const [activeStep, setActiveStep] = useState<number>(0);
  return activeStep === 0 ? (
    <div className="pl-11 text-gray-500">
      <div className="text-lg font-semibold leading-6 text-gray-900">
        安装 VS Code 插件
      </div>
      <div className="my-4 text-gray-500">
        <div className="my-2 text-gray-500">
          <div>
            下载{" "}
            <a
              className="text-sky-600"
              href="https://oss-1305043249.cos.ap-shanghai.myqcloud.com/analyze-0.0.10.vsix"
              target="_blank"
              rel="noreferrer"
            >
              NaiveSystems Analyze 插件
            </a>
            。
          </div>
          <div>在 VSCode 中点击左侧扩展图标，手动安装插件：</div>
        </div>
        <img src="/desktop_scan/install_from_vsix.png" alt="" />
        <div className="my-2 text-gray-500">
          安装完成后，按照提示重新加载当前窗口：
        </div>
        <img src="/desktop_scan/reload.png" alt="" />
      </div>
      <WhiteButton type="button" onClick={() => setActiveStep(1)}>
        插件安装完毕，下一步
      </WhiteButton>
      <a
        className="px-4 text-sky-600"
        href="https://jinshuju.net/f/GyZyxJ"
        target="_blank"
        rel="noreferrer"
      >
        需要帮助
      </a>
    </div>
  ) : (
    <div className="pl-11 text-gray-500">
      <ExtensionManual />
      <WhiteButton type="button" onClick={() => setActiveStep(0)}>
        返回上一步
      </WhiteButton>
      <a
        className="px-4 text-sky-600"
        href="https://jinshuju.net/f/GyZyxJ"
        target="_blank"
        rel="noreferrer"
      >
        需要帮助
      </a>
    </div>
  );
}

function DesktopManualForWindows() {
  return (
    <div className="my-2">
      <div className="my-2">
        使用解压缩软件对桌面版进行解压，进入解压出的文件夹，运行 Code.exe：
      </div>
      <img src="/desktop_scan/win32_x64_code.png" alt="" />
    </div>
  );
}

function DesktopManualForUnsupportOS() {
  const [projectID, setprojectID] = useState<string>("");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const projectID = params.get("project_id");
    if (projectID === null) {
      throw new Error("project id is missing");
    }
    setprojectID(projectID);
  }, [setprojectID]);

  return (
    <div className="text-gray-500">
      <div>NaiveSystems Analyze 桌面版暂不支持该操作系统。</div>
      <div>
        可以使用“发布前”页面，
        <a
          className="text-sky-600"
          href={`/prerelease/newscantask?project_id=${projectID}`}
          target="_blank"
          rel="noreferrer"
        >
          上传代码包
        </a>
        进行扫描。
      </div>
    </div>
  );
}

function DesktopManual(props: { url: string }) {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [projectID, setprojectID] = useState<string>("");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const projectID = params.get("project_id");
    if (projectID === null) {
      throw new Error("project id is missing");
    }
    setprojectID(projectID);
  }, [setprojectID]);

  return activeStep === 0 ? (
    <div className="text-gray-500">
      <div className="my-2">
        <div>
          下载{" "}
          <a
            className="text-sky-600"
            href={props.url}
            target="_blank"
            rel="noreferrer"
          >
            NaiveSystems Analyze 桌面版
          </a>
          。
        </div>
        <div>
          或者使用“发布前”页面，
          <a
            className="text-sky-600"
            href={`/prerelease/newscantask?project_id=${projectID}`}
            target="_blank"
            rel="noreferrer"
          >
            上传代码包
          </a>
          进行扫描。
        </div>
      </div>

      <WhiteButton type="button" onClick={() => setActiveStep(1)}>
        桌面版下载完毕，下一步
      </WhiteButton>
      <a
        className="px-4 text-sky-600"
        href="https://jinshuju.net/f/GyZyxJ"
        target="_blank"
        rel="noreferrer"
      >
        需要帮助
      </a>
    </div>
  ) : (
    <div className="my-4 text-gray-500">
      <DesktopManualForWindows />
      <ExtensionManual />
      <WhiteButton type="button" onClick={() => setActiveStep(0)}>
        返回上一步
      </WhiteButton>
      <a
        className="px-4 text-sky-600"
        href="https://jinshuju.net/f/GyZyxJ"
        target="_blank"
        rel="noreferrer"
      >
        需要帮助
      </a>
    </div>
  );
}

function OthersManual() {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const tabs = ["Windows", "Linux", "Mac"];
  const tabHref = "#";
  const downloadLink = [
    "https://oss-1305043249.cos.ap-shanghai.myqcloud.com/NaiveSystemsAnalyzeDesktop-win32-x64.zip",
    "https://xxxxxxxx",
    "https://xxxxxxxx",
  ];

  return (
    <div className="text-gray-500">
      <div className="text-lg font-semibold leading-6 text-gray-900">
        安装 NaiveSystems Analyze 桌面版
      </div>
      <div className="pt-4">
        <p className="text-base font-semibold leading-6 text-gray-900">
          请选择你目前使用的操作系统
        </p>
        <div className="px-22 py-4 sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
            defaultValue={tabs[activeTabIndex]}
          >
            {tabs.map((tab) => (
              <option key={tab} value={tab}>
                {tab}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden py-4 sm:block">
          <nav className="flex space-x-4" aria-label="Tabs">
            {tabs.map((tab, index) => (
              <a
                key={tab}
                href={tabHref}
                className={classNames(
                  activeTabIndex === index
                    ? "bg-indigo-100 text-indigo-700"
                    : "text-gray-500 hover:text-gray-700",
                  "rounded-md px-3 py-2 text-sm font-medium"
                )}
                aria-current={activeTabIndex === index ? "page" : undefined}
                onClick={() => {
                  setActiveTabIndex(index);
                }}
              >
                {tab}
              </a>
            ))}
          </nav>
        </div>
      </div>
      {activeTabIndex === 0 ? (
        <DesktopManual url={downloadLink[activeTabIndex]} />
      ) : (
        <DesktopManualForUnsupportOS />
      )}
    </div>
  );
}

function UnsupportManual() {
  return (
    <div className="pl-11 text-gray-500">
      <div className="mb-4">
        暂不支持该 IDE，可以根据以下说明使用桌面版，并向我们
        <a
          className="text-sky-600"
          href="https://jinshuju.net/f/GyZyxJ"
          target="_blank"
          rel="noreferrer"
        >
          反馈具体需求
        </a>
        。
      </div>
      <OthersManual />
    </div>
  );
}

function DesktopScan() {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setBreadcrumbs([
      {
        name: window.apiPortalDesktopScan.projectName,
        href: "/project?id=" + params.get("project_id"),
        current: false,
      },
      {
        name: "桌面扫描",
        href: "",
        current: true,
      },
    ]);
  }, []);

  const tabs = [
    "VS Code",
    "Visual Studio",
    "Keil MDK",
    "IntelliJ IDEA",
    "其它",
  ];
  const tabHref = "#";

  return (
    <ProjectLayout>
      <Breadcrumbs pages={breadcrumbs} />
      <MainContentWithTitle title="桌面扫描">
        {window.apiPortalDesktopScan.desktopScanEnabled ? (
          <div className="my-2 overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-24 sm:p-6">
              <p className="text-lg font-semibold leading-6 text-gray-900">
                请选择你目前使用的 IDE
              </p>
              <div className="px-22 py-4 sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                  Select a tab
                </label>
                <select
                  id="tabs"
                  name="tabs"
                  className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                  defaultValue={tabs[activeTabIndex]}
                >
                  {tabs.map((tab) => (
                    <option key={tab} value={tab}>
                      {tab}
                    </option>
                  ))}
                </select>
              </div>
              <div className="hidden py-4 sm:block">
                <nav className="flex space-x-4" aria-label="Tabs">
                  {tabs.map((tab, index) => (
                    <a
                      key={tab}
                      href={tabHref}
                      className={classNames(
                        activeTabIndex === index
                          ? "bg-indigo-100 text-indigo-700"
                          : "text-gray-500 hover:text-gray-700",
                        "rounded-md px-3 py-2 text-sm font-medium"
                      )}
                      aria-current={
                        activeTabIndex === index ? "page" : undefined
                      }
                      onClick={() => {
                        setActiveTabIndex(index);
                      }}
                    >
                      {tab}
                    </a>
                  ))}
                </nav>
              </div>
              {tabs[activeTabIndex] === "VS Code" ? (
                <VSCodeManual />
              ) : tabs[activeTabIndex] === "其它" ? (
                <div className="pl-11">
                  <OthersManual />
                </div>
              ) : (
                <UnsupportManual />
              )}
            </div>
          </div>
        ) : (
          <div className="my-4 text-gray-500">
            当前使用许可不包括桌面扫描功能
          </div>
        )}
      </MainContentWithTitle>
    </ProjectLayout>
  );
}

export default DesktopScan;
