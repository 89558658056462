interface RSButtonProps {
  className?: string;
  label?: JSX.Element | string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

export const RSButton: React.FC<RSButtonProps> = (props) => {
  return (
    <button
      className={
        "rounded py-2 px-4 font-bold focus:outline-none " +
        props.className +
        (props.disabled
          ? " bg-gray-100 text-gray-300"
          : " bg-sky-500 text-white  hover:bg-sky-600")
      }
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.label}
    </button>
  );
};

export const RSButtonWhite: React.FC<RSButtonProps> = (props) => {
  return (
    <button
      className={`rounded bg-white py-2 px-4 font-bold text-white hover:text-sky-500 focus:outline-none ${props.className}`}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.label}
    </button>
  );
};
