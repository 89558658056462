/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import { useEffect, useRef, useState } from "react";
import ProjectDashboardGuideTour from "../components/ProjectDashboardGuideTour";
import ProjectLayout from "../components/ProjectLayout";
import {
  BreadcrumbItem,
  Breadcrumbs,
  MainContentWithTitle,
} from "../uilib/layouts";
import Chart from "chart.js/auto";
import colors from "tailwindcss/colors";

interface RuleCount {
  name: string;
  severity: number;
  count: number;
}

interface DailyCount {
  date: string;
  count: number;
}

interface ProjectDatabase {
  id: string;
  totalBugs: number;
  totalGuidelines?: number;
  linesOfCode?: number;
  issueDensity?: string;
  severityCount?: number[];
  guidelineCount?: number[];
  topFiveRules?: RuleCount[];
  dailyCount?: DailyCount[];
  dailyDismissedCount?: DailyCount[];
  dailyFixedCount?: DailyCount[];
  dailyOutstandingCount?: DailyCount[];
}

interface APIPortalProjectDashboard {
  projectDatabase: ProjectDatabase;
  projectName: string;
  dashboardEnabled: boolean;
  desktopScanEnabled: boolean;
  demoModeEnabled: boolean;
}

const ERROR_MARK = "-";
const ERROR_COLOR = colors.gray[100];

const severityColors = [
  colors.red[700], // highest
  colors.red[400], // high
  colors.gray[500], // medium
  colors.gray[400], // low
  colors.gray[300], // lowest
  ERROR_COLOR,
];

const guidelineColors = [
  colors.orange[500], // MISRA C:2012
  colors.teal[400], // MISRA C++:2008
  colors.indigo[500], // GJB
  colors.rose[500], // CWE
  colors.purple[500], // AUTOSAR
  ERROR_COLOR,
];

const severity = ["最高", "高", "中", "低", "最低"];

const guidelines = ["MISRA C:2012", "MISRA C++:2008", "GJB", "CWE", "AUTOSAR"];

declare global {
  interface Window {
    apiPortalProjectDashboard: APIPortalProjectDashboard;
  }
}

function handleCount(n?: number): string {
  if (n === 0 || n === undefined) {
    return String(0);
  }
  if (n < 0) {
    return ERROR_MARK;
  }
  return String(n);
}

function isValidCounts(counts: number[] | undefined): boolean {
  if (counts === undefined || counts.length === 0) {
    return false;
  }
  if (counts.every((cnt) => cnt >= 0)) {
    return true;
  }
  return false;
}

function handleBarColorBySeverity(
  severity: number[] | undefined
): string[] | undefined {
  const colors: string[] = [];
  if (severity === undefined) {
    return undefined;
  }
  for (let i = 0; i < severity.length; i++) {
    let n = severity[i] - 1;
    if (n >= 0 && n < severity.length) {
      colors.push(severityColors[n]);
      continue;
    }
    // should not reach here
    console.log("handleBarColorBySeverity: invalid index: ", i);
    return undefined;
  }
  return colors;
}

function handleStepSizeByCounts(counts: number[] | undefined): number {
  if (counts === undefined || counts.length === 0) {
    // step size when error
    return 20;
  }
  let maxNum = Math.max(...counts);
  if (maxNum > 0 && maxNum <= 50) {
    return 10;
  } else if (maxNum > 20 && maxNum <= 100) {
    return 20;
  } else if (maxNum > 100 && maxNum <= 500) {
    return 100;
  } else if (maxNum > 500 && maxNum <= 1000) {
    return 200;
  } else if (maxNum > 1000 && maxNum <= 5000) {
    return 1000;
  } else if (maxNum > 5000) {
    return 2000;
  } else if (maxNum > 10000) {
    // rare cases: keep 5 steps, e.g., max 11000 -> step size 2500
    return Math.ceil(maxNum / 2500) * 500;
  } else {
    // should not reach here
    return 20;
  }
}

// get count string according to the validity of all counts
function getCountStr(counts: number[] | undefined, i: number): string {
  if (counts === undefined || counts.length === 0) {
    return ERROR_MARK;
  }
  if (i >= counts.length || i < 0) {
    console.log("getCountStr: invalid index: ", i);
    return ERROR_MARK;
  }
  if (counts.every((cnt) => cnt >= 0)) {
    return String(counts[i]);
  }
  return ERROR_MARK;
}

function ProjectDashboard() {
  const [projectDatabase, setProjectDatabase] = useState<ProjectDatabase>();
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);
  const [invisibleLink, setInvisibleLink] = useState("");

  const severityCanvasRef = useRef<HTMLCanvasElement>(null);
  const guidelineCanvasRef = useRef<HTMLCanvasElement>(null);
  const ruleCanvasRef = useRef<HTMLCanvasElement>(null);
  const dailyCanvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (window.apiPortalProjectDashboard.demoModeEnabled) {
      // switch the mode: '&demo=1' <=> '&demo=0'
      var params = new URLSearchParams(window.location.search);
      params.set("demo", params.get("demo") === "1" ? "0" : "1");
      setInvisibleLink(window.location.pathname + "?" + params.toString());
    }

    let hasError = false;

    setBreadcrumbs([
      {
        name: window.apiPortalProjectDashboard.projectName,
        href: "",
        current: true,
      },
    ]);
    setProjectDatabase(window.apiPortalProjectDashboard.projectDatabase);
    if (severityCanvasRef.current) {
      let counts = projectDatabase?.severityCount;
      if (
        !isValidCounts(projectDatabase?.severityCount) ||
        projectDatabase?.totalBugs === 0
      ) {
        // e.g., [0, 0, 0, 0, 0, 1], the last 1 is for displaying the error color
        counts = Array.from({ length: severityColors.length }, (_, index) =>
          index === severityColors.length - 1 ? 1 : 0
        );
      }

      Chart.getChart(severityCanvasRef.current)?.destroy();
      const ctx = severityCanvasRef.current.getContext("2d");
      if (ctx) {
        new Chart(ctx, {
          type: "doughnut",
          data: {
            labels: severity,
            datasets: [
              {
                data: counts,
                backgroundColor: severityColors,
                hoverBackgroundColor: severityColors,
                borderWidth: 0,
              },
            ],
          },
          options: {
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
            },
          },
        });
      }
    }
    if (guidelineCanvasRef.current) {
      let counts = projectDatabase?.guidelineCount;
      if (
        !isValidCounts(projectDatabase?.guidelineCount) ||
        projectDatabase?.totalBugs === 0
      ) {
        // e.g., [0, 0, 0, 1], the last 1 is for displaying the error color
        counts = Array.from({ length: guidelineColors.length }, (_, index) =>
          index === guidelineColors.length - 1 ? 1 : 0
        );
      }

      Chart.getChart(guidelineCanvasRef.current)?.destroy();
      const ctx = guidelineCanvasRef.current.getContext("2d");
      if (ctx) {
        new Chart(ctx, {
          type: "doughnut",
          data: {
            labels: guidelines,
            datasets: [
              {
                data: counts,
                backgroundColor: guidelineColors,
                hoverBackgroundColor: guidelineColors,
                borderWidth: 0,
              },
            ],
          },
          options: {
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
            },
          },
        });
      }
    }
    if (ruleCanvasRef.current) {
      let names: string[] | undefined = projectDatabase?.topFiveRules?.map(
        (item) => item.name
      );
      let severities: number[] | undefined = projectDatabase?.topFiveRules?.map(
        (item) => item.severity
      );
      let counts: number[] | undefined = projectDatabase?.topFiveRules?.map(
        (item) => item.count
      );
      let barColors = handleBarColorBySeverity(severities);

      if (
        names === undefined ||
        severities === undefined ||
        !isValidCounts(counts) ||
        barColors === undefined
      ) {
        counts = [0, 90]; // mock step numbers for error cases
        names = undefined;
        severities = undefined;
        barColors = undefined;
      }

      Chart.getChart(ruleCanvasRef.current)?.destroy();
      const ctx = ruleCanvasRef.current.getContext("2d");
      if (ctx) {
        new Chart(ctx, {
          type: "bar",
          data: {
            labels: names,
            datasets: [
              {
                data: counts,
                backgroundColor: barColors,
                hoverBackgroundColor: barColors,
                barThickness: 48,
              },
            ],
          },
          options: {
            indexAxis: "y",
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                padding: 16,
                titleColor: colors.gray[900],
                bodyColor: colors.gray[900],
                backgroundColor: colors.white,
                borderColor: colors.gray[300],
                borderWidth: 1,
                displayColors: false,
                cornerRadius: 8,
                callbacks: {
                  // label is displayed as '最高: 180'
                  label: function (ctx) {
                    if (severities !== undefined) {
                      let index = severities?.[ctx.dataIndex] - 1;
                      if (index >= 0 && index < severity.length) {
                        return severity[index] + ": " + ctx.formattedValue;
                      }
                    }
                    // cannot find corresponding severity
                    return ctx.formattedValue;
                  },
                },
              },
            },
            scales: {
              x: {
                grid: {
                  display: true,
                },
                border: {
                  display: false, // do not display x-axis line
                },
                ticks: {
                  stepSize: handleStepSizeByCounts(counts),
                  font: {
                    family: "Inter",
                    size: 14,
                  },
                  color: colors.gray[500],
                },
              },
              y: {
                grid: {
                  display: false,
                },
                ticks: {
                  font: {
                    family: "Inter",
                    size: 14,
                  },
                  color: colors.gray[500],
                  crossAlign: "far",
                  callback: function (value, index, ticks) {
                    const fullName = this.getLabelForValue(index);
                    // do not need further processing on cwe rule names
                    if (fullName.startsWith("CWE")) {
                      return fullName;
                    }
                    const matchedPrefix = guidelines.find((prefix) =>
                      fullName.startsWith(prefix)
                    );
                    if (matchedPrefix) {
                      // match for existing guideline names in guidelines
                      // newline between guideline name and rule name
                      const prefixLength = matchedPrefix.length;
                      const guidelineName = fullName.substring(0, prefixLength);
                      const ruleName = fullName.substring(prefixLength).trim();
                      return /** @type {string[]} */ [guidelineName, ruleName];
                    }
                    console.log("no matching guideline name found: ", fullName);
                    const fields = fullName.split(" ");
                    if (fields.length >= 3) {
                      // default: add newline in the second space
                      const firstTwoFields = fields.slice(0, 2).join(" ");
                      const remainder = fields.slice(2).join(" ");
                      return /** @type {string[]} */ [
                        firstTwoFields,
                        remainder,
                      ];
                    }
                    console.log("no enough spaces: ", fullName);
                    return fullName;
                  },
                },
              },
            },
          },
        });
      }
    }
    if (dailyCanvasRef.current) {
      const dates: string[] | undefined = projectDatabase?.dailyCount?.map(
        (item) => item.date
      );
      let dailyCounts: number[] | undefined = projectDatabase?.dailyCount?.map(
        (item) => item.count
      );
      let dailyDismissedCounts: number[] | undefined =
        projectDatabase?.dailyDismissedCount?.map((item) => item.count);
      let dailyFixedCounts: number[] | undefined =
        projectDatabase?.dailyFixedCount?.map((item) => item.count);
      let dailyOutstandingCounts: number[] | undefined =
        projectDatabase?.dailyOutstandingCount?.map((item) => item.count);

      if (
        !isValidCounts(dailyCounts) ||
        !isValidCounts(dailyDismissedCounts) ||
        !isValidCounts(dailyFixedCounts) ||
        !isValidCounts(dailyOutstandingCounts)
      ) {
        dailyCounts = [0, 900]; // mock step numbers for error cases
        dailyDismissedCounts = undefined;
        dailyFixedCounts = undefined;
        dailyOutstandingCounts = undefined;
        hasError = true;
      }

      Chart.getChart(dailyCanvasRef.current)?.destroy();
      const ctx = dailyCanvasRef.current.getContext("2d");
      if (ctx) {
        new Chart(ctx, {
          type: "line",
          data: {
            labels: dates,
            datasets: [
              {
                label: "问题总数",
                data: dailyCounts,
                borderColor: hasError ? "transparent" : colors.sky[500],
                borderWidth: 1,
                fill: false,
                tension: 0.1,
                pointRadius: 0, // do not display points
                pointHoverRadius: 5,
              },
              {
                label: "已忽略问题",
                data: dailyDismissedCounts,
                borderColor: colors.orange[500],
                borderWidth: 1,
                fill: false,
                pointRadius: 0, // do not display points
                pointHoverRadius: 5,
              },
              {
                label: "已修复问题",
                data: dailyFixedCounts,
                borderColor: colors.green[500],
                borderWidth: 1,
                fill: false,
                pointRadius: 0, // do not display points
                pointHoverRadius: 5,
              },
              {
                label: "未解决问题",
                data: dailyOutstandingCounts,
                borderColor: colors.rose[500],
                borderWidth: 1,
                fill: false,
                pointRadius: 0, // do not display points
                pointHoverRadius: 5,
              },
            ],
          },
          options: {
            scales: {
              x: {
                grid: {
                  display: false,
                },
                ticks: {
                  maxRotation: 0,
                  minRotation: 0,
                  maxTicksLimit: 6, // only display six-month labels
                  labelOffset: 64, // adjust label position
                  font: {
                    family: "Inter",
                    size: 14,
                  },
                  color: colors.gray[500],
                },
              },
              y: {
                border: {
                  display: false,
                },
                ticks: {
                  stepSize: Math.max(
                    handleStepSizeByCounts(dailyCounts),
                    handleStepSizeByCounts(dailyDismissedCounts),
                    handleStepSizeByCounts(dailyFixedCounts),
                    handleStepSizeByCounts(dailyOutstandingCounts)
                  ),
                  font: {
                    family: "Inter",
                    size: 14,
                  },
                  color: colors.gray[500],
                },
              },
            },
            plugins: {
              legend: {
                display: hasError ? false : true,
                position: "bottom",
                labels: {
                  usePointStyle: false,
                  textAlign: "left",
                  padding: 20,
                  boxHeight: 1,
                  boxWidth: 37,
                  generateLabels: function (chart) {
                    const originalLabels =
                      Chart.defaults.plugins.legend.labels.generateLabels(
                        chart
                      );
                    const modifiedLabels = originalLabels.map(function (label) {
                      // Replace box with line
                      label.fillStyle = label.strokeStyle;
                      label.lineWidth = 0;
                      // add spaces to mock spacing between labels
                      label.text = label.text + "            ";
                      return label;
                    });

                    return modifiedLabels;
                  },
                },
              },
              // TODO: adjust color label format and text should be right-aligned
              tooltip: {
                padding: 16,
                titleColor: colors.gray[900],
                bodyColor: colors.gray[900],
                backgroundColor: colors.white,
                borderColor: colors.gray[300],
                borderWidth: 1,
                cornerRadius: 8,
                mode: "index",
                intersect: false,
                position: "nearest",
                // do not display tooltip when errors occur
                filter: function (tooltipItem) {
                  return !hasError;
                },
                callbacks: {
                  // TODO: add a calender icon before titile
                  title: function (tooltipItems) {
                    const tooltipItem = tooltipItems[0];
                    if (dates !== undefined) {
                      return dates[tooltipItem.dataIndex];
                    }
                    return tooltipItem.label;
                  },
                  // TODO: the vertical line of tooltip should always display
                  // TODO: the cross points of lines should be 10px width
                  beforeBody: function (tooltipItems) {
                    if (tooltipItems.length > 0) {
                      const tooltipX = tooltipItems[0].parsed.x;
                      const chart = tooltipItems[0].chart;
                      const ctx = chart.ctx;
                      const xAxis = chart.scales.x;
                      const topY = chart.chartArea.top;
                      const bottomY = chart.chartArea.bottom;
                      ctx.save();
                      ctx.beginPath();
                      ctx.moveTo(xAxis.getPixelForValue(tooltipX), topY);
                      ctx.lineTo(xAxis.getPixelForValue(tooltipX), bottomY);
                      ctx.lineWidth = 1;
                      ctx.strokeStyle = colors.gray[300];
                      ctx.stroke();
                      ctx.restore();
                    }
                  },
                },
              },
            },
          },
          plugins: [
            {
              // There may be duplicate month labels due to variations in the
              // number of days in each month. For example, if there are 180
              // days, the chart will display labels at intervals of 30 days
              // (30, 60, 90, etc.), which may result in duplicate labels if
              // certain months have more than 30 days.
              id: "handle_duplicated_month_labels",
              afterBuildTicks: function (chart) {
                let ticks = chart.scales["x"].ticks;
                if (ticks.length === 0) {
                  return;
                }
                let uniLabels: string[] = [];
                ticks.forEach((tick) => {
                  // 10: length of 'YYYY-MM-DD'
                  if (!Array.isArray(tick.label) && tick.label?.length === 10) {
                    const date = new Date(tick.label);
                    // format 'YYYY-MM-DD' to 'M月'
                    let month = date.toLocaleString("zh-CN", {
                      month: "long",
                    });
                    if (uniLabels.includes(month)) {
                      // This month is larger than 30 days, move to the next
                      // month (by adding 5 days).
                      month = new Date(
                        date.getTime() + 5 * 24 * 60 * 60 * 1000
                      ).toLocaleString("zh-CN", {
                        month: "long",
                      });
                    }
                    uniLabels.push(month);
                    tick.label = month;
                  }
                });
              },
            },
          ],
        });
      }
    }
  }, [projectDatabase]);

  const firstOpenProjectDashboardKey =
    "naivesystems-first-open-project-dashboard";
  let firstOpenProjectDashboard = false;
  if (localStorage.getItem(firstOpenProjectDashboardKey) !== "false") {
    firstOpenProjectDashboard = true;
  }

  return (
    <ProjectLayout>
      <Breadcrumbs pages={breadcrumbs} />
      {firstOpenProjectDashboard && (
        <ProjectDashboardGuideTour
          dialogKey={firstOpenProjectDashboardKey}
          desktopScanEnabled={
            window.apiPortalProjectDashboard.desktopScanEnabled
          }
        ></ProjectDashboardGuideTour>
      )}
      <MainContentWithTitle
        title={window.apiPortalProjectDashboard.projectName}
      >
        {!window.apiPortalProjectDashboard.dashboardEnabled ? (
          <div className="my-4 text-gray-500">
            当前使用许可不包括项目仪表盘功能
          </div>
        ) : (
          <div className="container max-w-5xl pt-8 pb-8">
            <div className="flex-row-3 flex-cols-4 grid gap-6">
              <div className="h-45 order-0 inline-flex w-64 flex-col items-center justify-center gap-1 rounded-lg bg-white p-6 shadow">
                <div className="h-6 self-stretch text-base text-gray-500">
                  问题总数
                </div>
                <h3 className="pt-3 pb-3 text-6xl font-extrabold text-gray-900">
                  {handleCount(projectDatabase?.totalBugs)}
                </h3>
              </div>
              <div className="h-45 order-1 inline-flex w-64 flex-col items-center justify-center gap-1 rounded-lg bg-white p-6 shadow">
                <div className="h-6 self-stretch text-base text-gray-500">
                  违反安全编码规则数
                </div>
                <h3 className="pt-3 pb-3 text-6xl font-extrabold text-gray-900">
                  {handleCount(projectDatabase?.totalGuidelines)}
                </h3>
              </div>
              <div className="h-45 order-2 inline-flex w-64 flex-col items-center justify-center gap-1 rounded-lg bg-white p-6 shadow">
                <div className="h-6 self-stretch text-base text-gray-500">
                  代码行数
                </div>
                <h3 className="pt-3 pb-3 text-6xl font-extrabold text-gray-900">
                  {handleCount(projectDatabase?.linesOfCode)}
                </h3>
              </div>
              <div className="h-45 order-3 inline-flex w-64 flex-col items-center justify-center gap-1 rounded-lg bg-white p-6 shadow">
                <div className="h-6 self-stretch text-base text-gray-500">
                  问题密度
                </div>
                <h3 className="pt-3 pb-3 text-6xl font-extrabold text-gray-900">
                  {projectDatabase?.issueDensity}
                </h3>
              </div>
              <div className="h-77 w-128 order-4 col-span-2 inline-flex flex-col items-center justify-center gap-1 rounded-lg bg-white px-6 pt-6 pb-10 shadow">
                <div className="h-6 self-stretch text-base text-gray-500">
                  问题严重程度比例
                </div>
                <div className="grid-rows-7 inline-grid grid-cols-3 pt-4">
                  <div className="order-0 col-span-2 pb-2 text-left text-sm font-normal text-gray-900">
                    问题总数 {handleCount(projectDatabase?.totalBugs)}
                  </div>
                  <div className="order-2 self-center">
                    <span className="flex items-center gap-x-1.5 rounded-full px-2 py-1 text-sm font-normal text-gray-500 ring-1 ring-inset ring-white">
                      <svg
                        className="h-2.5 w-2.5 fill-red-700"
                        viewBox="0 0 6 6"
                        aria-hidden="true"
                      >
                        <circle cx={3} cy={3} r={3} />
                      </svg>
                      {severity[0]}
                    </span>
                  </div>
                  <div className="order-3 self-center pr-32 text-right text-sm font-normal text-gray-500">
                    {getCountStr(projectDatabase?.severityCount, 0)}
                  </div>
                  <div className="order-4 self-center">
                    <span className="flex items-center gap-x-1.5 rounded-full px-2 py-1 text-sm font-normal text-gray-500 ring-1 ring-inset ring-white">
                      <svg
                        className="h-2.5 w-2.5 fill-red-500"
                        viewBox="0 0 6 6"
                        aria-hidden="true"
                      >
                        <circle cx={3} cy={3} r={3} />
                      </svg>
                      {severity[1]}
                    </span>
                  </div>
                  <div className="order-5 self-center pr-32 text-right text-sm font-normal text-gray-500">
                    {getCountStr(projectDatabase?.severityCount, 1)}
                  </div>
                  <div className="order-6 self-center">
                    <span className="flex items-center gap-x-1.5 rounded-full px-2 py-1 text-sm font-normal text-gray-500 ring-1 ring-inset ring-white">
                      <svg
                        className="h-2.5 w-2.5 fill-gray-500"
                        viewBox="0 0 6 6"
                        aria-hidden="true"
                      >
                        <circle cx={3} cy={3} r={3} />
                      </svg>
                      {severity[2]}
                    </span>
                  </div>
                  <div className="order-7 self-center pr-32 text-right text-sm font-normal text-gray-500">
                    {getCountStr(projectDatabase?.severityCount, 2)}
                  </div>
                  <div className="order-8 self-center">
                    <span className="flex items-center gap-x-1.5 rounded-full px-2 py-1 text-sm font-normal text-gray-500 ring-1 ring-inset ring-white">
                      <svg
                        className="h-2.5 w-2.5 fill-gray-400"
                        viewBox="0 0 6 6"
                        aria-hidden="true"
                      >
                        <circle cx={3} cy={3} r={3} />
                      </svg>
                      {severity[3]}
                    </span>
                  </div>
                  <div className="order-9 self-center pr-32 text-right text-sm font-normal text-gray-500">
                    {getCountStr(projectDatabase?.severityCount, 3)}
                  </div>
                  <div className="order-10 self-center">
                    <span className="flex items-center gap-x-1.5 rounded-full px-2 py-1 text-sm font-normal text-gray-500 ring-1 ring-inset ring-white">
                      <svg
                        className="h-2.5 w-2.5 fill-gray-300"
                        viewBox="0 0 6 6"
                        aria-hidden="true"
                      >
                        <circle cx={3} cy={3} r={3} />
                      </svg>
                      {severity[4]}
                    </span>
                  </div>
                  <div className="order-11 self-center pr-32 text-right text-sm font-normal text-gray-500">
                    {getCountStr(projectDatabase?.severityCount, 4)}
                  </div>
                  <div className="order-1 row-span-6 self-center">
                    <canvas ref={severityCanvasRef} width={200} height={200} />
                  </div>
                </div>
              </div>
              <div className="h-77 w-128 order-5 col-span-2 inline-flex flex-col items-center justify-center gap-1 rounded-lg bg-white px-6 pt-6 pb-10 shadow">
                <div className="h-6 self-stretch text-base text-gray-500">
                  各规则集问题比例
                </div>
                <div className="grid-rows-7 inline-grid grid-cols-3 pt-4">
                  <div className="order-0 col-span-2 pb-2 text-left text-sm font-normal text-gray-900">
                    问题总数 {handleCount(projectDatabase?.totalBugs)}
                  </div>
                  {projectDatabase?.guidelineCount?.[0] !== 0 ? (
                    <>
                      <div className="order-2 self-center">
                        <span className="flex items-center gap-x-1.5 rounded-full px-2 py-1 text-sm font-normal text-gray-500 ring-1 ring-inset ring-white">
                          <svg
                            className="h-2.5 w-2.5 fill-orange-500"
                            viewBox="0 0 6 6"
                            aria-hidden="true"
                          >
                            <circle cx={3} cy={3} r={3} />
                          </svg>
                          {guidelines[0]}
                        </span>
                      </div>
                      <div className="order-3 self-center pr-32 text-right text-sm font-normal text-gray-500">
                        {getCountStr(projectDatabase?.guidelineCount, 0)}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {projectDatabase?.guidelineCount?.[1] !== 0 ? (
                    <>
                      <div className="order-4 self-center">
                        <span className="flex items-center gap-x-1.5 rounded-full px-2 py-1 text-sm font-normal text-gray-500 ring-1 ring-inset ring-white">
                          <svg
                            className="h-2.5 w-2.5 fill-teal-400"
                            viewBox="0 0 6 6"
                            aria-hidden="true"
                          >
                            <circle cx={3} cy={3} r={3} />
                          </svg>
                          {guidelines[1]}
                        </span>
                      </div>
                      <div className="order-5 self-center pr-32 text-right text-sm font-normal text-gray-500">
                        {getCountStr(projectDatabase?.guidelineCount, 1)}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {projectDatabase?.guidelineCount?.[2] !== 0 ? (
                    <>
                      <div className="order-6 self-center">
                        <span className="flex items-center gap-x-1.5 rounded-full px-2 py-1 text-sm font-normal text-gray-500 ring-1 ring-inset ring-white">
                          <svg
                            className="h-2.5 w-2.5 fill-indigo-500"
                            viewBox="0 0 6 6"
                            aria-hidden="true"
                          >
                            <circle cx={3} cy={3} r={3} />
                          </svg>
                          {guidelines[2]}
                        </span>
                      </div>
                      <div className="order-7 self-center pr-32 text-right text-sm font-normal text-gray-500">
                        {getCountStr(projectDatabase?.guidelineCount, 2)}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {projectDatabase?.guidelineCount?.[3] !== 0 ? (
                    <>
                      <div className="order-7 self-center">
                        <span className="flex items-center gap-x-1.5 rounded-full px-2 py-1 text-sm font-normal text-gray-500 ring-1 ring-inset ring-white">
                          <svg
                            className="h-2.5 w-2.5 fill-rose-500"
                            viewBox="0 0 6 6"
                            aria-hidden="true"
                          >
                            <circle cx={3} cy={3} r={3} />
                          </svg>
                          {guidelines[3]}
                        </span>
                      </div>
                      <div className="order-8 self-center pr-32 text-right text-sm font-normal text-gray-500">
                        {getCountStr(projectDatabase?.guidelineCount, 3)}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {projectDatabase?.guidelineCount?.[4] !== 0 ? (
                    <>
                      <div className="order-8 self-center">
                        <span className="flex items-center gap-x-1.5 rounded-full px-2 py-1 text-sm font-normal text-gray-500 ring-1 ring-inset ring-white">
                          <svg
                            className="h-2.5 w-2.5 fill-purple-500"
                            viewBox="0 0 6 6"
                            aria-hidden="true"
                          >
                            <circle cx={3} cy={3} r={3} />
                          </svg>
                          {guidelines[4]}
                        </span>
                      </div>
                      <div className="order-9 self-center pr-32 text-right text-sm font-normal text-gray-500">
                        {getCountStr(projectDatabase?.guidelineCount, 4)}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="order-1 row-span-6 self-center">
                    <canvas ref={guidelineCanvasRef} width={200} height={200} />
                  </div>
                </div>
              </div>
              <div className="h-164 w-256 order-6 col-span-4 inline-flex flex-col items-center justify-center gap-1 rounded-lg bg-white p-6 shadow">
                <div className="h-6 self-stretch pb-2 text-base text-gray-500">
                  问题检出量排行 Top 5
                </div>
                <div>
                  <canvas ref={ruleCanvasRef} width={1000} height={480} />
                </div>
                <div className="self-center">
                  <span className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-sm font-normal text-gray-500 ring-1 ring-inset ring-white">
                    <svg
                      className="h-2.5 w-2.5 fill-red-700"
                      viewBox="0 0 6 6"
                      aria-hidden="true"
                    >
                      <circle cx={3} cy={3} r={3} />
                    </svg>
                    最高
                  </span>
                  <span className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-sm font-normal text-gray-500 ring-1 ring-inset ring-white">
                    <svg
                      className="h-2.5 w-2.5 fill-red-500"
                      viewBox="0 0 6 6"
                      aria-hidden="true"
                    >
                      <circle cx={3} cy={3} r={3} />
                    </svg>
                    高
                  </span>
                  <span className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-sm font-normal text-gray-500 ring-1 ring-inset ring-white">
                    <svg
                      className="h-2.5 w-2.5 fill-gray-500"
                      viewBox="0 0 6 6"
                      aria-hidden="true"
                    >
                      <circle cx={3} cy={3} r={3} />
                    </svg>
                    中
                  </span>
                  <span className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-sm font-normal text-gray-500 ring-1 ring-inset ring-white">
                    <svg
                      className="h-2.5 w-2.5 fill-gray-400"
                      viewBox="0 0 6 6"
                      aria-hidden="true"
                    >
                      <circle cx={3} cy={3} r={3} />
                    </svg>
                    低
                  </span>
                  <span className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-sm font-normal text-gray-500 ring-1 ring-inset ring-white">
                    <svg
                      className="h-2.5 w-2.5 fill-gray-300"
                      viewBox="0 0 6 6"
                      aria-hidden="true"
                    >
                      <circle cx={3} cy={3} r={3} />
                    </svg>
                    最低
                  </span>
                </div>
              </div>
              <div className="h-164 w-256 order-7 col-span-4 inline-flex flex-col items-center justify-center gap-1 rounded-lg bg-white p-6 shadow">
                <div className="h-6 self-stretch pb-10 text-base text-gray-500">
                  项目风险走势图
                </div>
                <div>
                  <canvas ref={dailyCanvasRef} width={1000} height={480} />
                </div>
              </div>
            </div>
            {window.apiPortalProjectDashboard.demoModeEnabled ? (
              <a
                href={invisibleLink}
                className="cursor-default text-xs text-transparent"
              >
                switch
              </a>
            ) : (
              <></>
            )}
          </div>
        )}
      </MainContentWithTitle>
    </ProjectLayout>
  );
}

export default ProjectDashboard;
