/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import { PropsWithChildren } from "react";

export function ToolbarLinkButton(
  props: PropsWithChildren<{ href: string; icon: (props: any) => JSX.Element }>
) {
  return (
    <a
      href={props.href}
      className="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
    >
      <props.icon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
      {props.children}
    </a>
  );
}

export function ToolbarButton(
  props: PropsWithChildren<{
    icon: (props: any) => JSX.Element;
    disabled: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
  }>
) {
  return (
    <button
      className={
        "inline-flex items-center rounded-md border border-gray-300 px-3 py-2 text-sm font-medium leading-4 shadow-sm focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2" +
        (props.disabled
          ? " bg-gray-100 text-gray-300"
          : " bg-white text-gray-700 hover:bg-gray-50")
      }
      disabled={props.disabled}
      onClick={props.onClick}
    >
      <props.icon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
      {props.children}
    </button>
  );
}

interface ButtonProps {
  type?: "button" | "submit" | "reset" | undefined;
  className?: string;
  pending?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export function PrimaryButton(props: PropsWithChildren<ButtonProps>) {
  return (
    <button
      type={props.type}
      className={
        "inline-flex items-center rounded-md border border-transparent bg-sky-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 " +
        (props.className || "")
      }
      disabled={props.pending}
    >
      {props.pending && (
        <svg
          className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            cx={12}
            cy={12}
            r={10}
            stroke="white"
            strokeOpacity={0.25}
            strokeWidth={4}
          />
          <path
            fill="white"
            fillOpacity={0.75}
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      )}
      {props.children}
    </button>
  );
}

export function WhiteButton(props: PropsWithChildren<ButtonProps>) {
  return (
    <button
      type={props.type}
      className={
        "rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 " +
        (props.className || "")
      }
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}
