/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import {
  CalendarIcon,
  ClockIcon,
  DocumentIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import {
  CheckIcon,
  XCircleIcon,
  XMarkIcon,
  TableCellsIcon,
} from "@heroicons/react/20/solid";
import {
  GitCommitIcon,
  GitBranchIcon,
  UploadIcon,
} from "@primer/octicons-react";
import { Dialog, Transition } from "@headlessui/react";
import { FormEvent, Fragment, useEffect, useRef, useState } from "react";

import { api } from "../api";
import ProjectLayout from "../components/ProjectLayout";
import { PrimaryButton, ToolbarButton } from "../uilib/buttons";
import {
  BreadcrumbItem,
  Breadcrumbs,
  MainContentWithTitle,
} from "../uilib/layouts";
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHead,
  TableRow,
} from "../uilib/tables";
import ReactTimeAgo from "react-time-ago";
import { ScanSubStep, ScanTask, ScanTaskStep } from "../common/ScanTask";
import { EmptyScanPage } from "../components/EmptyPage";
import { CheckboxField, SelectField } from "../uilib/forms";
import { SeverityList, SeverityDisplay } from "../components/Severity";
import { CharsetList } from "../common/Encoding";
import { BasicButton } from "../components/BasicButton";
import Alert from "../components/Alert";
import OptionalModal from "../components/OptionalModal";

interface APIPortalScanTaskList {
  scanTaskList: ScanTask[];
  pageSize: number;
  itemCount: number;
  totalCount: number;
  projectName: string;
  postsubmitScanEnabled: boolean;
}

declare global {
  interface Window {
    apiPortalScanTaskList: APIPortalScanTaskList;
  }
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

function Steps(props: {
  steps: ScanTaskStep[];
  id: String;
  project_id: String;
}) {
  const [subName, setSubName] = useState("");
  useEffect(() => {
    setSubName(window.location.pathname.slice(1));
  }, []);
  return (
    <nav aria-label="Progress">
      <ol className="flex items-center">
        {props.steps.map((step, stepIdx) => (
          <li
            key={step.name}
            className={classNames(stepIdx > 0 ? "pl-6" : "", "relative")}
          >
            {step.status === "completed" ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-green-500" />
                </div>
                <a
                  href={`/scan_task_detail?id=${props.id}&project_id=${props.project_id}&from=${subName}`}
                  className="relative flex h-4 w-4 items-center justify-center rounded-full bg-green-500"
                >
                  <CheckIcon
                    className="h-3 w-3 text-white"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                </a>
              </>
            ) : step.status === "error" ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-red-500" />
                </div>
                <a
                  href={`/scan_task_detail?id=${props.id}&project_id=${props.project_id}&from=${subName}`}
                  className="relative flex h-4 w-4 items-center justify-center rounded-full bg-red-600"
                >
                  <XMarkIcon
                    className="h-3 w-3 text-white"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                </a>
              </>
            ) : step.status === "running" ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-green-500" />
                </div>
                <a
                  href={`/scan_task_detail?id=${props.id}&project_id=${props.project_id}&from=${subName}`}
                  className="relative flex h-4 w-4 items-center justify-center rounded-full border-2 border-green-500 bg-white"
                  aria-current="step"
                >
                  <span
                    className="h-2 w-2 animate-pulse rounded-full bg-green-500"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                </a>
              </>
            ) : (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <a
                  href={`/scan_task_detail?id=${props.id}&project_id=${props.project_id}&from=${subName}`}
                  className="group relative flex h-4 w-4 items-center justify-center rounded-full border-2 border-gray-300 bg-white"
                >
                  <span
                    className="h-2 w-2 rounded-full bg-transparent group-hover:bg-gray-300"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                </a>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}

function cleanRefName(s: string): string {
  if (s.startsWith("refs/heads/refs/changes/")) {
    return s.substring("refs/heads/".length);
  }
  return s;
}

function setAdd(s: Set<string>, x: string) {
  return new Set(s).add(x);
}

function setRemove(s: Set<string>, x: string) {
  if (!s.has(x)) return s;
  let ns = new Set(s);
  ns.delete(x);
  return ns;
}

// -1: set as '-1' when failed to get severity counts
const errorFlag = -1;
// -2: set as '-2' when the scan task is New or Running
const blankFlag = -2;

function handleDurationSeconds(
  n?: number,
  s?: string
): number | string | undefined {
  if (s) {
    return s;
  }
  if (n) {
    return n;
  }
  return undefined;
}

function ProjectScanTasks(props: {
  presubmit?: boolean;
  postsubmit?: boolean;
  developing?: boolean;
  prerelease?: boolean;
}) {
  const [tasks, setTasks] = useState<ScanTask[]>([]);
  const [page, setPage] = useState(0);
  const [title, setTitle] = useState("");
  const [pageSize, setPageSize] = useState(0);
  const [itemCount, setItemCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [searchStr, setSearchStr] = useState("");
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);
  const [selectedTasks, setSelectedTasks] = useState<Set<string>>(
    new Set<string>()
  );
  const [generateReportError, setGenerateReportError] = useState("");
  const [projectID, setProjectID] = useState("");
  const [reportFormOpen, setReportFormOpen] = useState(false);
  const [seletedSeverity, setSeletedSeverity] = useState<Map<string, boolean>>(
    new Map<string, boolean>([
      ["highest", true],
      ["high", true],
      ["medium", true],
      ["low", true],
      ["lowest", true],
      ["unknown", false],
    ])
  );
  const [formatType, setFormatType] = useState<string>("html");
  const [charset, setCharset] = useState<string>("utf8");
  const [submitPending, setSubmitPending] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [deletedScanTaskID, setDeletedScanTaskID] = useState("");

  const severity = SeverityList;

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    const path = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    const projectID = params.get(path === "/project" ? "id" : "project_id");
    var subName = "";
    if (props.presubmit === true) {
      subName = "presubmit";
    } else if (props.postsubmit === true) {
      subName = "postsubmit";
    } else if (props.developing === true) {
      subName = "developing";
    } else if (props.prerelease === true) {
      subName = "prerelease";
    } else {
      subName = "扫描任务";
    }
    if (ScanSubStep.get(subName) !== undefined) {
      subName = ScanSubStep.get(subName)!;
    }
    setTitle(subName);
    if (projectID === null) {
      throw new Error("project id is missing");
    }
    setProjectID(projectID);
    setBreadcrumbs([
      {
        name: window.apiPortalScanTaskList.projectName,
        href: "/project?id=" + params.get("project_id"),
        current: false,
      },
      {
        name: "扫描任务",
        href: "",
        current: false,
      },
      {
        name: subName,
        href: "",
        current: true,
      },
    ]);
    if (
      props.postsubmit &&
      !window.apiPortalScanTaskList.postsubmitScanEnabled
    ) {
      return;
    }
    setTasks(window.apiPortalScanTaskList.scanTaskList);
    const pageStr = params.get("page");
    if (pageStr === null) {
      setPage(1);
    } else {
      setPage(parseInt(pageStr));
    }
    setPageSize(window.apiPortalScanTaskList.pageSize);
    setItemCount(window.apiPortalScanTaskList.itemCount);
    setTotalCount(window.apiPortalScanTaskList.totalCount);
    setSearchStr(window.location.search);
  }, [props.presubmit, props.postsubmit, props.developing, props.prerelease]);

  const generateReportClick = (
    selectedTasks: Set<string>,
    reportOptions: api.ReportOptions
  ) => {
    const scanTaskIDs = Array.from(selectedTasks.values());
    api
      .NewService()
      .GenerateReport({
        projectID,
        scanTaskIDs,
        reportOptions,
      })
      .then((reply) => {
        if (reply.status !== "ok" && reply.status === "error") {
          setGenerateReportError("生成报告失败：" + reply.reason);
        }
        if (generateReportError === "") {
          window.location.assign(`/reports?project_id=${projectID}`);
        }
        setSubmitPending(false);
      })
      .catch((err) => {
        console.error(err);
        setGenerateReportError("网络错误");
        setSubmitPending(false);
      });
  };

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitPending(true);
    const reportOptions: api.ReportOptions = {
      severity: seletedSeverity,
      formatType: formatType,
      charset: charset,
    };
    generateReportClick(selectedTasks, reportOptions);
  };

  const alertShow = (content: string) => {
    setAlertContent(content);
    setShowAlert(true);
  };

  const handleStopBuild = (
    event: React.MouseEvent<HTMLElement>,
    taskID: string
  ) => {
    event.preventDefault();
    api
      .NewService()
      .StopBuild({
        projectID,
        taskID,
      })
      .then((reply) => {
        if (reply.status !== "ok" && reply.status === "error") {
          alertShow("停止扫描失败: " + reply.reason);
          return;
        }
        window.location.reload();
      })
      .catch((err) => {
        console.error(err);
        alertShow("停止扫描失败");
      });
  };

  const handleRebuild = (
    event: React.MouseEvent<HTMLElement>,
    taskID: string
  ) => {
    event.preventDefault();
    api
      .NewService()
      .Rebuild({
        projectID,
        taskID,
      })
      .then((reply) => {
        if (reply.status !== "ok" && reply.status === "error") {
          alertShow("启动重新扫描失败: " + reply.reason);
          return;
        }
        window.location.reload();
      })
      .catch((err) => {
        console.error(err);
        alertShow("启动重新扫描失败");
      });
  };

  const handleDeleteScanTask = (
    event: React.MouseEvent<HTMLElement>,
    taskID: string
  ) => {
    event.preventDefault();
    api
      .NewService()
      .DeleteScanTask({
        projectID,
        taskID,
      })
      .then((reply) => {
        if (reply.status !== "ok" && reply.status === "error") {
          alertShow("删除扫描任务失败: " + reply.reason);
          return;
        }
        window.location.reload();
      })
      .catch((err) => {
        console.error(err);
        alertShow("删除扫描任务失败");
      });
  };

  return (
    <ProjectLayout>
      <Breadcrumbs pages={breadcrumbs} />
      <Transition.Root show={reportFormOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setReportFormOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <form onSubmit={submit}>
                    <div className="align-items-center mb-6 flex justify-between">
                      <div className="flex items-center text-sm font-normal leading-5">
                        导出报告
                      </div>
                      <PrimaryButton
                        className="h-8 w-24 justify-center"
                        type="submit"
                        pending={submitPending}
                      >
                        <div className="text-sm font-medium leading-3">
                          导出
                        </div>
                      </PrimaryButton>
                    </div>
                    <div className="sm:col-span-4">
                      <SelectField
                        id="project-type"
                        label="项目类型"
                        value={formatType}
                        onChange={setFormatType}
                      >
                        <option value="html">HTML</option>
                        <option value="pdf">PDF</option>
                        <option value="word">Word</option>
                        <option value="excel">Excel</option>
                        <option value="xml">XML</option>
                      </SelectField>
                    </div>
                    <div className="sm:col-span-4">
                      <SelectField
                        id="charset"
                        label="文件编码格式"
                        value={charset}
                        onChange={setCharset}
                      >
                        {CharsetList.map((c) => {
                          return (
                            <option value={c.name}>{c.displayName}</option>
                          );
                        })}
                      </SelectField>
                    </div>
                    <div className="sm:col-span-4">
                      <div className="mt-4 mb-2 text-sm font-medium leading-5">
                        问题的严重程度
                      </div>
                      {severity.map((s) => (
                        <div className="mt-0.5">
                          <CheckboxField
                            id={s.id}
                            label={s.label}
                            defaultChecked={s.default}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setSeletedSeverity((old) => {
                                old.set(s.id, event.target.checked);
                                return old;
                              });
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Alert
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        alertContent={alertContent}
      />
      <MainContentWithTitle title={title}>
        {props.postsubmit &&
        !window.apiPortalScanTaskList.postsubmitScanEnabled ? (
          <div className="my-4 text-gray-500">
            当前使用许可不包括提交后扫描功能
          </div>
        ) : (
          <>
            {tasks.length === 0 ? (
              <EmptyScanPage
                projectID={projectID}
                prerelease={props.prerelease}
              />
            ) : (
              <>
                <div
                  className={
                    generateReportError === ""
                      ? "hidden"
                      : "mb-4 rounded-md bg-red-50 p-4"
                  }
                >
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <XCircleIcon
                        className="h-5 w-5 text-red-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-red-800">
                        {generateReportError}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="my-4">
                  {(props.postsubmit || props.prerelease) && (
                    <span>
                      {props.prerelease && (
                        <a
                          href={`/prerelease/newscantask?project_id=${projectID}`}
                          className="mr-1"
                        >
                          <ToolbarButton icon={PlusIcon} disabled={false}>
                            新建扫描任务
                          </ToolbarButton>
                        </a>
                      )}
                      <span className="mr-1">
                        <ToolbarButton
                          icon={DocumentIcon}
                          disabled={selectedTasks.size === 0}
                          // The function of generating reports is disabled currently
                          onClick={() => {
                            // TODO: give the options for generating reports
                            /* generateReportClick(selectedTasks); */
                            setReportFormOpen(true);
                          }}
                        >
                          导出报告
                        </ToolbarButton>
                      </span>
                      {selectedTasks.size === 2 ? (
                        <a
                          href={`/compare_scan_tasks?project_id=${projectID}&a=${
                            Array.from(selectedTasks)[0]
                          }&b=${Array.from(selectedTasks)[1]}`}
                        >
                          <ToolbarButton icon={TableCellsIcon} disabled={false}>
                            对比信息
                          </ToolbarButton>
                        </a>
                      ) : (
                        <ToolbarButton icon={TableCellsIcon} disabled={true}>
                          对比信息
                        </ToolbarButton>
                      )}
                    </span>
                  )}
                </div>
                <Table
                  page={page}
                  pageSize={pageSize}
                  itemCount={itemCount}
                  totalCount={totalCount}
                  searchStr={searchStr}
                >
                  <TableHead>
                    <TableColumn first></TableColumn>
                    <TableColumn>描述</TableColumn>
                    <TableColumn>进度</TableColumn>
                    <TableColumn>时间</TableColumn>
                    <TableColumn>代码行数</TableColumn>
                    <TableColumn>各个程度问题数量</TableColumn>
                    <TableColumn last>操作</TableColumn>
                  </TableHead>
                  <TableBody>
                    {tasks.map((task) => (
                      <TableRow key={task.id}>
                        <TableCell first>
                          <input
                            type="checkbox"
                            className={
                              "absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-sky-500 focus:ring-sky-500" +
                              (task.steps[task.steps.length - 1].status !==
                              "completed"
                                ? " bg-gray-100"
                                : "")
                            }
                            disabled={
                              task.steps[task.steps.length - 1].status !==
                              "completed"
                            }
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              if (event.target.checked) {
                                setSelectedTasks((s) => setAdd(s, task.id));
                              } else {
                                setSelectedTasks((s) => setRemove(s, task.id));
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <div className="max-w-[35ch] truncate font-bold">
                            {task.steps.every((step, _) => {
                              return step.status === "completed";
                            }) === false ? (
                              task.subject || "暂无信息"
                            ) : (
                              <a
                                href={
                                  props.presubmit ||
                                  props.developing ||
                                  props.prerelease
                                    ? `/scan_task?project_id=${projectID}&id=${
                                        task.id
                                      }&from=${window.location.pathname.slice(
                                        1
                                      )}`
                                    : `/scan_task_detail?id=${
                                        task.id
                                      }&project_id=${projectID}&from=${window.location.pathname.slice(
                                        1
                                      )}`
                                }
                              >
                                {task.subject || "暂无信息"}
                              </a>
                            )}
                          </div>
                          <div className="mt-1">
                            {task.repoKind === "git" ? (
                              <span>
                                <GitCommitIcon className="mr-1" />
                                {task.revision.substring(0, 12)}
                              </span>
                            ) : (
                              <span>
                                <UploadIcon className="mr-1" />
                                通过zip上传
                              </span>
                            )}
                            {task.repoKind === "git" &&
                              task.refName &&
                              task.refName !== task.revision && (
                                <span className="ml-3.5">
                                  <GitBranchIcon className="mr-1" />
                                  {cleanRefName(task.refName)}
                                </span>
                              )}
                          </div>
                        </TableCell>
                        <TableCell>
                          <Steps
                            steps={task.steps}
                            id={task.id}
                            project_id={projectID}
                          />
                        </TableCell>
                        <TableCell>
                          <div className="flex items-center justify-end xl:mr-8 xl:w-24">
                            <CalendarIcon className="mr-1 inline-block h-3 w-3" />
                            {Number.isNaN(Date.parse(task.submittedAt)) ? (
                              task.submittedAt
                            ) : (
                              <ReactTimeAgo
                                date={Date.parse(task.submittedAt)}
                              />
                            )}
                          </div>
                          {task.durationSeconds && (
                            <div className="mt-1 flex items-center justify-end xl:mr-8 xl:w-24">
                              <ClockIcon className="mr-1 inline-block h-3 w-3" />
                              {handleDurationSeconds(
                                task.durationSeconds,
                                task.durationSecondsStr
                              )}
                            </div>
                          )}
                        </TableCell>
                        <TableCell>
                          <div className="text-right xl:mr-8 xl:w-20">
                            {task.linesOfCode === errorFlag
                              ? "加载失败"
                              : task.linesOfCode !== blankFlag
                              ? task.linesOfCode
                              : "暂无信息"}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="flex">
                            {task.highestSeverityCount === errorFlag
                              ? "加载失败"
                              : task.highestSeverityCount !== blankFlag && (
                                  <SeverityDisplay task={task} />
                                )}
                          </div>
                        </TableCell>
                        <TableCell last>
                          <div className="flex gap-4">
                            <BasicButton
                              onClick={() => {
                                window.open(
                                  `scan_task_detail?id=${
                                    task.id
                                  }&project_id=${projectID}&from=${window.location.pathname.slice(
                                    1
                                  )}`,
                                  "_blank"
                                );
                              }}
                            >
                              详情
                            </BasicButton>
                            <BasicButton
                              disabled={
                                task.builderID === undefined ||
                                task.builderID === "" ||
                                task.steps.every((step, _) => {
                                  return (
                                    step.status === "completed" ||
                                    step.status === "error"
                                  );
                                }) === true
                              }
                              onClick={(e) => {
                                handleStopBuild(e, task.id);
                              }}
                            >
                              停止
                            </BasicButton>
                            <BasicButton
                              disabled={
                                task.builderID === undefined ||
                                task.builderID === "" ||
                                task.hasBuilderConfig !== true ||
                                task.steps.every((step, _) => {
                                  return (
                                    step.status === "completed" ||
                                    step.status === "error"
                                  );
                                }) === false
                              }
                              onClick={(e) => {
                                handleRebuild(e, task.id);
                              }}
                            >
                              重新扫描
                            </BasicButton>
                            <BasicButton
                              disabled={
                                task.steps.every((step, _) => {
                                  return (
                                    step.status === "completed" ||
                                    step.status === "error"
                                  );
                                }) === false
                              }
                              onClick={() => {
                                setOpenDialog(true);
                                setDeletedScanTaskID(task.id);
                              }}
                            >
                              删除
                            </BasicButton>
                            <OptionalModal
                              showModal={openDialog}
                              confirmHandler={(e) => {
                                handleDeleteScanTask(e, deletedScanTaskID);
                              }}
                              cancelHandler={() => {
                                setOpenDialog(false);
                                setDeletedScanTaskID("");
                              }}
                              content="确定要删除这个扫描任务吗？"
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </>
            )}
          </>
        )}
      </MainContentWithTitle>
    </ProjectLayout>
  );
}

export default ProjectScanTasks;
