/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import { Routes, Route } from "react-router-dom";
import CreateProject from "./pages/CreateProject";
import Home from "./pages/Home";
import Login from "./pages/Login";
import ProjectAPISettings from "./pages/ProjectAPISettings";
import ProjectDashboard from "./pages/ProjectDashboard";
import DesktopScan from "./pages/DesktopScan";
import { ProjectReport, ProjectReports } from "./pages/ProjectReports";
import ProjectRuleSettings from "./pages/ProjectRuleSettings";
import ProjectRunners from "./pages/ProjectRunners";
import ProjectScanTasks from "./pages/ProjectScanTasks";
import ProjectCompareScanTasks from "./pages/ProjectCompareScanTasks";
import ResultsViewer from "./pages/ResultsViewer";
import ProjectNewScanTask from "./pages/ProjectNewScanTask";
import ProjectScanTaskDetail, {
  DownloadCheckRules,
} from "./pages/ProjectScanTaskDetail";
import CodeMeasurement from "./pages/CodeMeasurement";
import ProjectSettings from "./pages/ProjectSettings";
import CreateUser from "./pages/CreateUser";
import ProjectCreateRunner from "./pages/ProjectCreateRunner";
import ProjectBranches from "./pages/ProjectBranches";
import UserManagement from "./pages/UserManagement";
import CWEDocumentation from "./rulesettings/pages/CWEDocumentation";

function App() {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<Home />} />
        <Route path="projects">
          <Route index element={<Home />} />
          <Route path="new" element={<CreateProject />} />
        </Route>
        <Route path="project" element={<ProjectDashboard />} />
        <Route path="resultsviewer" element={<ResultsViewer />} />
        <Route path="reports" element={<ProjectReports />} />
        <Route path="report" element={<ProjectReport />} />
        <Route path="runners" element={<ProjectRunners />} />
        <Route path="newrunner" element={<ProjectCreateRunner />} />
        <Route path="presubmit" element={<ProjectScanTasks presubmit />} />
        <Route path="scan_task" element={<ResultsViewer />} />
        <Route path="postsubmit" element={<ProjectScanTasks postsubmit />} />
        <Route path="developing" element={<ProjectScanTasks developing />} />
        <Route path="prerelease" element={<ProjectScanTasks prerelease />} />
        <Route path="prerelease">
          <Route index element={<ProjectScanTasks prerelease />} />
          <Route path="newscantask" element={<ProjectNewScanTask />} />
        </Route>
        <Route
          path="compare_scan_tasks"
          element={<ProjectCompareScanTasks />}
        />
        <Route
          path="scan_task_detail"
          element={<ProjectScanTaskDetail />}
        ></Route>
        <Route path="code_measurement" element={<CodeMeasurement />}></Route>
        <Route path="branches" element={<ProjectBranches />} />
        <Route path="desktopscan" element={<DesktopScan />} />
        <Route path="apisettings" element={<ProjectAPISettings />} />
        <Route path="rulesettings" element={<ProjectRuleSettings />} />
        <Route path="projectsettings" element={<ProjectSettings />} />
        <Route path="login" element={<Login />} />
        <Route path="user_management">
          <Route index element={<UserManagement />} />
          <Route path="new" element={<CreateUser />} />
        </Route>
        <Route path="cwedocumentation" element={<CWEDocumentation />} />
        <Route path="download">
          <Route path="check_rules" element={<DownloadCheckRules />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
