/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confIDential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import React, { useEffect, useState, useRef, useCallback } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { ArrowPathIcon, CheckIcon } from "@heroicons/react/24/outline";
import { Combobox } from "@headlessui/react";

import { api } from "../api";
import ProjectLayout from "../components/ProjectLayout";
import Alert from "../components/Alert";
import Modal from "../components/Modal";
import { WhiteButton } from "../uilib/buttons";

import {
  BreadcrumbItem,
  Breadcrumbs,
  MainContentWithTitle,
} from "../uilib/layouts";
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHead,
} from "../uilib/tables";
import { ClassNames } from "../common/classnames";

const ascii85 = require("ascii85");

interface APIPortalNewScanTask {
  projectName: string;
  runnerKey: string;
  repoKind: string;
  repoURL: string;
  projectType: string;
}

declare global {
  interface Window {
    apiPortalNewScanTask: APIPortalNewScanTask;
  }
}

interface UploadFile {
  file: File | null;
  size: string;
  name: string;
}

enum Status {
  clonePending = 0,
  cloneFailed,
  cloneSuccess,
  zipPending,
  zipFailed,
  zipSuccess,
  configPending,
  configFailed,
  configSuccess,
}

const escapeRegExp = (str: string) =>
  str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

function Highlight(props: { query: string; text: string }) {
  if (!props.query) {
    return <div>{props.text}</div>;
  }
  const regex = new RegExp(`(${escapeRegExp(props.query)})`, "gi");
  const parts = props.text.split(regex);
  return (
    <div>
      {parts.map((part, idx) =>
        regex.test(part) ? (
          <mark className="bg-transparent text-blue-500" key={idx}>
            {part}
          </mark>
        ) : (
          <span key={idx}>{part}</span>
        )
      )}
    </div>
  );
}

export function formatBytes(bytes: number, decimals: number) {
  if (bytes === 0) return "0 B";
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let unitIndex = 0;
  while (bytes >= 1000) {
    bytes /= 1000;
    unitIndex += 1;
  }
  return bytes.toFixed(decimals) + " " + sizes[unitIndex];
}

function ProjectNewScanTask() {
  const [job, setJob] = useState<UploadFile>();
  const [projectID, setProjectID] = useState("");
  const [progress, setProgress] = useState("上传中");
  const [runnerKey, setRunnerKey] = useState("");
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);
  const [repoID, setRepoID] = useState("");
  const [uploadError, setUploadError] = useState("");
  const [revision, setRevision] = useState("");
  const [dragOver, setDragOver] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [gitURL, setGitURL] = useState("");
  const [refID, setRefID] = useState("");
  const [refName, setRefName] = useState("");
  const [gitTags, setGitTags] = useState<api.TagRef[]>([]);
  const [selectedTag, setSelectedTag] = useState<api.TagRef>({
    ref: "",
    revision: "",
  });
  const [query, setQuery] = useState("");
  const [checkStatusInterval, setCheckStatusInterval] = useState(0);
  const [repoKind, setRepoKind] = useState("");
  const [isNoneProject, setIsNoneProject] = useState(false);
  const [hasCheckRules, setHasCheckRules] = useState(false);
  const [taskID, setTaskID] = useState("");
  const handleDragOverStart = () => setDragOver(true);
  const handleDragOverEnd = () => setDragOver(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const filteredTags =
    query === ""
      ? gitTags
      : gitTags.filter((tag) =>
          tag.ref.toLowerCase().includes(query.toLowerCase())
        );

  progress !== "上传中" &&
    progress !== "压缩中" &&
    progress !== "配置扫描任务中" &&
    clearInterval(checkStatusInterval);

  const handleClickUpload = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    inputRef.current!.click();
  };

  const enableDropping = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const alertShow = (content: string) => {
    setAlertContent(content);
    setShowAlert(true);
  };

  const handleFileUpload = (files: FileList | null) => {
    if (!files) {
      return;
    }
    if (files.length > 1) {
      alertShow("一次只能上传一个 ZIP 文件");
      return;
    }
    const file = files[0];
    if (file.name.split(".").pop() !== "zip") {
      alertShow("暂时只接受 ZIP 文件");
      return;
    }

    const fileSize = formatBytes(file.size, 2);
    setJob({ file: file, size: fileSize, name: file.name });

    const chunkSize = 1024 * 1024 * 80;
    var encodedSize = 0;
    var ascii85_encode = "";
    let reader = new FileReader();
    reader.onload = async function (event: ProgressEvent<FileReader>) {
      let arrayBuffer = event.target?.result;
      ascii85_encode += ascii85.encode(arrayBuffer).toString();
      encodedSize += chunkSize;
      if (encodedSize < file.size) {
        let nextChunk = file.slice(encodedSize, encodedSize + chunkSize);
        reader.readAsArrayBuffer(nextChunk);
      } else {
        const uri = "base85:" + ascii85_encode;
        const kind = "zip";
        setUploadError("");
        setProgress("上传中");
        api
          .NewService()
          .AddRepository({
            runnerKey,
            uri,
            kind,
          })
          .then((reply) => {
            if (reply.status !== "ok" && reply.status === "error") {
              setUploadError("文件上传失败");
              setProgress("失败");
              return;
            }
            if (reply.repo_id) {
              setRepoID(reply.repo_id);
              setRevision(reply.repo_id);
              setProgress("文件上传完成");
              setUploadError("");
              setProgress("配置扫描任务中");
              api
                .NewService()
                .CreateZipUpload({
                  projectID,
                  repoID: reply.repo_id,
                  filename: file.name,
                })
                .then((reply) => {
                  if (reply.status !== "ok" && reply.status === "error") {
                    setUploadError("配置扫描任务失败");
                    setProgress("失败");
                    return;
                  }
                  setHasCheckRules(reply.hasCheckRules === true);
                  if (reply.taskID !== undefined) {
                    setTaskID(reply.taskID);
                  }
                  setProgress("完成");
                })
                .catch((err) => {
                  console.error(err);
                  setUploadError("配置扫描任务失败");
                  setProgress("失败");
                });
            }
          })
          .catch((err) => {
            console.error(err);
            setUploadError("文件上传失败");
            setProgress("失败");
          });
      }
    };
    let firstChunk = file.slice(encodedSize, encodedSize + chunkSize);
    reader.readAsArrayBuffer(firstChunk);
  };

  const handleGitUpload = (e: React.MouseEvent<HTMLElement>) => {
    const uri = (
      (e.target as HTMLInputElement).previousElementSibling as HTMLInputElement
    ).value;
    if (!uri) {
      return;
    }
    const name = uri
      .replace(/\.[^/.]+$/, "")
      .split("/")
      .slice(-1)[0];
    setJob({ file: null, size: "", name: name });
    setUploadError("");
    api
      .NewService()
      .GitClone({
        projectID,
        uri,
        repoKind,
      })
      .then((reply) => {
        if (reply.status !== "ok" && reply.status === "error") {
          setUploadError("文件上传失败");
          setProgress("失败");
          return;
        }
        if (reply.repo_id) {
          setRepoID(reply.repo_id);
        }
        if (reply.refID !== "") {
          setRefID(reply.refID!);
          setProgress("上传中");
          const intervalID = window.setInterval(function () {
            handleRefreshStatus(reply.refID!);
          }, 2000);
          setCheckStatusInterval(intervalID);
        }
      })
      .catch((err) => {
        console.error(err);
        setUploadError("文件上传失败");
        setProgress("失败");
      });
    if (repoKind === "none") {
      setGitURL("");
    }
  };

  const handleRefreshStatus = useCallback(
    (refID: string) => {
      api
        .NewService()
        .CheckStatus({
          runnerKey,
          refID,
        })
        .then((reply) => {
          if (reply.status === "error") {
            setUploadError("代码仓库Clone失败");
            setProgress("失败");
            return;
          }
          setJob({ file: null, size: "", name: reply.name! });
          switch (reply.operationStatus) {
            case Status.clonePending:
              setProgress("上传中");
              break;
            case Status.cloneFailed:
              setUploadError("代码仓库上传失败");
              setProgress("失败");
              break;
            case Status.cloneSuccess:
              setProgress("代码仓库上传完成");
              let tags = [] as api.TagRef[];
              reply.tags?.forEach((val) => {
                tags.push(val);
              });
              setGitTags(tags);
              setShowModal(true);
              break;
            case Status.zipPending:
              setProgress("压缩中");
              break;
            case Status.zipFailed:
              setUploadError("文件压缩失败");
              setProgress("失败");
              break;
            case Status.zipSuccess:
              setProgress("完成");
              if (reply.repo_id) {
                setRepoID(reply.repo_id);
                setRevision(reply.repo_id);
              }
              break;
            case Status.configPending:
              setProgress("配置扫描任务中");
              break;
            case Status.configFailed:
              setUploadError("配置扫描任务失败");
              setProgress("失败");
              break;
            case Status.configSuccess:
              setProgress("完成");
              break;
            default:
              setProgress("上传中");
              break;
          }
        })
        .catch((err) => {
          console.error(err);
          setUploadError("代码仓库Clone失败");
          setProgress("失败");
        });
    },
    [runnerKey]
  );

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragOver(false);
    handleFileUpload(event.dataTransfer.files);
  };

  const handleUploadBuild = (
    event: React.MouseEvent<HTMLElement>,
    repoKind: string
  ) => {
    event.preventDefault();
    if (progress !== "完成") {
      return;
    }
    api
      .NewService()
      .StartBuild({
        projectID,
        taskID,
        repoKind,
        repoID,
        refID,
        ref: refName,
      })
      .then((reply) => {
        if (reply.status !== "ok" && reply.status === "error") {
          alertShow("扫描开始失败: " + reply.reason);
          return;
        }
        window.location.assign(`/prerelease?project_id=${projectID}`);
      })
      .catch((err) => {
        console.error(err);
        alertShow("扫描开始失败");
      });
  };

  const handleCreateGitUpload = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setShowModal(false);
    api
      .NewService()
      .CreateGitUpload({
        projectID,
        refID,
        ref: refName,
      })
      .then((reply) => {
        if (reply.status === "ok") {
          setProgress("配置扫描任务中");
          const intervalID = window.setInterval(function () {
            handleRefreshStatus(refID);
          }, 2000);
          setCheckStatusInterval(intervalID);
          if (reply.taskID !== undefined) {
            setTaskID(reply.taskID);
          }
        } else {
          setProgress("失败");
          alertShow("配置扫描任务失败");
        }
      })
      .catch((err) => {
        console.error(err);
        setProgress("失败");
        alertShow("配置扫描任务失败");
      });
  };

  useEffect(() => {
    const path = window.location.pathname;

    const params = new URLSearchParams(window.location.search);
    const projectID = params.get(path === "/project" ? "id" : "project_id");
    if (projectID === null) {
      throw new Error("project ID is missing");
    }
    setProjectID(projectID);
    setBreadcrumbs([
      {
        name: window.apiPortalNewScanTask.projectName,
        href: "/project?id=" + params.get("project_id"),
        current: false,
      },
      {
        name: "扫描任务",
        href: "",
        current: false,
      },
      {
        name: "发布前",
        href: `/prerelease?project_id=${projectID}`,
        current: false,
      },
      {
        name: "新建扫描任务",
        href: "",
        current: true,
      },
    ]);
    setRunnerKey(window.apiPortalNewScanTask.runnerKey);
    setRepoKind(window.apiPortalNewScanTask.repoKind);
    if (window.apiPortalNewScanTask.repoKind !== "none") {
      setGitURL(window.apiPortalNewScanTask.repoURL);
    }
    setIsNoneProject(
      window.apiPortalNewScanTask.projectType === "" ||
        window.apiPortalNewScanTask.projectType === "none"
    );
  }, [setProjectID]);

  return (
    <ProjectLayout currentSubName={"prerelease"}>
      <Breadcrumbs pages={breadcrumbs} />
      <MainContentWithTitle title={"新建扫描任务"}>
        <div className="my-4">
          <form>
            <div className="mb-2">上传任务</div>
            <div
              onDragOver={enableDropping}
              onDrop={handleDrop}
              onDragEnter={handleDragOverStart}
              onDragLeave={handleDragOverEnd}
              style={dragOver ? { fontWeight: "bold", background: "grey" } : {}}
              className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 bg-white p-12 text-center"
            >
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  vectorEffect="non-scaling-stroke"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                />
              </svg>
              <span className="mt-2 block text-sm text-gray-900">
                <input
                  accept=".zip"
                  ref={inputRef}
                  type="file"
                  multiple={false}
                  hidden
                  onChange={(e) => {
                    handleFileUpload(e.target.files);
                  }}
                />
                <button
                  className="cursor-pointer font-medium text-sky-600 hover:text-sky-500"
                  id="refresh"
                  onClick={handleClickUpload}
                >
                  上传 ZIP 文件
                </button>
                或拖拽本地 ZIP 文件到该区域
              </span>
            </div>
          </form>
          <Alert
            showAlert={showAlert}
            setShowAlert={setShowAlert}
            alertContent={alertContent}
          />
          <div className="mt-4 flex w-full md:w-2/4">
            {repoKind === "none" ? (
              <input
                className={
                  "mr-2 grow rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                }
                type="text"
                placeholder="或粘贴git源代码仓库URL, 回车以读取git分支"
                multiple={false}
                value={gitURL}
                onChange={(e) => {
                  setGitURL(e.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    (
                      (e.target as Element)
                        .nextElementSibling as HTMLInputElement
                    ).click();
                  }
                }}
              />
            ) : (
              <input
                className={
                  "mr-2 grow rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                }
                type="text"
                readOnly
                value={gitURL}
              />
            )}
            <WhiteButton type="button" onClick={handleGitUpload}>
              提交
            </WhiteButton>
          </div>
          <Modal
            showModal={showModal}
            clickHandler={handleCreateGitUpload}
            title={"选择分支"}
            disabled={revision === ""}
          >
            <Combobox
              value={selectedTag}
              onChange={(tag) => {
                setSelectedTag(tag);
                setRefName(tag.ref);
                setRevision(tag.revision);
              }}
            >
              <div className="w-full">
                <Combobox.Input
                  className={
                    "w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                  }
                  displayValue={(tag: api.TagRef) => tag.ref}
                  onChange={(e) => setQuery(e.target.value)}
                />
                <Combobox.Options
                  static
                  className="mt-2 h-40 w-full overflow-y-auto"
                >
                  {filteredTags.map((tag) => (
                    <Combobox.Option
                      key={tag.ref}
                      value={tag}
                      className="focus:outline-none"
                    >
                      {({ active, selected }) => (
                        <div
                          className={ClassNames(
                            active ? "bg-blue-100" : "bg-white",
                            "flex h-8 w-full content-center justify-between px-4"
                          )}
                        >
                          <span className="truncate leading-8">
                            <Highlight text={tag.ref} query={query} />
                          </span>
                          {selected && (
                            <span className="flex w-fit items-center text-blue-500">
                              <CheckIcon
                                className="h-4 w-4"
                                aria-hidden="true"
                              />
                            </span>
                          )}
                        </div>
                      )}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              </div>
            </Combobox>
          </Modal>
          {isNoneProject && (
            <div className="mt-4 rounded-md bg-yellow-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon
                    className="h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-yellow-800">
                    未指定项目类型
                  </h3>
                  <div className="mt-2 text-sm text-yellow-700">
                    <p>当前项目没有设置项目类型，分析结果可能不准确。</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div>
            {job && (
              <>
                <div className="mt-5 mb-2">上传任务列表</div>
                <Table>
                  <TableHead>
                    <TableColumn first textAlign="center">
                      项目名称
                    </TableColumn>
                    <TableColumn textAlign="center">大小</TableColumn>
                    <TableColumn textAlign="center">状态</TableColumn>
                    <TableColumn last textAlign="center">
                      操作
                    </TableColumn>
                  </TableHead>
                  <TableBody>
                    <TableCell first textAlign="center">
                      <div className="mb font-bold">
                        {job.name || "暂无信息"}
                      </div>
                    </TableCell>

                    <TableCell textAlign="center">
                      <div className="font-bold">{job.size || "暂无信息"}</div>
                    </TableCell>

                    <TableCell textAlign="center">
                      {uploadError ? (
                        <div className="font-semibold text-red-400">
                          {uploadError}
                          <ArrowPathIcon className="inline-block h-4 w-4" />
                        </div>
                      ) : (
                        <>
                          {progress === "完成" && hasCheckRules
                            ? "完成（扫描使用规则以项目中 check_rules 为准）"
                            : progress}
                        </>
                      )}
                    </TableCell>
                    <TableCell last textAlign="center">
                      {job && (
                        <button
                          onClick={(e) => {
                            job.file !== null
                              ? handleUploadBuild(e, "zip")
                              : handleUploadBuild(e, "git");
                          }}
                          disabled={progress !== "完成"}
                          className={ClassNames(
                            "rounded bg-white px-2 py-1 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300",
                            progress
                              ? "hover:bg-gray-50"
                              : "cursor-not-allowed opacity-50"
                          )}
                        >
                          开始扫描
                        </button>
                      )}
                    </TableCell>
                  </TableBody>
                </Table>
              </>
              // TODO(b/12551): show upload history table
            )}
          </div>
        </div>
      </MainContentWithTitle>
    </ProjectLayout>
  );
}

export default ProjectNewScanTask;
