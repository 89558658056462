/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import {
  GitCommitIcon,
  GitBranchIcon,
  UploadIcon,
} from "@primer/octicons-react";
import { useEffect, useState } from "react";

import ProjectLayout from "../components/ProjectLayout";
import {
  BreadcrumbItem,
  Breadcrumbs,
  MainContentWithTitle,
} from "../uilib/layouts";
import {
  Measurement,
  MeasurementRow,
  Table,
  TableBody,
  TableColumn,
  TableHead,
} from "../components/MeasurementTable";

interface APIPortalCodeMeasurement {
  measurements: Measurement[];
  projectName: string;
}

declare global {
  interface Window {
    apiPortalCodeMeasurement: APIPortalCodeMeasurement;
  }
}

function cleanRefName(s: string): string {
  if (s.startsWith("refs/heads/refs/changes/")) {
    return s.substring("refs/heads/".length);
  }
  return s;
}

function CodeMeasurement() {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);
  const [measurements, setMeasurements] = useState<Measurement[]>([]);
  const [subject, setSubject] = useState("");
  const [repoKind, setRepoKind] = useState("");
  const [revision, setRevision] = useState("");
  const [refName, setRefName] = useState("");
  const [subName, setSubName] = useState("");

  useEffect(() => {
    const path = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    const projectID = params.get(path === "/project" ? "id" : "project_id");
    const subject = params.get("subject");
    const repoKind = params.get("repoKind");
    const revision = params.get("revision");
    const refName = params.get("refName");
    setSubName(params.get("from") || "扫描任务");
    if (projectID === null) {
      throw new Error("project id is missing");
    }
    setSubject(subject ? subject : "暂无信息");
    setRepoKind(repoKind ? repoKind : "");
    setRevision(revision ? revision : "");
    setRefName(refName ? refName : "");
    setBreadcrumbs([
      {
        name: window.apiPortalCodeMeasurement.projectName,
        href: "/project?id=" + projectID,
        current: false,
      },
      {
        name: "扫描任务",
        href: "",
        current: false,
      },
      {
        name: "查看代码度量",
        href: "",
        current: true,
      },
    ]);
    setMeasurements(window.apiPortalCodeMeasurement.measurements);
  }, []);

  return (
    <ProjectLayout currentSubName={subName}>
      <Breadcrumbs pages={breadcrumbs} />
      <MainContentWithTitle title={"查看代码度量"}>
        <div className="divide-y divide-solid">
          <div className="mt-8 mb-4">
            <div className="font-bold">{subject}</div>
            <div className="mt-1 text-sm text-gray-400">
              {repoKind === "git" ? (
                <span>
                  <GitCommitIcon className="mr-1" />
                  {revision.substring(0, 12)}
                </span>
              ) : (
                <span>
                  <UploadIcon className="mr-1" />
                  通过zip上传
                </span>
              )}
              {repoKind === "git" && refName && refName !== revision && (
                <span className="ml-3.5">
                  <GitBranchIcon className="mr-1" />
                  {cleanRefName(refName)}
                </span>
              )}
            </div>
            <div className="mt-1 text-sm text-gray-400">扫描完成</div>
          </div>
          <div>
            <Table>
              <TableHead>
                <TableColumn first>文件名</TableColumn>
                <TableColumn>代码行数</TableColumn>
                <TableColumn>可执行代码行数</TableColumn>
                <TableColumn>函数个数</TableColumn>
                <TableColumn>注释比例</TableColumn>
                <TableColumn>缺陷总数</TableColumn>
                <TableColumn>各个严重程度问题数量</TableColumn>
                <TableColumn>缺陷密度</TableColumn>
                <TableColumn>头文件个数</TableColumn>
                <TableColumn>头文件行数</TableColumn>
                <TableColumn>源文件个数</TableColumn>
                <TableColumn>源文件行数</TableColumn>
                <TableColumn>最大圈复杂度</TableColumn>
                {/* TODO(r/13460): support these code measurement values
                <TableColumn>基本圈复杂度</TableColumn>
                <TableColumn>扇入</TableColumn>
                <TableColumn>扇出</TableColumn> */}
              </TableHead>
              <TableBody>
                {measurements.map((m, idx) => (
                  <MeasurementRow measurement={m} key={idx} />
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </MainContentWithTitle>
    </ProjectLayout>
  );
}

export default CodeMeasurement;
