interface RSArrorIconProps {
  className: string;
  direction: "right" | "down";
  color: "black" | "blue";
}

const RSArrorIcon: React.FC<RSArrorIconProps> = ({
  className,
  direction,
  color,
}) => {
  const rotation = direction === "right" ? 270 : 0;
  const strokeColor = color === "black" ? "#374151" : "#0284C7"; // gray-700 : sky-600
  const arrowSVG = () => {
    return (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ transform: `rotate(${rotation}deg)` }}
      >
        <path
          d="M11.375 4.8125L7 9.1875L2.625 4.8125"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };
  return <div className={className}>{arrowSVG()}</div>;
};

export default RSArrorIcon;
