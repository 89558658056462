/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import {
  GitCommitIcon,
  GitBranchIcon,
  UploadIcon,
} from "@primer/octicons-react";
import { useEffect, useState } from "react";

import { ScanSubStep } from "../common/ScanTask";
import ProjectLayout from "../components/ProjectLayout";
import StepsDetail from "../components/StepsDetail";
import { ScanTask } from "../common/ScanTask";
import { BasicButton } from "../components/BasicButton";
import {
  BreadcrumbItem,
  Breadcrumbs,
  MainContentWithTitle,
} from "../uilib/layouts";

const ascii85 = require("ascii85");
interface APIPortalScanTaskDetail {
  tasks: ScanTask[];
  projectName: string;
  scanTaskLogEnabled: boolean;
}

declare global {
  interface Window {
    apiPortalScanTaskDetail: APIPortalScanTaskDetail;
  }
}

function cleanRefName(s: string): string {
  if (s.startsWith("refs/heads/refs/changes/")) {
    return s.substring("refs/heads/".length);
  }
  return s;
}

function ProjectScanTaskDetail() {
  const [tasks, setTasks] = useState<ScanTask[]>([]);
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);
  const [taskDone, setTaskDone] = useState(false);
  const [taskStatus, setTaskStatus] = useState("");
  const [projectID, setProjectID] = useState("");
  const [subName, setSubName] = useState("");
  const [scanTaskLogEnabled, setScanTaskLogEnabled] = useState(false);

  useEffect(() => {
    const path = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    setSubName(params.get("from") || "扫描任务");
    const projectID = params.get(path === "/project" ? "id" : "project_id");
    if (projectID === null) {
      throw new Error("project id is missing");
    }
    setProjectID(projectID);
    setScanTaskLogEnabled(window.apiPortalScanTaskDetail.scanTaskLogEnabled);
    setBreadcrumbs([
      {
        name: window.apiPortalScanTaskDetail.projectName,
        href: "/project?id=" + params.get("project_id"),
        current: false,
      },
      {
        name: "扫描任务",
        href: "",
        current: true,
      },
      {
        name: ScanSubStep.get(subName)!,
        href: "/" + subName + "?project_id=" + params.get("project_id"),
        current: true,
      },
    ]);
    if (window.apiPortalScanTaskDetail.tasks.length !== 1) {
      return;
    }
    setTasks(window.apiPortalScanTaskDetail.tasks);
    for (let step of window.apiPortalScanTaskDetail.tasks[0].steps) {
      switch (step.status) {
        case "pending":
          setTaskStatus("等待扫描");
          setTaskDone(false);
          return;
        case "running":
          setTaskStatus("正在扫描");
          setTaskDone(false);
          return;
        case "completed":
          setTaskStatus("扫描完成");
          setTaskDone(true);
          break;
        case "error":
          setTaskStatus("扫描失败");
          setTaskDone(false);
          return;
      }
    }
  }, [subName]);

  return (
    <ProjectLayout currentSubName={subName}>
      <Breadcrumbs pages={breadcrumbs} />
      <MainContentWithTitle title={""}>
        {tasks.map((task) => (
          <div className="divide-y divide-solid" key={task.id}>
            <div className="mt-8 mb-4">
              <div className="font-bold">{task.subject || "暂无信息"}</div>
              <div className="mt-1 text-sm text-gray-400">
                {task.repoKind === "git" ? (
                  <span>
                    <GitCommitIcon className="mr-1" />
                    {task.revision.substring(0, 12)}
                  </span>
                ) : (
                  <span>
                    <UploadIcon className="mr-1" />
                    通过zip上传
                  </span>
                )}
                {task.repoKind === "git" &&
                  task.refName &&
                  task.refName !== task.revision && (
                    <span className="ml-3.5">
                      <GitBranchIcon className="mr-1" />
                      {cleanRefName(task.refName)}
                    </span>
                  )}
              </div>
              <div className="mt-1 text-sm text-gray-400">{taskStatus}</div>
            </div>
            <div>
              {task.hasCheckRules === true && (
                <div className="my-4 border-l-4 border-yellow-400 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <ExclamationTriangleIcon
                        className="h-5 w-5 text-yellow-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-yellow-700">
                        项目存在自定义 check_rules 文件，忽略现有规则设置
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className="my-4 flex gap-4">
                <BasicButton
                  disabled={!taskDone}
                  onClick={() => {
                    window.location.href = `/scan_task?project_id=${projectID}&id=${task.id}&from=${subName}`;
                  }}
                >
                  查看结果
                </BasicButton>
                <BasicButton
                  disabled={!taskDone}
                  onClick={() => {
                    window.location.href = `/code_measurement?project_id=${projectID}&id=${
                      task.id
                    }${task.subject && `&subject=${task.subject}`}${
                      task.repoKind && `&repoKind=${task.repoKind}`
                    }&revision=${task.revision}${
                      task.refName && `&refName=${task.refName}`
                    }&from=${subName}`;
                  }}
                >
                  查看代码度量
                </BasicButton>
                {scanTaskLogEnabled && (
                  <BasicButton
                    onClick={() => {
                      let builderURL = `http://${window.location.hostname}:8010`;
                      if (
                        task.builderID !== undefined &&
                        task.builderID !== ""
                      ) {
                        builderURL += `/#/builders/${task.builderID}`;
                      }
                      window.location.href = builderURL;
                    }}
                  >
                    查看日志
                  </BasicButton>
                )}
                <BasicButton
                  onClick={() => {
                    window.open(
                      `/download/check_rules?project_id=${projectID}&id=${task.id}`,
                      "_blank"
                    );
                  }}
                >
                  查看规则
                </BasicButton>
              </div>
              <StepsDetail steps={task.steps} />
            </div>
            <div style={{ marginTop: "40vh" }}>
              <pre>compile_error_counter={task.compileErrorCounter}</pre>
            </div>
          </div>
        ))}
      </MainContentWithTitle>
    </ProjectLayout>
  );
}

interface APIPortalProjectCheckRules {
  checkRulesContents: string;
}

declare global {
  interface Window {
    apiPortalProjectCheckRules: APIPortalProjectCheckRules;
  }
}

export function DownloadCheckRules() {
  const enc = new TextEncoder();
  const checkRules = new Uint8Array(
    ascii85.decode(
      enc.encode(window.apiPortalProjectCheckRules.checkRulesContents)
    )
  );
  const url = URL.createObjectURL(
    new Blob([checkRules], {
      type: "application/octet-stream",
    })
  );
  const anchorElement = document.createElement("a");
  document.body.appendChild(anchorElement);
  anchorElement.href = url;
  anchorElement.download = "check_rules";
  anchorElement.click();
  setTimeout(() => {
    URL.revokeObjectURL(url);
    anchorElement.remove();
    window.close();
  }, 1000);
  return <div className="my-4 text-gray-500">正在读取规则</div>;
}

export default ProjectScanTaskDetail;
