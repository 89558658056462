import { Dispatch, SetStateAction, useState } from "react";

import {
  Catagory,
  Decidability,
  MisraRule,
  getRulesFromChapter,
} from "../standards/Misra";
import { CodingStandard, CodingStandardChapter } from "../standards/Common";
import { Stage } from "../../pages/ProjectRuleSettings";
import { EditorLayout } from "../layouts/EditorLayout";
import Footer from "../containers/Footer";
import Sidebar from "../containers/Sidebar";
import StandardList from "../containers/StandardList";
import { RSButtonWhite } from "../components/RSButton";

interface RuleSelectionPageProps {
  standards: CodingStandard[];
  originalStandards: CodingStandard[];
  nameSelectedStatusMap: Map<string, boolean>;
  updateStandards: Dispatch<SetStateAction<CodingStandard[]>>;
  updateStage: Dispatch<SetStateAction<Stage>>;
  onSelectRule: (rule: MisraRule, selected: boolean) => void;
  onSelectChapter: (chapter: CodingStandardChapter, selected: boolean) => void;
  onSelectStandard: (standard: CodingStandard, selected: boolean) => void;
}

const RuleSelectionPage: React.FC<RuleSelectionPageProps> = ({
  standards,
  originalStandards,
  nameSelectedStatusMap,
  updateStandards,
  updateStage,
  onSelectRule,
  onSelectChapter,
  onSelectStandard,
}) => {
  const onSelectedByCategory = (category: Catagory, selected: boolean) => {
    standards.forEach((standard) =>
      standard.chapters.forEach((chapter) =>
        getRulesFromChapter(chapter).forEach((rule) => {
          if (rule.category === category) rule.selected = selected;
        })
      )
    );
    const mergedStandards = originalStandards
      .filter((standard) => !nameSelectedStatusMap.get(standard.name))
      .concat(standards);
    updateStandards([...mergedStandards]);
  };

  const onSelectedByDecidability = (
    decidability: Decidability,
    selected: boolean
  ) => {
    standards.forEach((standard) =>
      standard.chapters.forEach((chapter) =>
        getRulesFromChapter(chapter).forEach((rule) => {
          if (rule.decidability === decidability) rule.selected = selected;
        })
      )
    );
    const mergedStandards = originalStandards
      .filter((standard) => !nameSelectedStatusMap.get(standard.name))
      .concat(standards);
    updateStandards([...mergedStandards]);
  };

  const selectedRulesNumber = standards.reduce(
    (accum, standard) =>
      accum +
      standard.chapters.reduce(
        (accum, chapter) =>
          accum +
          getRulesFromChapter(chapter).filter((rule) => rule.selected).length,
        0
      ),
    0
  );

  const selectedAnyAdvisory =
    standards.reduce(
      (accum, standard) =>
        accum +
        standard.chapters.reduce(
          (accum, chapter) =>
            accum +
            getRulesFromChapter(chapter).filter(
              (rule) => rule.selected && rule.category === "Advisory"
            ).length,
          0
        ),
      0
    ) > 0;

  const selectedAnyRequired =
    standards.reduce(
      (accum, standard) =>
        accum +
        standard.chapters.reduce(
          (accum, chapter) =>
            accum +
            getRulesFromChapter(chapter).filter(
              (rule) => rule.selected && rule.category === "Required"
            ).length,
          0
        ),
      0
    ) > 0;

  const selectedAllMandatory =
    standards.reduce(
      (accum, standard) =>
        accum +
        standard.chapters.reduce(
          (accum, chapter) =>
            accum +
            getRulesFromChapter(chapter).filter(
              (rule) => rule.category === "Mandatory" && !rule.selected
            ).length,
          0
        ),
      0
    ) === 0;

  const needHiddenSidebar =
    standards.reduce(
      (accum, standard) =>
        accum +
        standard.chapters.reduce(
          (accum, chapter) =>
            accum +
            getRulesFromChapter(chapter).filter(
              (rule) =>
                /^(googlecpp|cwe\/cwe_|cert|go|typescript|javascript)/.test(
                  rule.id
                ) === false
            ).length,
          0
        ),
      0
    ) === 0;

  const needShowDecidablity =
    standards.reduce(
      (accum, standard) =>
        accum +
        standard.chapters.reduce(
          (accum, chapter) =>
            accum +
            getRulesFromChapter(chapter).filter((rule) =>
              /^(misra_c_2012)/.test(rule.id)
            ).length,
          0
        ),
      0
    ) !== 0;

  const showHintForAdvisory = selectedAnyAdvisory && !selectedAllMandatory;
  const showHintForRequired = selectedAnyRequired && !selectedAllMandatory;
  // Key: two-length bit with "showHintForRequired" as higher and "showHintForAdvisory" as lower
  const stagesOrder: Map<number, JSX.Element> = new Map([
    [0, <></>],
    [
      1,
      <p className="text-red-500">
        如未选必选类别“强制”，仅选择“建议”可能会导致您的代码不合规。
      </p>,
    ],
    [
      2,
      <p className="text-amber-600">
        如未选必选类别“强制”，仅选择“要求”可能会导致您的代码不合规。
      </p>,
    ],
    [
      3,
      <p className="text-red-500">
        如未选必选类别“强制”，仅选择“要求”和“建议”可能会导致您的代码不合规。
      </p>,
    ],
  ]);

  const [focusedRuleId, setFocusedRuleId] = useState<string | null>(null);

  return (
    <>
      <title>规则设置工具 - 添加检查项</title>
      <EditorLayout
        sidebarHidden={needHiddenSidebar}
        sidebar={
          <div>
            <Sidebar
              standards={standards}
              showDecidability={needShowDecidablity}
              onSelectedByCategory={onSelectedByCategory}
              onSelectedByDecidability={onSelectedByDecidability}
            />
          </div>
        }
        editor={
          <>
            {standards.map((standard) => (
              <StandardList
                key={standard.name}
                standard={standard}
                onSelectRule={onSelectRule}
                onSelectChapter={onSelectChapter}
                onSelectStandard={onSelectStandard}
                focusedRuleId={focusedRuleId}
                setFocusedRuleId={setFocusedRuleId}
              />
            ))}
          </>
        }
        footer={
          <Footer
            onClickNextStep={() => updateStage("edit settings")}
            disabled={false}
          >
            <div className="ml-4 flex items-center space-x-6">
              <RSButtonWhite
                className="bg-white text-black hover:bg-white"
                label="上一步"
                onClick={() => updateStage("select sets")}
              />
              <span className="text-sm">
                <p>已选择 {selectedRulesNumber} 条规则</p>
                {stagesOrder.get(
                  (showHintForAdvisory ? 1 : 0) + (showHintForRequired ? 2 : 0)
                )}
              </span>
            </div>
          </Footer>
        }
      />
    </>
  );
};

export default RuleSelectionPage;
