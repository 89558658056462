import { Dispatch, SetStateAction, useState } from "react";

import { CodingStandardChapter, CodingStandard } from "../standards/Common";
import { MisraRule, getRulesFromChapter } from "../standards/Misra";
import { Stage } from "../../pages/ProjectRuleSettings";
import { EditorLayout } from "../layouts/EditorLayout";
import Footer from "../containers/Footer";
import { RSButtonWhite } from "../components/RSButton";
import RuleOptionsEditor from "../containers/RuleOptionsEditor";
import RuleList from "../containers/RuleList";

interface SettingsEditorProps {
  standards: CodingStandard[];
  updateStandards: Dispatch<SetStateAction<CodingStandard[]>>;
  updateStage: Dispatch<SetStateAction<Stage>>;
  onSelectRule: (rule: MisraRule, selected: boolean) => void;
  onSelectChapter: (chapter: CodingStandardChapter, selected: boolean) => void;
  onSelectStandard: (standard: CodingStandard, selected: boolean) => void;
}

const SettingsEditorPage: React.FC<SettingsEditorProps> = ({
  standards,
  updateStandards,
  updateStage,
}) => {
  const [rulesToApply, setRulesToApply] = useState(() => {
    const ruleMap = new Map<string, boolean>();
    standards.forEach((std) => {
      std.chapters.forEach((chapter) => {
        getRulesFromChapter(chapter).forEach((rule) => {
          if (rule.selected) {
            ruleMap.set(rule.id, true);
          }
        });
      });
    });
    return ruleMap;
  });

  const [focusedRuleId, setFocusedRuleId] = useState<string | null>(null);

  return (
    <>
      <title>规则设置工具 - 批量设定</title>
      <EditorLayout
        sidebar={
          <div className="p-4">
            {standards.map((standard, i) => (
              <RuleList
                key={standard.name}
                standard={standard}
                rulesToApply={rulesToApply}
                setRulesToApply={setRulesToApply}
                setFocusedRuleId={setFocusedRuleId}
              />
            ))}
          </div>
        }
        editor={
          <>
            {standards.map((standard, i) => (
              <RuleOptionsEditor
                key={standard.name}
                rulesToApply={rulesToApply}
                standard={standard}
                updateStandards={updateStandards}
                focusedRuleId={focusedRuleId}
                setFocusedRuleId={setFocusedRuleId}
              />
            ))}
          </>
        }
        footer={
          <Footer
            onClickNextStep={() => updateStage("preview")}
            disabled={false}
          >
            <div className="mt-4 mt-0 ml-4 flex space-x-6">
              <RSButtonWhite
                className="bg-white text-black hover:bg-white"
                label="上一步"
                onClick={() => updateStage("select rules")}
              />
            </div>
          </Footer>
        }
        sidebarHidden={false}
      />
    </>
  );
};

export default SettingsEditorPage;
