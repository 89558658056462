import { PropsWithChildren } from "react";

export default function OptionalModal(
  props: PropsWithChildren<{
    showModal: boolean;
    confirmHandler: (e: React.MouseEvent<HTMLElement>) => void;
    cancelHandler: (e: React.MouseEvent<HTMLElement>) => void;
    content: string;
  }>
) {
  return (
    <>
      {props.showModal && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            ></span>
            <div className="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <p className="text-xs leading-6 text-gray-700">
                  {props.content}
                </p>
              </div>
              <div className="mb-2 bg-white px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  className="ml-3 inline-flex items-center rounded-md border border-gray-300 bg-sky-500 px-3 py-2 text-xs leading-4 text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                  onClick={props.confirmHandler}
                >
                  确定
                </button>
                <button
                  type="button"
                  className="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-xs leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                  onClick={props.cancelHandler}
                >
                  取消
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
