import {
  createRef,
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import RuleOptionField, {
  StandardOptions,
} from "../components/RuleOptionField";

import { CodingStandard } from "../standards/Common";
import { RuleOption, getRulesFromChapter } from "../standards/Misra";

interface RuleOptionsEditorProps {
  readOnly?: boolean;
  rulesToApply?: Map<string, boolean>; // No need if readOnly
  standard: CodingStandard;
  updateStandards: Dispatch<SetStateAction<CodingStandard[]>>;
  focusedRuleId?: string | null;
  setFocusedRuleId?: Dispatch<SetStateAction<string | null>>;
}

const RuleOptionsEditor: React.FC<RuleOptionsEditorProps> = ({
  readOnly,
  rulesToApply,
  standard,
  updateStandards,
  focusedRuleId,
  setFocusedRuleId,
}) => {
  const [ruleRefs, setRuleRefs] = useState<{
    [key: string]: RefObject<HTMLDivElement>;
  }>({});
  const selectedRules = standard.chapters.flatMap((chapter) =>
    getRulesFromChapter(chapter).filter((rule) => rule.selected)
  );

  useEffect(() => {
    // set up DOM refs for each rule
    setRuleRefs((refs) => {
      selectedRules.forEach((rule) => {
        refs[rule.id] = createRef<HTMLDivElement>();
      });
      return refs;
    });
  }, [selectedRules]);

  useEffect(() => {
    // scroll to focused rule when it changes
    if (!focusedRuleId || !setFocusedRuleId) return;
    ruleRefs[focusedRuleId]?.current?.scrollIntoView();
    setFocusedRuleId(null);
  }, [focusedRuleId, ruleRefs, setFocusedRuleId]);

  return (
    <>
      <div className="divide-y border-b">
        {!readOnly && (
          <p className="m-8 my-4 text-xl font-bold">{standard.name}</p>
        )}
        {selectedRules.map((rule) => (
          <div key={rule.id} className="my-4" ref={ruleRefs[rule.id]}>
            <p className="ml-14 mb-2 mt-8 text-lg font-semibold">
              {rule.ident} {rule.subject}
            </p>
            {(
              Object.entries(rule.options) as [
                string,
                RuleOption<string | number | boolean>
              ][]
            ) // TODO: use different input type for different option type
              .map(([optionName, option]) => (
                <RuleOptionField
                  key={`${rule.id} ${optionName}`}
                  optionName={optionName}
                  option={option}
                  readOnly={readOnly ?? false}
                  onChange={(newValue, apply) => {
                    standard.chapters.forEach((c) =>
                      getRulesFromChapter(c).forEach((r) => {
                        if (
                          r.id === rule.id || // We need to update for this rule even if nothing is chosen
                          (rulesToApply &&
                            rulesToApply.get(r.id) &&
                            rulesToApply.get(rule.id))
                        ) {
                          (
                            Object.entries(r.options) as [
                              string,
                              RuleOption<string | number | boolean>
                            ][]
                          ).forEach(([ruleOptionName, ruleOption]) => {
                            if (ruleOptionName === optionName) {
                              if (
                                ruleOptionName ===
                                  "externalIdentifierSignificance" ||
                                ruleOptionName === "maxLoop"
                              ) {
                                ruleOption.currentValue = Number(newValue);
                              } else {
                                if (!apply) {
                                  ruleOption.currentValue = newValue;
                                  // updates externalIdentifierSignificance when updating standard
                                  if (
                                    ruleOptionName === "standard" &&
                                    r.options.externalIdentifierSignificance
                                  ) {
                                    if (
                                      newValue === StandardOptions.C89 ||
                                      newValue === StandardOptions.C90
                                    ) {
                                      r.options.externalIdentifierSignificance.currentValue = 31;
                                    } else if (
                                      newValue === StandardOptions.C99 ||
                                      newValue === StandardOptions.C11
                                    ) {
                                      r.options.externalIdentifierSignificance.currentValue = 63;
                                    }
                                  }
                                } else {
                                  ruleOption.apply = newValue ? true : false;
                                }
                              }
                              ruleOption.affectedBy = rule.id;
                            }
                          });
                        }
                      })
                    );
                    updateStandards((stds) => {
                      return stds.map((std) => {
                        if (std.name === standard.name) {
                          return standard;
                        }
                        return std;
                      });
                    });
                  }}
                />
              ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default RuleOptionsEditor;
