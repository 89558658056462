import { RSButton } from "../components/RSButton";

interface FooterProps {
  children: JSX.Element;
  onClickNextStep: () => void;
  disabled: boolean;
}

const Footer: React.FC<FooterProps> = (props) => {
  return (
    <div className="flex w-full items-center justify-between py-6 px-4 ">
      {props.children}
      <div className="mt-4 mt-0 mr-10 flex space-x-6 text-right">
        <RSButton
          className="w-24"
          label="下一步"
          onClick={props.onClickNextStep}
          disabled={props.disabled}
        />
      </div>
    </div>
  );
};

export default Footer;
