/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

export function ClassNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
