/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import { qrcodegen } from "../qrcodegen";

function QRCode(props: { text: string }) {
  const border = 0;
  const lightColor = "white";
  const darkColor = "black";
  const qr = qrcodegen.QrCode.encodeText(props.text, qrcodegen.QrCode.Ecc.LOW);
  const parts = [];
  for (let y = 0; y < qr.size; y++) {
    for (let x = 0; x < qr.size; x++) {
      if (qr.getModule(x, y))
        parts.push("M" + (x + border) + "," + (y + border) + "h1v1h-1z");
    }
  }
  return (
    <svg
      viewBox={`0 0 ${qr.size + border * 2} ${qr.size + border * 2}`}
      stroke="none"
    >
      <rect width="100%" height="100%" fill={lightColor} />
      <path d={parts.join(" ")} fill={darkColor} />
    </svg>
  );
}

export default QRCode;
