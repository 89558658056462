/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import { useEffect, useState } from "react";
import ProjectLayout from "../components/ProjectLayout";
import {
  BreadcrumbItem,
  Breadcrumbs,
  MainContentWithTitle,
} from "../uilib/layouts";

interface APIPortalProjectAPISettings {
  projectAPISettingsEnabled: boolean;
  projectName: string;
  runnerKey: string;
}

declare global {
  interface Window {
    apiPortalProjectAPISettings: APIPortalProjectAPISettings;
  }
}

function ProjectAPISettings() {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);
  const [runnerKey, setRunnerKey] = useState("");
  const [clickCopyKeySuccess, setClickCopyKeySuccess] = useState(false);
  const [clickCopyScriptSuccess, setClickCopyScriptSuccess] = useState(false);

  const project_id = new URLSearchParams(window.location.search).get(
    "project_id"
  );

  useEffect(() => {
    setBreadcrumbs([
      {
        name: window.apiPortalProjectAPISettings.projectName,
        href: "/project?project_id=" + project_id,
        current: false,
      },
      {
        name: "API",
        href: "",
        current: true,
      },
    ]);
    setRunnerKey(window.apiPortalProjectAPISettings.runnerKey);
  }, [project_id]);

  return (
    <ProjectLayout>
      <Breadcrumbs pages={breadcrumbs} />
      <MainContentWithTitle title="API">
        {window.apiPortalProjectAPISettings.projectAPISettingsEnabled ? (
          <div className="container m-5">
            <div className="bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  API Key
                </h3>
                <div className="mt-2 rounded-md bg-gray-50 px-6 py-2 sm:flex sm:items-start sm:justify-between">
                  <div>
                    <div className="mt-2 max-w-4xl text-sm text-gray-500">
                      <p>{runnerKey}</p>
                    </div>
                  </div>
                  <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
                    <button
                      type="button"
                      onClick={() => {
                        navigator.clipboard.writeText(runnerKey);
                        setClickCopyKeySuccess(true);
                      }}
                      className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      {clickCopyKeySuccess ? "已复制" : "复制"}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-10 bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  样例脚本
                </h3>
                <div className="mt-2 rounded-md bg-gray-50 px-6 py-2 sm:flex sm:items-start sm:justify-between">
                  <div className="mt-2 text-sm text-gray-500">
                    <p>python3 client.py --zip_file /path/to/zipfile</p>
                  </div>
                  <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
                    <button
                      type="button"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          "python3 client.py --zip_file /path/to/zipfile"
                        );
                        setClickCopyScriptSuccess(true);
                      }}
                      className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      {clickCopyScriptSuccess ? "已复制" : "复制"}
                    </button>
                  </div>
                </div>
                <div className="mt-3 text-sm leading-6">
                  <a
                    href={"/download/client.py?project_id=" + project_id}
                    target="_blank"
                    rel="noreferrer"
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    点击下载样例脚本
                    <span aria-hidden="true"> &rarr;</span>
                  </a>
                </div>
              </div>
            </div>

            <div className="mt-10 bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  API 说明书
                </h3>
                <div className="mt-3 text-sm leading-6">
                  <a
                    href="/api.html"
                    target="_blank"
                    rel="noreferrer"
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    下载API说明书
                    <span aria-hidden="true"> &rarr;</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="my-4 text-gray-500">
            当前使用许可不包括<span className="mx-0.5">API</span>功能
          </div>
        )}
      </MainContentWithTitle>
    </ProjectLayout>
  );
}

export default ProjectAPISettings;
