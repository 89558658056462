import { RuleOption } from "../standards/Misra";
import RSCheckboxIcon from "./RSCheckboxIcon";

export enum StandardOptions {
  C89 = "C89",
  C90 = "C90",
  C99 = "C99",
  C11 = "C11",
}

const SeverityOptionsMap = new Map<string, string>([
  ["highest", "最高"],
  ["high", "高"],
  ["medium", "中"],
  ["low", "低"],
  ["lowest", "最低"],
]);

interface RuleOptionFieldProps {
  optionName: string;
  option: RuleOption<any>;
  readOnly: boolean;
  onChange: (newValue: string | number | boolean, apply?: boolean) => void;
}

const OPTION_NAMES = new Map<string, string>([
  ["standard", "标准选项"],
  ["externalIdentifierSignificance", "外部标识符有效字符长度"],
  ["caseSensitive", "是否区分大小写"],
  ["checkIncludeGuards", "检查包含保护符"],
  ["maxLoop", "最大循环"],
  ["maxNodes", "最大节点数（exploded graph）"],
  ["maxReportNum", "最大报错次数"],
  ["severity", "规则级别"],
  ["aggressiveMode", "检查策略"],
  ["CSAAnalyzerInliningMode", "全函数内联分析"],
  ["maximumInlineFuncLine", "最大内联函数行数"],
  ["maximumAllowedFuncLine", "最大函数行数"],
  ["maximumAllowedReturnNum", "最大返回值数量"],
  ["projectName", "自定义项目名称"],
  ["rootDir", "自定义根目录"],
  ["reportErrorInCallingSystemFunction", "报告系统函数调用错误"],
  ["structMemberLimit", "结构体最大成员数"],
  ["functionParmLimit", "函数定义最大参数数"],
  ["functionArgLimit", "函数调用最大参数数"],
  ["nestedRecordLimit", "结构体或联合最大内嵌深度"],
  ["nestedExprLimit", "括号表达式最大嵌套层数"],
  ["switchCaseLimit", "switch语句最大case标签数"],
  ["enumConstantLimit", "枚举最大枚举常量数"],
  ["stringCharLimit", "字符串字面量最大字符数"],
  ["externIDLimit", "最大外部标识符数"],
  ["externIDCharLimit", "外部标识符最大有效初始字符数"],
  ["macroIDLimit", "最大宏标识符数"],
  ["macroParmLimit", "宏定义最大参数数"],
  ["macroArgLimit", "宏调用最大参数数"],
  ["nestedBlockLimit", "块最大嵌套层数"],
  ["nestedIncludeLimit", "#include文件最大嵌套层数"],
  ["ioMIDCharLimit", "内部标识符或宏名称最大有效初始字符数"],
  ["nestedCondIncluCount", "条件包含最大嵌套深度"],
  ["blockIdCount", "块中最大标识符数"],
  ["nestedDeclCount", "括号声明符最大嵌套深度"],
  ["modifyDeclCount", "指针、数组和函数声明符修改声明最大限制数"],
]); // TODO: use react-i18next

const RuleOptionField: React.FC<RuleOptionFieldProps> = ({
  optionName,
  option,
  readOnly,
  onChange,
}) => {
  const isChanged =
    option.currentValue !== option.previousValue ||
    option.apply !== option.previousApply;
  return (
    <div className="flex flex-row items-center space-x-3 space-y-4">
      {!readOnly &&
        (optionName === "maxReportNum" ||
          optionName === "projectName" ||
          optionName === "rootDir" ||
          optionName === "severity") && (
          <label className="ml-4 mr-8 mt-4 flex w-48 items-center">
            <input
              className="hidden"
              type="checkbox"
              checked={option.apply ?? false}
              onChange={(e) => {
                onChange(e.target.checked, true);
              }}
            />
            <span className="m-3">
              <RSCheckboxIcon max={1} cur={option.apply ? 1 : 0} />
            </span>
            {OPTION_NAMES.get(optionName) ?? optionName}
          </label>
        )}
      {((readOnly && option.apply) ||
        !(
          optionName === "maxReportNum" ||
          optionName === "projectName" ||
          optionName === "rootDir" ||
          optionName === "severity"
        )) && (
        <label className="mt-4 ml-12 w-48 pl-2">
          {OPTION_NAMES.get(optionName) ?? optionName}
        </label>
      )}
      {optionName === "standard" &&
        (readOnly ? (
          <input
            className={
              "h-8 w-48 rounded border-0 border-transparent text-center dark:bg-gray-600" +
              (isChanged ? " text-sky-600" : "")
            }
            type="text"
            readOnly
            value={option.currentValue ?? option.defaultValue}
          />
        ) : (
          <select
            className="h-8 w-48 cursor-pointer rounded border-0 py-1 pl-[5rem] text-center ring-1 ring-gray-300 focus:ring-2 focus:ring-sky-600 dark:bg-gray-600"
            value={option.currentValue ?? option.defaultValue}
            onChange={(e) => onChange(e.target.value)}
          >
            <option value={StandardOptions.C89}>{StandardOptions.C89}</option>
            <option value={StandardOptions.C90}>{StandardOptions.C90}</option>
            <option value={StandardOptions.C99}>{StandardOptions.C99}</option>
            <option value={StandardOptions.C11}>{StandardOptions.C11}</option>
          </select>
        ))}
      {optionName === "externalIdentifierSignificance" && (
        <input
          className={
            "h-8 w-48 rounded border-0 border-transparent text-center dark:bg-gray-600" +
            (readOnly
              ? ""
              : " ring-1 ring-gray-300 focus:ring-2 focus:ring-sky-600") +
            (readOnly && isChanged ? " text-sky-600" : "")
          }
          type={readOnly ? "text" : "number"}
          readOnly={readOnly}
          value={option.currentValue ?? option.defaultValue}
          min="1"
          max="63"
          onChange={(e) => onChange(e.target.value)}
        />
      )}
      {(optionName === "caseSensitive" ||
        optionName === "checkIncludeGuards" ||
        optionName === "reportErrorInCallingSystemFunction") &&
        (readOnly ? (
          <input
            className={
              "h-8 w-48 rounded border-0 border-transparent text-center dark:bg-gray-600" +
              (isChanged ? " text-sky-600" : "")
            }
            type="text"
            readOnly
            value={option.currentValue ?? option.defaultValue ? "是" : "否"}
          />
        ) : (
          <select
            className="h-10 w-48 cursor-pointer rounded border-0 pl-[5rem] text-center ring-1 ring-gray-300 focus:ring-2 focus:ring-sky-600 dark:bg-gray-600"
            value={option.currentValue ?? option.defaultValue}
            onChange={(e) => onChange(e.target.value === "true")}
          >
            <option value="true">是</option>
            <option value="false">否</option>
          </select>
        ))}
      {optionName === "CSAAnalyzerInliningMode" &&
        (readOnly ? (
          <input
            className={
              "h-8 w-48 rounded border-0 border-transparent text-center dark:bg-gray-600" +
              (isChanged ? " text-sky-600" : "")
            }
            type="text"
            readOnly
            value={option.currentValue ?? option.defaultValue ? "打开" : "关闭"}
          />
        ) : (
          <select
            className="h-10 w-48 cursor-pointer rounded border-0 pl-[5rem] text-center ring-1 ring-gray-300 focus:ring-2 focus:ring-sky-600 dark:bg-gray-600"
            value={option.currentValue ?? option.defaultValue}
            onChange={(e) => onChange(e.target.value === "true")}
          >
            <option value="true">打开</option>
            <option value="false">关闭</option>
          </select>
        ))}
      {optionName === "aggressiveMode" &&
        (readOnly ? (
          <input
            className={
              "h-8 w-48 rounded border-0 border-transparent text-center dark:bg-gray-600" +
              (isChanged ? " text-sky-600" : "")
            }
            type="text"
            readOnly
            value={
              option.currentValue ?? option.defaultValue
                ? "减少漏报"
                : "减少误报"
            }
          />
        ) : (
          <select
            className="h-10 w-48 cursor-pointer rounded border-0 pl-[4rem] text-center ring-1 ring-gray-300 focus:ring-2 focus:ring-sky-600 dark:bg-gray-600"
            value={option.currentValue ?? option.defaultValue}
            onChange={(e) => onChange(e.target.value === "true")}
          >
            <option value="true">减少漏报</option>
            <option value="false">减少误报</option>
          </select>
        ))}
      {(optionName === "maxLoop" ||
        optionName === "maxNodes" ||
        optionName === "maximumInlineFuncLine" ||
        optionName === "maximumAllowedFuncLine" ||
        optionName === "maximumAllowedReturnNum" ||
        optionName === "structMemberLimit" ||
        optionName === "functionParmLimit" ||
        optionName === "functionArgLimit" ||
        optionName === "nestedRecordLimit" ||
        optionName === "nestedExprLimit" ||
        optionName === "switchCaseLimit" ||
        optionName === "enumConstantLimit" ||
        optionName === "stringCharLimit" ||
        optionName === "externIDLimit" ||
        optionName === "externIDCharLimit" ||
        optionName === "macroIDLimit" ||
        optionName === "macroParmLimit" ||
        optionName === "macroArgLimit" ||
        optionName === "nestedBlockLimit" ||
        optionName === "nestedIncludeLimit" ||
        optionName === "ioMIDCharLimit" ||
        optionName === "nestedCondIncluCount" ||
        optionName === "blockIdCount" ||
        optionName === "nestedDeclCount" ||
        optionName === "modifyDeclCount") && (
        <input
          className={
            "h-8 w-48 rounded border-0 border-transparent text-center dark:bg-gray-600" +
            (readOnly
              ? ""
              : " ring-1 ring-gray-300 focus:ring-2 focus:ring-sky-600") +
            (readOnly && isChanged ? " text-sky-600" : "")
          }
          type={readOnly ? "text" : "number"}
          min="0"
          readOnly={readOnly}
          value={option.currentValue ?? option.defaultValue}
          onChange={(e) => onChange(e.target.value)}
        />
      )}
      {(!readOnly || option.apply) && optionName === "maxReportNum" && (
        <input
          className={
            "h-8 w-48 rounded border-0 border-transparent text-center dark:bg-gray-600" +
            (readOnly
              ? ""
              : " ring-1 ring-gray-300 focus:ring-2 focus:ring-sky-600") +
            (readOnly && isChanged ? " text-sky-600" : "")
          }
          type={readOnly ? "text" : "number"}
          readOnly={readOnly}
          value={option.currentValue ?? option.defaultValue}
          min="0"
          max="10000"
          onChange={(e) => onChange(e.target.value)}
        />
      )}
      {(!readOnly || option.apply) &&
        (optionName === "projectName" || optionName === "rootDir") && (
          <input
            className={
              "h-8 w-48 rounded border-0 border-transparent text-center dark:bg-gray-600" +
              (readOnly
                ? ""
                : " ring-1 ring-gray-300 focus:ring-2 focus:ring-sky-600") +
              (readOnly && isChanged ? " text-sky-600" : "")
            }
            type={"text"}
            readOnly={readOnly}
            value={option.currentValue ?? option.defaultValue}
            onChange={(e) => onChange(e.target.value)}
          />
        )}
      {!readOnly && optionName === "severity" && (
        <select
          className="h-8 w-48 cursor-pointer rounded border-0 py-1 pl-[5rem] text-center ring-1 ring-gray-300 focus:ring-2 focus:ring-sky-600 dark:bg-gray-600"
          value={option.currentValue ?? option.defaultValue}
          onChange={(e) => onChange(e.target.value)}
        >
          <option value="highest">{SeverityOptionsMap.get("highest")}</option>
          <option value="high">{SeverityOptionsMap.get("high")}</option>
          <option value="medium">{SeverityOptionsMap.get("medium")}</option>
          <option value="low">{SeverityOptionsMap.get("low")}</option>
          <option value="lowest">{SeverityOptionsMap.get("lowest")}</option>
        </select>
      )}
      {readOnly && option.apply && optionName === "severity" && (
        <input
          className={
            "h-8 w-48 rounded border-0 border-transparent text-center dark:bg-gray-600" +
            (isChanged ? " text-sky-600" : "")
          }
          type="text"
          readOnly
          value={
            SeverityOptionsMap.get(option.currentValue) ??
            SeverityOptionsMap.get(option.defaultValue)
          }
        />
      )}
    </div>
  );
};

export default RuleOptionField;
