/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import { PlusIcon } from "@heroicons/react/24/outline";
import { ToolbarButton } from "../uilib/buttons";

export function EmptyScanPage(props: {
  projectID: string;
  prerelease?: boolean;
}) {
  return (
    <>
      <div className="container mx-0 mt-20 flex min-w-full flex-col items-center py-10 px-10">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#9CA3AF"
          className="h-9 w-9"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 10.5v6m3-3H9m4.06-7.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
          />
        </svg>

        <p className="mb-5 text-sm text-gray-900">暂无扫描任务</p>
        {props.prerelease && (
          <a href={`/prerelease/newscantask?project_id=${props.projectID}`}>
            <ToolbarButton icon={PlusIcon} disabled={false}>
              新建扫描任务
            </ToolbarButton>
          </a>
        )}
      </div>
    </>
  );
}

export function EmptyProjectPage() {
  return (
    <>
      <div className="container mx-0 mt-20 flex min-w-full flex-col items-center py-10 px-10">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#9CA3AF"
          className="h-9 w-9"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 10.5v6m3-3H9m4.06-7.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
          />
        </svg>

        <p className="mb-5 text-sm text-gray-900">暂无项目</p>
      </div>
    </>
  );
}

export function EmptyUserPage() {
  return (
    <>
      <div className="container mx-0 mt-20 flex min-w-full flex-col items-center py-10 px-10">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#9CA3AF"
          className="h-9 w-9"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 10.5v6m3-3H9m4.06-7.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
          />
        </svg>

        <p className="mb-5 text-sm text-gray-900">暂无用户</p>
      </div>
    </>
  );
}

export function EmptyBranchPage() {
  return (
    <>
      <div className="container mx-0 mt-20 flex min-w-full flex-col items-center py-10 px-10">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#9CA3AF"
          className="h-9 w-9"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 10.5v6m3-3H9m4.06-7.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
          />
        </svg>

        <p className="mb-5 text-sm text-gray-900">暂无分支信息</p>
      </div>
    </>
  );
}
