import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { api } from "../api";
import RSArrorIcon from "../rulesettings/components/RSArrorIcon";
import { HelpText, TextField } from "../uilib/forms";
import SlideOver from "./SlideOver";

export interface GithubSettings extends api.GithubSettings {}

export function GithubSettingsForm(props: {
  settings: GithubSettings;
  updateSettings: (_: GithubSettings) => void;
  updateForm?: boolean;
}) {
  const [showGithubAdvancedSettings, setShowGithubAdvancedSettings] =
    useState(false);

  return (
    <>
      <div className="sm:col-span-6">
        <TextField
          id="github-git-clone-url"
          value={props.settings.gitCloneURL}
          onChange={(v) =>
            props.updateSettings({ ...props.settings, gitCloneURL: v })
          }
          disabled={props.updateForm}
        >
          git clone 地址
        </TextField>
      </div>
      <div className="sm:col-span-6">
        <TextField
          id="github-personal-access-token"
          type="password"
          value={props.settings.personalAccessToken}
          onChange={(v) =>
            props.updateSettings({ ...props.settings, personalAccessToken: v })
          }
        >
          个人访问令牌
        </TextField>
      </div>
      <div className="sm:col-span-8">
        <div
          className="flex items-center"
          onClick={() => {
            setShowGithubAdvancedSettings((prev) => !prev);
          }}
        >
          <RSArrorIcon
            className="mr-2 inline-block w-4"
            direction={showGithubAdvancedSettings ? "down" : "right"}
            color="blue"
          />
          <label className="text-sm font-black text-sky-600">高级设置</label>
        </div>
      </div>
      {showGithubAdvancedSettings && (
        <div className="sm:col-span-12">
          <TextField
            id="github-branches"
            value={props.settings.branches}
            onChange={(v) =>
              props.updateSettings({ ...props.settings, branches: v })
            }
          >
            提交后扫描触发分支
          </TextField>
          <HelpText>
            <div>
              选填，需要触发提交后扫描的分支名，多个分支则通过逗号（,）分开，留空则默认使用
              master 分支
            </div>
            <div>如 master, postsubmit</div>
          </HelpText>
        </div>
      )}
    </>
  );
}

export function GithubHelp(props: {
  show: boolean;
  set: (_: React.SetStateAction<boolean>) => void;
  webhookProxy: string;
}) {
  return (
    <SlideOver
      open={props.show}
      setOpen={props.set}
      title="如何配置一个专用于触发提交前/提交后代码扫描的 Webhook？"
    >
      <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <p className="text-sm text-yellow-700">
              该操作必须由<span className="mx-0.5">GitHub</span>
              项目所有者或者具有创建 Webhook 权限的用户执行。
            </p>
          </div>
        </div>
      </div>
      <div className="text-base font-semibold leading-4 text-gray-900">
        <p className="mt-4">创建个人访问令牌</p>
      </div>
      <div className="text-sm text-gray-700">
        <p className="mt-4">
          点击用户头像，选择 <b>Settings</b>，然后在左侧边栏选择{" "}
          <b>Developer Settings</b>，进入后点击{" "}
          <b>Personal access tokens {`>`} Tokens(classic)</b>，点击{" "}
          <b>Generate new token {`>`} Generate new token (classic)</b>。。
        </p>
        <p className="mt-4">
          <b>Select scopes</b> 勾选 <b>repo</b>；其他选项按需求填写，完成后点击{" "}
          <b>Generate token</b>。
        </p>
      </div>
      <div className="text-base font-semibold leading-4 text-gray-900">
        <p className="mt-4">提交前扫描</p>
      </div>
      <div className="text-sm text-gray-700">
        <p className="mt-4">
          进入需要触发提交前代码扫描的项目，选择{" "}
          <b>
            Settings {`>`} Webhooks {`>`} Add webhook
          </b>
          。
        </p>
        <p className="mt-4">
          在 <b>Payload URL</b> 中，输入{" "}
          <b>{props.webhookProxy + "change_hook/github"}</b>
        </p>
        <p className="mt-4">
          在 <b>Content type</b> 中，选择 <b>application/json</b>。
        </p>
        <p className="mt-4">
          在 <b>Which events would you like to trigger this webhook?</b>{" "}
          中，选择 <b>Let me select individual events. {`>`} Pull requests</b>。
        </p>
        <p className="mt-4">
          点击 <b>Add webhook</b>，完成 webhook 配置。
        </p>
      </div>
      <div className="text-base font-semibold leading-4 text-gray-900">
        <p className="mt-4">提交后扫描</p>
      </div>
      <div className="text-sm text-gray-700">
        <p className="mt-4">
          进入需要触发提交后代码扫描的项目，选择{" "}
          <b>
            Settings {`>`} Webhooks {`>`} Add webhook
          </b>
          。
        </p>
        <p className="mt-4">
          在 <b>Payload URL</b> 中，输入{" "}
          <b>{props.webhookProxy + "change_hook/github"}</b>
        </p>
        <p className="mt-4">
          在 <b>Content type</b> 中，选择 <b>application/json</b>。
        </p>
        <p className="mt-4">
          在 <b>Which events would you like to trigger this webhook?</b>{" "}
          中，选择 <b>Let me select individual events. {`>`} Pushes</b>。
        </p>
        <p className="mt-4">
          点击 <b>Add webhook</b>，完成 webhook 配置。
        </p>
      </div>
      <div className="text-base font-semibold leading-4 text-gray-900">
        <p className="mt-4">webhook 关闭 SSL 验证</p>
      </div>
      <div className="text-sm text-gray-700">
        <p className="mt-4">
          如果没有为 {props.webhookProxy} 配置证书，需要在配置 webhook 时，在{" "}
          <b>SSL verification</b> 中，选择 <b>Disable (not recommended)</b>。
        </p>
      </div>
    </SlideOver>
  );
}
