/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import { PlusIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import ReactTimeAgo from "react-time-ago";
import { EmptyProjectPage } from "../components/EmptyPage";
import ProjectGuideTour from "../components/ProjectGuideTour";
import { ToolbarLinkButton } from "../uilib/buttons";
import { MainContentWithTitle } from "../uilib/layouts";
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHead,
  TableRow,
} from "../uilib/tables";
import { HomeLayout } from "../components/ProjectLayout";

interface Project {
  id: string;
  name: string;
  fixed: number;
  dismissed: number;
  outstanding: number;
  linesOfCode: number;
  lastSubmittedAt?: string;
  total: number;
  issueDensity: string;
  newlyDetected: number;
  new: number;
}

interface APIPortalProjectList {
  projectList: Project[];
  statsEnabled: boolean;
  privatizationModalEnabled: boolean;
}

declare global {
  interface Window {
    apiPortalProjectList: APIPortalProjectList;
  }
}

function Home() {
  const firstOpenProjectKey = "naivesystems-first-open-project";
  let firstOpenProject = false;
  if (localStorage.getItem(firstOpenProjectKey) !== "false") {
    firstOpenProject = true;
  }

  const [projects, setProjects] = useState<Project[]>([]);
  const [statsEnabled, setStatsEnabled] = useState(false);

  useEffect(() => {
    setProjects(window.apiPortalProjectList.projectList);
    setStatsEnabled(window.apiPortalProjectList.statsEnabled);
  }, []);

  return (
    <HomeLayout>
      {firstOpenProject && (
        <ProjectGuideTour
          dialogKey={firstOpenProjectKey}
          privatizationModalEnabled={
            window.apiPortalProjectList.privatizationModalEnabled
          }
        ></ProjectGuideTour>
      )}
      <MainContentWithTitle title="项目列表">
        <div className="my-4">
          <ToolbarLinkButton href="/projects/new" icon={PlusIcon}>
            新建项目
          </ToolbarLinkButton>
        </div>
        {projects.length === 0 ? (
          <EmptyProjectPage />
        ) : (
          <Table>
            <TableHead>
              <TableColumn first>项目</TableColumn>
              <TableColumn>已修复</TableColumn>
              <TableColumn>已忽略</TableColumn>
              <TableColumn>未解决</TableColumn>
              <TableColumn>未分类</TableColumn>
              <TableColumn>总计</TableColumn>
              <TableColumn>代码行数</TableColumn>
              <TableColumn>问题密度</TableColumn>
              <TableColumn>上次提交时间</TableColumn>
              <TableColumn last>最近被检测到</TableColumn>
            </TableHead>
            <TableBody>
              {projects.map((project) => (
                <TableRow key={project.id}>
                  <TableCell first>
                    <div className="max-w-[25ch] truncate">
                      <a href={`/project?id=${project.id}`}>{project.name}</a>
                    </div>
                  </TableCell>
                  {statsEnabled ? (
                    <>
                      <TableCell>{project.fixed}</TableCell>
                      <TableCell>{project.dismissed}</TableCell>
                      <TableCell>{project.outstanding}</TableCell>
                      <TableCell>{project.new}</TableCell>
                      <TableCell>{project.total}</TableCell>
                      <TableCell>{project.linesOfCode}</TableCell>
                      <TableCell>{project.issueDensity}</TableCell>
                      <TableCell>
                        {project.lastSubmittedAt &&
                          (Number.isNaN(Date.parse(project.lastSubmittedAt)) ? (
                            project.lastSubmittedAt
                          ) : (
                            <ReactTimeAgo
                              date={Date.parse(project.lastSubmittedAt)}
                            />
                          ))}
                      </TableCell>
                      <TableCell last>{project.newlyDetected}</TableCell>
                    </>
                  ) : (
                    <TableCell colSpan={9} last>
                      <div className="text-center text-gray-300">
                        当前使用许可不包括项目统计功能
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </MainContentWithTitle>
    </HomeLayout>
  );
}

export default Home;
