/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import { TableCellsIcon } from "@heroicons/react/20/solid";
import { DocumentIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import ProjectLayout from "../components/ProjectLayout";
import { EmptyBranchPage } from "../components/EmptyPage";
import { ToolbarButton } from "../uilib/buttons";
import {
  BreadcrumbItem,
  Breadcrumbs,
  MainContentWithTitle,
} from "../uilib/layouts";
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHead,
  TableRow,
} from "../uilib/tables";

interface Branch {
  id: string;
  name: string;
  fixedIssueCount: number;
  ignoredIssueCount: number;
  unsolvedIssueCount: number;
  linesOfCode: number;
  submittedAt: string; // must be in ISO 8601 format
  totalIssueCount: number;
  IssueDensity: number;
  recentlyDetectedIssueCount: number;
  newIssueCount: number;
}

interface APIPortalProjectBranches {
  branchesList: Branch[];
  pageSize: number;
  itemCount: number;
  totalCount: number;
  branchesEnabled: boolean;
  projectName: string;
}

declare global {
  interface Window {
    apiPortalProjectBranches: APIPortalProjectBranches;
  }
}

function setAdd(s: Set<string>, x: string) {
  return new Set(s).add(x);
}

function setRemove(s: Set<string>, x: string) {
  if (!s.has(x)) return s;
  let ns = new Set(s);
  ns.delete(x);
  return ns;
}

function ProjectBranches() {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);
  const [branches, setBranches] = useState<Branch[]>([]);
  const [selectedBranches, setSelectedBranches] = useState<Set<string>>(
    new Set<string>()
  );
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [itemCount, setItemCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [searchStr, setSearchStr] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("project_id") === null) {
      throw new Error("project id is missing");
    }
    setBreadcrumbs([
      {
        name: window.apiPortalProjectBranches.projectName,
        href: "/project?id=" + params.get("project_id"),
        current: false,
      },
      {
        name: "分支",
        href: "",
        current: true,
      },
    ]);
    if (window.apiPortalProjectBranches.branchesList !== undefined) {
      setBranches(window.apiPortalProjectBranches.branchesList);
    }
    const pageStr = params.get("page");
    if (pageStr === null) {
      setPage(1);
    } else {
      setPage(parseInt(pageStr));
    }
    setSearchStr(window.location.search);
    setPageSize(window.apiPortalProjectRunners.pageSize);
    setItemCount(window.apiPortalProjectRunners.itemCount);
    setTotalCount(window.apiPortalProjectRunners.totalCount);
  }, []);

  return (
    <ProjectLayout>
      <Breadcrumbs pages={breadcrumbs} />
      <MainContentWithTitle title="分支">
        {window.apiPortalProjectBranches.branchesEnabled ? (
          <>
            <div className="my-4">
              <span className="mr-1">
                <ToolbarButton
                  icon={DocumentIcon}
                  disabled={selectedBranches.size === 0}
                >
                  导出报告
                </ToolbarButton>
              </span>
              <span className="mr-1">
                <ToolbarButton
                  icon={TableCellsIcon}
                  disabled={selectedBranches.size !== 2}
                >
                  对比信息
                </ToolbarButton>
              </span>
            </div>
            {branches.length === 0 ? (
              <EmptyBranchPage />
            ) : (
              <Table
                page={page}
                pageSize={pageSize}
                itemCount={itemCount}
                totalCount={totalCount}
                searchStr={searchStr}
              >
                <TableHead>
                  <TableColumn first></TableColumn>
                  <TableColumn>分支名称</TableColumn>
                  <TableColumn>已修复</TableColumn>
                  <TableColumn>已忽略</TableColumn>
                  <TableColumn>现存未解决</TableColumn>
                  <TableColumn>代码行</TableColumn>
                  <TableColumn>上次提交时间</TableColumn>
                  <TableColumn>总计</TableColumn>
                  <TableColumn>问题密度</TableColumn>
                  <TableColumn>最近被检测到</TableColumn>
                  <TableColumn last>新增</TableColumn>
                </TableHead>
                <TableBody>
                  {branches.map((branch, idx) => (
                    <TableRow key={idx}>
                      <TableCell first>
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-sky-500 focus:ring-sky-500"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            if (event.target.checked) {
                              setSelectedBranches((s) => setAdd(s, branch.id));
                            } else {
                              setSelectedBranches((s) =>
                                setRemove(s, branch.id)
                              );
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <div className="xl:mr-16 xl:w-24">{branch.name}</div>
                      </TableCell>
                      <TableCell>
                        <div className="text-right xl:mr-8 xl:w-16">
                          {branch.fixedIssueCount >= 0
                            ? branch.fixedIssueCount
                            : "暂无信息"}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="text-right xl:mr-8 xl:w-16">
                          {branch.ignoredIssueCount >= 0
                            ? branch.ignoredIssueCount
                            : "暂无信息"}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="text-right xl:mr-8 xl:w-16">
                          {branch.unsolvedIssueCount >= 0
                            ? branch.unsolvedIssueCount
                            : "暂无信息"}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="text-right xl:mr-8 xl:w-16">
                          {branch.linesOfCode >= 0
                            ? branch.linesOfCode
                            : "暂无信息"}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="xl:w-30 xl:mr-8">
                          {branch.submittedAt ? branch.submittedAt : "暂无信息"}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="text-right xl:mr-8 xl:w-16">
                          {branch.totalIssueCount >= 0
                            ? branch.totalIssueCount
                            : "暂无信息"}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="text-right xl:mr-8 xl:w-16">
                          {branch.IssueDensity
                            ? branch.IssueDensity
                            : "暂无信息"}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="text-right xl:mr-8 xl:w-16">
                          {branch.recentlyDetectedIssueCount >= 0
                            ? branch.recentlyDetectedIssueCount
                            : "暂无信息"}
                        </div>
                      </TableCell>
                      <TableCell last>
                        <div className="text-right xl:mr-8 xl:w-16">
                          {branch.newIssueCount >= 0
                            ? branch.newIssueCount
                            : "暂无信息"}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </>
        ) : (
          <div className="my-4 text-gray-500">
            当前使用许可不包括查看分支功能
          </div>
        )}
      </MainContentWithTitle>
    </ProjectLayout>
  );
}

export default ProjectBranches;
