/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

declare global {
  interface Window {
    developmentMode: string;
  }
}

namespace api {
  interface StatusReply {
    status: "ok" | "error";
    reason?: string;
    repo_id?: string;
    refID?: string;
    /*
    // operationStatus: 0: clonePending, 1: cloneFailed, 2: cloneSuccess,
    // 3: zipPending, 4: zipFailed, 5: zipSuccess
    */
    operationStatus?: number;
    name?: string;
    tags?: TagRef[];
    hasCheckRules?: boolean;
    taskID?: string;
  }

  export interface TagRef {
    ref: string;
    revision: string;
  }

  export interface GerritSettings {
    username: string;
    host: string;
    port: number;
    project: string;
    branch: string;
  }

  export interface GitlabSettings {
    gitCloneURL: string;
    projectAccessToken: string;
    branches: string;
    port: number;
  }

  export interface GithubSettings {
    gitCloneURL: string;
    personalAccessToken: string;
    branches: string;
  }

  interface AddRepositoryOptions {
    runnerKey: string;
    uri: string;
    kind: string;
  }

  interface GitCloneOptions {
    projectID: string;
    uri: string;
    repoKind: string;
  }

  interface CheckStatusOptions {
    runnerKey: string;
    refID: string;
  }

  interface CreateGitUploadOptions {
    projectID: string;
    refID: string;
    ref: string;
  }

  interface StartBuildOptions {
    projectID: string;
    taskID: string;
    repoKind: string;
    refID?: string;
    ref?: string;
    repoID?: string;
  }

  interface CreateZipUploadOptions {
    projectID: string;
    repoID: string;
    filename: string;
  }

  interface CreateScanTaskOptions {
    runnerKey: string;
    repoID: string;
    revision: string;
    refName: string | undefined;
    description: string | undefined;
  }

  interface CreateProjectOptions {
    projectName: string;
    projectType: string;
    qtProPath: string;
    buildScript: string;
    repoKind: string;
    analyzeSrcDir: string;
    initSubmodule: boolean;
    gerritSettings: GerritSettings;
    gitlabSettings: GitlabSettings;
    githubSettings: GithubSettings;
  }

  interface DeleteProjectOptions {
    projectID: string;
  }

  interface UpdateProjectOptions {
    projectID: string;
    projectType: string;
    analyzeSrcDir: string;
    qtProPath: string;
    buildScript: string;
    initSubmodule: boolean;
    repoKind: string;
    gerritSettings: GerritSettings;
    gitlabSettings: GitlabSettings;
    githubSettings: GithubSettings;
  }

  interface UpdateCheckRulesOptions {
    projectID: string;
    checkRules: string;
  }

  export interface ReportOptions {
    severity: Map<string, Boolean>;
    formatType: string;
    charset: string;
  }

  interface GenerateReportOptions {
    projectID: string;
    scanTaskIDs: string[];
    reportOptions: ReportOptions;
  }

  interface StopOrRebuildOptions {
    projectID: string;
    taskID: string;
  }

  interface IService {
    CreateProject(_: CreateProjectOptions): Promise<StatusReply>;
    UpdateProject(_: UpdateProjectOptions): Promise<StatusReply>;
    DeleteProject(_: DeleteProjectOptions): Promise<StatusReply>;
    UpdateCheckRules(_: UpdateCheckRulesOptions): Promise<StatusReply>;
    GenerateReport(_: GenerateReportOptions): Promise<StatusReply>;
    AddRepository(_: AddRepositoryOptions): Promise<StatusReply>;
    CreateScanTask(_: CreateScanTaskOptions): Promise<StatusReply>;
    GitClone(_: GitCloneOptions): Promise<StatusReply>;
    CheckStatus(_: CheckStatusOptions): Promise<StatusReply>;
    CreateGitUpload(_: CreateGitUploadOptions): Promise<StatusReply>;
    CreateZipUpload(_: CreateZipUploadOptions): Promise<StatusReply>;
    StartBuild(_: StartBuildOptions): Promise<StatusReply>;
    StopBuild(_: StopOrRebuildOptions): Promise<StatusReply>;
    Rebuild(_: StopOrRebuildOptions): Promise<StatusReply>;
    DeleteScanTask(_: StopOrRebuildOptions): Promise<StatusReply>;
  }

  class Service implements IService {
    async CreateProject({
      projectName,
      projectType,
      qtProPath,
      buildScript,
      repoKind,
      analyzeSrcDir,
      initSubmodule,
      gerritSettings,
      gitlabSettings,
      githubSettings,
    }: CreateProjectOptions): Promise<StatusReply> {
      const response = await fetch("/api/create_project", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          project_name: projectName,
          project_type: projectType,
          qt_pro_path: qtProPath,
          build_script: buildScript,
          repo_kind: repoKind,
          analyze_src_dir: analyzeSrcDir,
          init_submodule: initSubmodule,
          gerrit_settings: {
            username: gerritSettings.username,
            host: gerritSettings.host,
            port: gerritSettings.port,
            project: gerritSettings.project,
            branch: gerritSettings.branch,
          },
          gitlab_settings: {
            git_clone_url: gitlabSettings.gitCloneURL,
            project_access_token: gitlabSettings.projectAccessToken,
            branches: gitlabSettings.branches,
            port: gitlabSettings.port,
          },
          github_settings: {
            git_clone_url: githubSettings.gitCloneURL,
            personal_access_token: githubSettings.personalAccessToken,
            branches: gitlabSettings.branches,
          },
        }),
      });
      return await response.json();
    }

    async UpdateProject({
      projectID,
      projectType,
      analyzeSrcDir,
      qtProPath,
      buildScript,
      initSubmodule,
      repoKind,
      gerritSettings,
      gitlabSettings,
      githubSettings,
    }: UpdateProjectOptions): Promise<StatusReply> {
      const response = await fetch(
        `/api/update_project?project_id=${projectID}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            project_type: projectType,
            qt_pro_path: qtProPath,
            build_script: buildScript,
            analyze_src_dir: analyzeSrcDir,
            init_submodule: initSubmodule,
            repo_kind: repoKind,
            gerrit_settings: {
              username: gerritSettings.username,
              host: gerritSettings.host,
              port: gerritSettings.port,
              project: gerritSettings.project,
              branch: gerritSettings.branch,
            },
            gitlab_settings: {
              git_clone_url: gitlabSettings.gitCloneURL,
              project_access_token: gitlabSettings.projectAccessToken,
              branches: gitlabSettings.branches,
              port: gitlabSettings.port,
            },
            github_settings: {
              git_clone_url: githubSettings.gitCloneURL,
              personal_access_token: githubSettings.personalAccessToken,
              branches: gitlabSettings.branches,
            },
          }),
        }
      );
      return await response.json();
    }

    async DeleteProject({
      projectID,
    }: DeleteProjectOptions): Promise<StatusReply> {
      const response = await fetch(
        `/api/delete_project?project_id=${projectID}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        }
      );
      return await response.json();
    }

    async UpdateCheckRules({
      projectID,
      checkRules,
    }: UpdateCheckRulesOptions): Promise<StatusReply> {
      const response = await fetch(
        `/api/update_check_rules?project_id=${projectID}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            check_rules: checkRules,
          }),
        }
      );
      return await response.json();
    }

    async GenerateReport({
      projectID,
      scanTaskIDs,
      reportOptions,
    }: GenerateReportOptions): Promise<StatusReply> {
      // Map cannot be directly stringified, so it should be converted to object
      let severityObj = Object.create(null);
      reportOptions.severity.forEach((value, key) => {
        severityObj[key] = value;
      });
      const response = await fetch(
        `/api/generate_report?project_id=${projectID}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            scan_tasks: scanTaskIDs,
            report_options: {
              severity: severityObj,
              format_type: reportOptions.formatType,
              charset: reportOptions.charset,
            },
          }),
        }
      );
      return await response.json();
    }

    async AddRepository({
      runnerKey,
      uri,
      kind,
    }: AddRepositoryOptions): Promise<StatusReply> {
      const response = await fetch(`/api/add_repository`, {
        method: "POST",
        headers: {
          "X-NaiveSystems-API-Key": runnerKey,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          kind: kind,
          uri: uri,
        }),
      });
      return await response.json();
    }

    async CreateScanTask({
      runnerKey,
      repoID,
      revision,
      refName,
      description,
    }: CreateScanTaskOptions): Promise<StatusReply> {
      let url = `/api/create_scan_task?repo_id=${repoID}&revision=${revision}`;
      if (refName !== undefined) {
        url = `${url}&ref_name=${refName}`;
      }
      if (description !== undefined) {
        url = `${url}&description=${description}`;
      }
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "X-NaiveSystems-API-Key": runnerKey,
        },
      });
      return await response.json();
    }

    async GitClone({
      projectID,
      uri,
      repoKind,
    }: GitCloneOptions): Promise<StatusReply> {
      const response = await fetch(
        `/api/portal_git_clone?project_id=${projectID}&uri=${uri}&repo_kind=${repoKind}`,
        {
          method: "POST",
        }
      );
      return await response.json();
    }

    async CheckStatus({
      runnerKey,
      refID,
    }: CheckStatusOptions): Promise<StatusReply> {
      const response = await fetch(
        `/api/get_operation_status?ref_id=${refID}`,
        {
          method: "GET",
          headers: {
            "X-NaiveSystems-API-Key": runnerKey,
            "Content-Type": "application/json",
          },
        }
      );
      return await response.json();
    }

    async CreateZipUpload({
      projectID,
      repoID,
      filename,
    }: CreateZipUploadOptions): Promise<StatusReply> {
      const response = await fetch(
        `/api/create_zip_upload?project_id=${projectID}&repo_id=${repoID}&filename=${filename}`,
        {
          method: "POST",
        }
      );
      return await response.json();
    }

    async CreateGitUpload({
      projectID,
      refID,
      ref,
    }: CreateGitUploadOptions): Promise<StatusReply> {
      const response = await fetch(
        `/api/create_git_upload?project_id=${projectID}&ref_id=${refID}&ref=${ref}`,
        {
          method: "POST",
        }
      );
      return await response.json();
    }

    async StartBuild({
      projectID,
      taskID,
      repoKind,
      refID,
      ref,
      repoID,
    }: StartBuildOptions): Promise<StatusReply> {
      let url: string;
      switch (repoKind) {
        case "zip": {
          url = `/api/force_build?project_id=${projectID}&task_id=${taskID}&repo_kind=${repoKind}&repo_id=${repoID}`;
          break;
        }
        case "git":
        default: {
          url = `/api/force_build?project_id=${projectID}&task_id=${taskID}&repo_kind=${repoKind}&ref_id=${refID}&ref=${ref}`;
        }
      }
      const response = await fetch(url, {
        method: "POST",
      });
      return await response.json();
    }

    async StopBuild({
      projectID,
      taskID,
    }: StopOrRebuildOptions): Promise<StatusReply> {
      const response = await fetch(
        `/api/stop_build?project_id=${projectID}&task_id=${taskID}`,
        {
          method: "POST",
        }
      );
      return await response.json();
    }

    async Rebuild({
      projectID,
      taskID,
    }: StopOrRebuildOptions): Promise<StatusReply> {
      const response = await fetch(
        `/api/rebuild?project_id=${projectID}&task_id=${taskID}`,
        {
          method: "POST",
        }
      );
      return await response.json();
    }

    async DeleteScanTask({
      projectID,
      taskID,
    }: StopOrRebuildOptions): Promise<StatusReply> {
      const response = await fetch(
        `/api/delete_scan_task?project_id=${projectID}&task_id=${taskID}`,
        {
          method: "POST",
        }
      );
      return await response.json();
    }
  }

  class FakeService implements IService {
    async CreateProject(_: CreateProjectOptions): Promise<StatusReply> {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (Math.random() < 0.1) {
            return reject(`random ${Math.random()} error`);
          }
          if (Math.random() < 0.6) {
            return resolve({ status: "ok" });
          }
          resolve({
            status: "error",
            reason: `random ${Math.random()} reason`,
          });
        }, 250 * Math.ceil(Math.random() * 10));
      });
    }

    async UpdateProject(_: UpdateProjectOptions): Promise<StatusReply> {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (Math.random() < 0.1) {
            return reject(`random ${Math.random()} error`);
          }
          if (Math.random() < 0.6) {
            return resolve({ status: "ok" });
          }
          resolve({
            status: "error",
            reason: `random ${Math.random()} reason`,
          });
        }, 250 * Math.ceil(Math.random() * 10));
      });
    }

    async DeleteProject(): Promise<StatusReply> {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (Math.random() < 0.1) {
            return reject(`random ${Math.random()} error`);
          }
          if (Math.random() < 0.6) {
            return resolve({ status: "ok" });
          }
          resolve({
            status: "error",
            reason: `random ${Math.random()} reason`,
          });
        }, 250 * Math.ceil(Math.random() * 10));
      });
    }

    async UpdateCheckRules(_: UpdateCheckRulesOptions): Promise<StatusReply> {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (Math.random() < 0.1) {
            return reject(`random ${Math.random()} error`);
          }
          if (Math.random() < 0.6) {
            return resolve({ status: "ok" });
          }
          resolve({
            status: "error",
            reason: `random ${Math.random()} reason`,
          });
        }, 250 * Math.ceil(Math.random() * 10));
      });
    }

    async GenerateReport(_: GenerateReportOptions): Promise<StatusReply> {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (Math.random() < 0.1) {
            return reject(`random ${Math.random()} error`);
          }
          if (Math.random() < 0.6) {
            return resolve({ status: "ok" });
          }
          resolve({
            status: "error",
            reason: `random ${Math.random()} reason`,
          });
        }, 250 * Math.ceil(Math.random() * 10));
      });
    }

    async AddRepository(_: AddRepositoryOptions): Promise<StatusReply> {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (Math.random() < 0.1) {
            return reject(`random ${Math.random()} error`);
          }
          if (Math.random() < 0.6) {
            return resolve({ status: "ok" });
          }
          resolve({
            status: "error",
            reason: `random ${Math.random()} reason`,
          });
        }, 250 * Math.ceil(Math.random() * 10));
      });
    }

    async CreateScanTask(_: CreateScanTaskOptions): Promise<StatusReply> {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (Math.random() < 0.1) {
            return reject(`random ${Math.random()} error`);
          }
          if (Math.random() < 0.6) {
            return resolve({ status: "ok" });
          }
          resolve({
            status: "error",
            reason: `random ${Math.random()} reason`,
          });
        }, 250 * Math.ceil(Math.random() * 10));
      });
    }

    async GitClone(_: GitCloneOptions): Promise<StatusReply> {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (Math.random() < 0.1) {
            return reject(`random ${Math.random()} error`);
          }
          if (Math.random() < 0.6) {
            return resolve({ status: "ok" });
          }
          resolve({
            status: "error",
            reason: `random ${Math.random()} reason`,
          });
        }, 250 * Math.ceil(Math.random() * 10));
      });
    }

    async CheckStatus(_: CheckStatusOptions): Promise<StatusReply> {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (Math.random() < 0.1) {
            return reject(`random ${Math.random()} error`);
          }
          if (Math.random() < 0.6) {
            return resolve({ status: "ok" });
          }
          resolve({
            status: "error",
            reason: `random ${Math.random()} reason`,
          });
        }, 250 * Math.ceil(Math.random() * 10));
      });
    }

    async CreateZipUpload(_: CreateZipUploadOptions): Promise<StatusReply> {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (Math.random() < 0.1) {
            return reject(`random ${Math.random()} error`);
          }
          if (Math.random() < 0.6) {
            return resolve({ status: "ok" });
          }
          resolve({
            status: "error",
            reason: `random ${Math.random()} reason`,
          });
        }, 250 * Math.ceil(Math.random() * 10));
      });
    }

    async CreateGitUpload(_: CreateGitUploadOptions): Promise<StatusReply> {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (Math.random() < 0.1) {
            return reject(`random ${Math.random()} error`);
          }
          if (Math.random() < 0.6) {
            return resolve({ status: "ok" });
          }
          resolve({
            status: "error",
            reason: `random ${Math.random()} reason`,
          });
        }, 250 * Math.ceil(Math.random() * 10));
      });
    }

    async StartBuild(_: StartBuildOptions): Promise<StatusReply> {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (Math.random() < 0.1) {
            return reject(`random ${Math.random()} error`);
          }
          if (Math.random() < 0.6) {
            return resolve({ status: "ok" });
          }
          resolve({
            status: "error",
            reason: `random ${Math.random()} reason`,
          });
        }, 250 * Math.ceil(Math.random() * 10));
      });
    }

    async StopBuild(_: StopOrRebuildOptions): Promise<StatusReply> {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (Math.random() < 0.1) {
            return reject(`random ${Math.random()} error`);
          }
          if (Math.random() < 0.6) {
            return resolve({ status: "ok" });
          }
          resolve({
            status: "error",
            reason: `random ${Math.random()} reason`,
          });
        }, 250 * Math.ceil(Math.random() * 10));
      });
    }

    async Rebuild(_: StopOrRebuildOptions): Promise<StatusReply> {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (Math.random() < 0.1) {
            return reject(`random ${Math.random()} error`);
          }
          if (Math.random() < 0.6) {
            return resolve({ status: "ok" });
          }
          resolve({
            status: "error",
            reason: `random ${Math.random()} reason`,
          });
        }, 250 * Math.ceil(Math.random() * 10));
      });
    }

    async DeleteScanTask(_: StopOrRebuildOptions): Promise<StatusReply> {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (Math.random() < 0.1) {
            return reject(`random ${Math.random()} error`);
          }
          if (Math.random() < 0.6) {
            return resolve({ status: "ok" });
          }
          resolve({
            status: "error",
            reason: `random ${Math.random()} reason`,
          });
        }, 250 * Math.ceil(Math.random() * 10));
      });
    }
  }

  export function NewService() {
    if (window.developmentMode === "yes") {
      return new FakeService();
    }
    return new Service();
  }
}

export { api };
