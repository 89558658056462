import { ChangeEventHandler } from "react";
import RSCheckboxIcon from "./RSCheckboxIcon";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface RuleItemProps {
  ruleName: string;
  selected: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

const RuleItem: React.FC<RuleItemProps> = (props) => {
  // TODO: use theme.colors instead of hardcoded values
  return (
    <td
      className={classNames(
        /^(G|CWE-|A|M)/.test(props.ruleName) || props.ruleName.match("-C|-CPP")
          ? "py-3"
          : "",
        "h-12 justify-between text-[#102A43] hover:text-sky-600 dark:text-slate-200 dark:hover:bg-[#2A5F90] dark:hover:text-slate-200"
      )}
    >
      <label className="inline-block flex h-full w-full cursor-pointer items-center">
        <input
          className="hidden"
          type="checkbox"
          checked={props.selected}
          onChange={props.onChange}
        />
        <span className="m-4">
          <RSCheckboxIcon max={1} cur={props.selected ? 1 : 0} />
        </span>
        {props.ruleName}
      </label>
    </td>
  );
};

export default RuleItem;
