/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import { XCircleIcon } from "@heroicons/react/20/solid";
import { FormEvent, useState } from "react";
import { api } from "../api";
import { PrimaryButton, WhiteButton } from "../uilib/buttons";
import {
  HelpText,
  SelectField,
  TextAreaField,
  TextField,
  CheckboxField,
} from "../uilib/forms";
import { MainContentWithTitle } from "../uilib/layouts";
import {
  GerritHelp,
  GerritSettings,
  GerritSettingsForm,
} from "../components/GerritSettings";
import {
  GitlabHelp,
  GitlabSettings,
  GitlabSettingsForm,
} from "../components/GitlabSettings";
import {
  GithubHelp,
  GithubSettings,
  GithubSettingsForm,
} from "../components/GithubSettings";
import { HomeLayout } from "../components/ProjectLayout";
import { RepoHelpText } from "../components/SettingField";

interface APIPortalCreateProject {
  buildbotPublicKey: string;
  webhookProxy: string;
  gerritSupportEnabled: boolean;
  gitlabSupportEnabled: boolean;
  githubSupportEnabled: boolean;
  otherLanguagesEnabled: boolean;
}

declare global {
  interface Window {
    apiPortalCreateProject: APIPortalCreateProject;
  }
}

function CreateProject() {
  const [projectName, setProjectName] = useState("");
  const [projectNameError, setProjectNameError] = useState("");
  const [projectType, setProjectType] = useState("none");
  const [analyzeSrcDir, setAnalyzeSrcDir] = useState("");
  const [analyzeSrcDirError, setAnalyzeSrcDirError] = useState("");
  const [qtProPath, setQtProPath] = useState("");
  const [qtProPathError, setQtProPathError] = useState("");
  const [buildScript, setBuildScript] = useState("");
  const [buildScriptError, setBuildScriptError] = useState("");
  const [repoKind, setRepoKind] = useState("none");
  const [initSubmodule, setInitSubmodule] = useState(true);
  const [gerritSettings, setGerritSettings] = useState<GerritSettings>({
    sshPublicKey: window.apiPortalCreateProject.buildbotPublicKey,
    username: "naivesystems",
    host: "",
    port: 29418,
    project: "",
    branch: "master",
    eventType: "patchset-created",
  });
  const [gerritUsernameError, setGerritUsernameError] = useState("");
  const [gerritHostError, setGerritHostError] = useState("");
  const [gerritPortError, setGerritPortError] = useState("");
  const [gerritProjectError, setGerritProjectError] = useState("");
  const [gerritBranchError, setGerritBranchError] = useState("");
  const [gitlabSettings, setGitlabSettings] = useState<GitlabSettings>({
    gitCloneURL: "",
    projectAccessToken: "",
    branches: "master",
    port: 22,
  });
  const [gitlabPortError, setGitlabPortError] = useState("");
  const [githubSettings, setGithubSettings] = useState<GithubSettings>({
    gitCloneURL: "",
    personalAccessToken: "",
    branches: "master",
  });
  const [submitPending, setSubmitPending] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [showGerritCreateAccountHelp, setShowGerritCreateAccountHelp] =
    useState(false);
  const [showGitlabCreateWebhookHelp, setShowGitlabCreateWebhookHelp] =
    useState(false);
  const [showGithubCreateWebhookHelp, setShowGithubCreateWebhookHelp] =
    useState(false);
  const webhookProxy = window.apiPortalCreateProject.webhookProxy.endsWith("/")
    ? window.apiPortalCreateProject.webhookProxy
    : window.apiPortalCreateProject.webhookProxy + "/";

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let hasErrors = false;
    if (projectName === "") {
      setProjectNameError("请填写项目名称");
      hasErrors = true;
    } else if (projectName.length > 255) {
      setProjectNameError("项目名称的长度不能超过255");
      hasErrors = true;
    }
    if (analyzeSrcDir.length > 255) {
      setAnalyzeSrcDirError("文件路径的长度不能超过255");
      hasErrors = true;
    } else if (
      analyzeSrcDir.startsWith("/") ||
      analyzeSrcDir.startsWith("\\")
    ) {
      setAnalyzeSrcDirError("请填写相对路径");
      hasErrors = true;
    }
    if (projectType === "qmake") {
      if (qtProPath.length > 255) {
        setQtProPathError("文件路径的长度不能超过255");
        hasErrors = true;
      }
    } else if (projectType === "script") {
      if (buildScript.length > 1000) {
        setBuildScriptError("自定义构建脚本的长度不能超过1000");
        hasErrors = true;
      }
    }
    if (repoKind === "gerrit") {
      if (!window.apiPortalCreateProject.gerritSupportEnabled) {
        setSubmitError("当前使用许可不包括 Gerrit 相关功能");
        hasErrors = true;
      }
      if (gerritSettings.username === "") {
        setGerritUsernameError("请填写 Gerrit 账户名");
        hasErrors = true;
      }
      if (gerritSettings.username.length > 255) {
        setGerritUsernameError("账户名的长度不能超过255");
        hasErrors = true;
      }
      if (gerritSettings.host === "") {
        setGerritHostError("请填写服务器地址");
        hasErrors = true;
      }
      if (gerritSettings.host.length > 253) {
        setGerritHostError("服务器地址的长度不能超过253");
        hasErrors = true;
      }
      if (gerritSettings.port <= 0) {
        setGerritPortError("端口号必须大于0");
        hasErrors = true;
      }
      if (gerritSettings.port > 65535) {
        setGerritPortError("端口号不能超过65535");
        hasErrors = true;
      }
      if (gerritSettings.project === "") {
        setGerritProjectError("请填写 Gerrit 项目 ID");
        hasErrors = true;
      }
      if (gerritSettings.project.length > 255) {
        setGerritProjectError("项目 ID 的长度不能超过255");
        hasErrors = true;
      }
      if (gerritSettings.branch === "") {
        setGerritBranchError("请填写分支");
        hasErrors = true;
      }
      if (gerritSettings.branch.length > 255) {
        setGerritBranchError("分支的长度不能超过255");
        hasErrors = true;
      }
    }
    if (repoKind === "gitlab") {
      if (!window.apiPortalCreateProject.gitlabSupportEnabled) {
        setSubmitError("当前使用许可不包括 GitLab 相关功能");
        hasErrors = true;
      }
      if (gitlabSettings.port <= 0) {
        setGitlabPortError("端口号必须大于0");
        hasErrors = true;
      }
      if (gitlabSettings.port > 65535) {
        setGitlabPortError("端口号不能超过65535");
        hasErrors = true;
      }
    }
    if (repoKind === "github") {
      if (!window.apiPortalCreateProject.githubSupportEnabled) {
        setSubmitError("当前使用许可不包括 GitHub 相关功能");
        hasErrors = true;
      }
    }
    let initSub = initSubmodule;
    if (repoKind === "none") {
      initSub = false;
    }
    if (hasErrors) {
      return;
    }
    setSubmitPending(true);
    api
      .NewService()
      .CreateProject({
        projectName,
        projectType,
        qtProPath,
        buildScript,
        repoKind,
        analyzeSrcDir,
        initSubmodule: initSub,
        gerritSettings,
        gitlabSettings,
        githubSettings,
      })
      .then((reply) => {
        if (reply.status === "ok") {
          window.location.assign("/projects");
        } else if (reply.status === "error") {
          setSubmitError("保存失败：" + reply.reason);
        }
        setSubmitPending(false);
      })
      .catch((err) => {
        console.error(err);
        setSubmitError("网络错误");
        setSubmitPending(false);
      });
  };

  return (
    <HomeLayout>
      <MainContentWithTitle title="新建项目">
        <form className="space-y-8 divide-y divide-gray-200" onSubmit={submit}>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
            <div className="sm:col-span-4">
              <TextField
                id="project-name"
                value={projectName}
                onChange={setProjectName}
                error={projectNameError}
                setError={setProjectNameError}
              >
                项目名称
              </TextField>
            </div>
            <div className="sm:col-span-4">
              <SelectField
                id="project-type"
                label="项目类型"
                value={projectType}
                onChange={setProjectType}
              >
                <option value="none">暂不设置</option>
                <option value="makefile">Makefile</option>
                <option value="cmake">CMake</option>
                <option value="qmake">Qt</option>
                <option value="keil">Keil MDK</option>
                <option value="script">自定义构建脚本</option>
                {window.apiPortalCreateProject.otherLanguagesEnabled && (
                  <option value="maven">Java (Maven)</option>
                )}
                {window.apiPortalCreateProject.otherLanguagesEnabled && (
                  <option value="other">其他</option>
                )}
              </SelectField>
            </div>
            <div className="hidden sm:col-span-4 sm:block"></div>
            <div className={"sm:col-span-8"}>
              <TextField
                id="analyze-src-dir"
                value={analyzeSrcDir}
                onChange={setAnalyzeSrcDir}
                error={analyzeSrcDirError}
                setError={setAnalyzeSrcDirError}
              >
                项目构建脚本所在目录
              </TextField>
              <HelpText>
                <div>
                  选填，从项目根目录到构建脚本所在目录的相对路径，留空则使用项目根目录
                </div>
                <div>如构建脚本为 subproject1/Makefile，则填 subproject1/</div>
              </HelpText>
            </div>
            <div className="hidden sm:col-span-4 sm:block"></div>
            <div
              className={projectType === "qmake" ? "sm:col-span-12" : "hidden"}
            >
              <TextField
                id="qt-pro-path"
                value={qtProPath}
                onChange={setQtProPath}
                error={qtProPathError}
                setError={setQtProPathError}
              >
                Qt 项目文件 (*.pro) 路径
              </TextField>
              <HelpText>选填，留空则使用默认值</HelpText>
            </div>

            <div
              className={projectType === "script" ? "sm:col-span-12" : "hidden"}
            >
              <TextAreaField
                rows={5}
                id="build-script"
                value={buildScript}
                onChange={setBuildScript}
                error={buildScriptError}
                setError={setBuildScriptError}
              >
                自定义构建脚本内容
              </TextAreaField>
            </div>

            <div className="sm:col-span-4">
              <SelectField
                id="repo-kind"
                label="代码仓库类型"
                value={repoKind}
                onChange={setRepoKind}
              >
                <option value="none">暂不设置</option>
                <option value="gerrit">Gerrit</option>
                <option value="gitlab">GitLab</option>
                <option value="github">GitHub</option>
              </SelectField>
            </div>

            <RepoHelpText
              repoKind={repoKind}
              gerritSupportEnabled={
                window.apiPortalCreateProject.gerritSupportEnabled
              }
              gitlabSupportEnabled={
                window.apiPortalCreateProject.gitlabSupportEnabled
              }
              githubSupportEnabled={
                window.apiPortalCreateProject.githubSupportEnabled
              }
              setShowGerritCreateAccountHelp={setShowGerritCreateAccountHelp}
              setShowGitlabCreateWebhookHelp={setShowGitlabCreateWebhookHelp}
              setShowGithubCreateWebhookHelp={setShowGithubCreateWebhookHelp}
            ></RepoHelpText>

            {repoKind !== "none" && (
              <>
                <div className="sm:col-span-4">
                  <CheckboxField
                    id="init-submodule"
                    label="是否克隆子模块"
                    defaultChecked={true}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setInitSubmodule(event.target.checked);
                    }}
                  />
                </div>
                <div className="hidden sm:col-span-8 sm:block"></div>
              </>
            )}

            <div
              className={
                repoKind === "gerrit" &&
                window.apiPortalCreateProject.gerritSupportEnabled
                  ? "contents"
                  : "hidden"
              }
            >
              <GerritSettingsForm
                settings={gerritSettings}
                updateSettings={setGerritSettings}
                usernameError={gerritUsernameError}
                setUsernameError={setGerritUsernameError}
                hostError={gerritHostError}
                setHostError={setGerritHostError}
                portError={gerritPortError}
                setPortError={setGerritPortError}
                projectError={gerritProjectError}
                setProjectError={setGerritProjectError}
                branchError={gerritBranchError}
                setBranchError={setGerritBranchError}
              />
            </div>

            <div
              className={
                repoKind === "gitlab" &&
                window.apiPortalCreateProject.gitlabSupportEnabled
                  ? "contents"
                  : "hidden"
              }
            >
              <GitlabSettingsForm
                settings={gitlabSettings}
                updateSettings={setGitlabSettings}
                portError={gitlabPortError}
                setPortError={setGitlabPortError}
              />
            </div>

            <div
              className={
                repoKind === "github" &&
                window.apiPortalCreateProject.githubSupportEnabled
                  ? "contents"
                  : "hidden"
              }
            >
              <GithubSettingsForm
                settings={githubSettings}
                updateSettings={setGithubSettings}
              />
            </div>

            {/* TODO(b/10598): "init submodule" */}
            {/* TODO(b/10599): "analyze src dir" */}
          </div>

          <div className="pt-5">
            <div
              className={
                submitError === "" ? "hidden" : "mb-4 rounded-md bg-red-50 p-4"
              }
            >
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircleIcon
                    className="h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-red-800">{submitError}</p>
                </div>
              </div>
            </div>
            <div className="flex">
              <PrimaryButton type="submit" pending={submitPending}>
                保存
              </PrimaryButton>
              <WhiteButton
                type="button"
                className="ml-3"
                onClick={() => {
                  window.location.pathname = "/projects";
                }}
              >
                取消
              </WhiteButton>
            </div>
          </div>
        </form>
      </MainContentWithTitle>
      <GerritHelp
        show={showGerritCreateAccountHelp}
        set={setShowGerritCreateAccountHelp}
        settings={gerritSettings}
      />
      <GitlabHelp
        show={showGitlabCreateWebhookHelp}
        set={setShowGitlabCreateWebhookHelp}
        webhookProxy={webhookProxy}
      />
      <GithubHelp
        show={showGithubCreateWebhookHelp}
        set={setShowGithubCreateWebhookHelp}
        webhookProxy={webhookProxy}
      />
    </HomeLayout>
  );
}

export default CreateProject;
