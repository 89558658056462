import { createRef, RefObject, useEffect, useState } from "react";

import { CodingStandardChapter } from "../standards/Common";
import { MisraRule, getRulesFromChapter } from "../standards/Misra";
import RuleItem from "../components/RuleItem";
import RSArrorIcon from "../components/RSArrorIcon";
import RSCheckboxIcon from "../components/RSCheckboxIcon";

interface ChapterMenuProps {
  chapter: CodingStandardChapter;
  onSelectRule: (rule: MisraRule, selected: boolean) => void;
  onSelectChapter: (chapter: CodingStandardChapter, selected: boolean) => void;
  collapsed: Map<string, boolean>;
  setCollapsed: React.Dispatch<React.SetStateAction<Map<string, boolean>>>;
  focusedRuleId: string | null;
  setFocusedRuleId: React.Dispatch<React.SetStateAction<string | null>>;
}

const ChapterMenu: React.FC<ChapterMenuProps> = ({
  chapter,
  onSelectRule,
  onSelectChapter,
  collapsed,
  setCollapsed,
  focusedRuleId,
  setFocusedRuleId,
}) => {
  const [ruleRefs, setRuleRefs] = useState<{
    [key: string]: RefObject<HTMLTableRowElement>;
  }>({});
  const selectedRules = getRulesFromChapter(chapter).filter(
    (rule) => rule.selected
  );
  const supoortedRules = getRulesFromChapter(chapter).filter(
    (rule) => rule.support
  );
  const currentCollapsed = collapsed.get(chapter.name) ?? false;
  const generateChapter = (chapter: CodingStandardChapter) => {
    if (chapter.rules !== undefined) {
      // MISRA C:2012
      return chapter.rules.map((rule) => (
        <tr key={rule.id} ref={ruleRefs[rule.id]}>
          <RuleItem
            key={rule.id}
            ruleName={`${rule.ident} ${rule.subject}`}
            selected={rule.selected}
            onChange={(e) => onSelectRule(rule, e.target.checked)}
          />
        </tr>
      ));
    } else {
      // nested rules like MISRA C++:2008
      if (chapter.sections === undefined) return [];
      return chapter.sections.map((section) => (
        <tr key={section.name}>
          <td>
            <ChapterMenu
              key={section.name}
              chapter={section}
              onSelectChapter={onSelectChapter}
              onSelectRule={onSelectRule}
              collapsed={collapsed}
              setCollapsed={setCollapsed}
              focusedRuleId={focusedRuleId}
              setFocusedRuleId={setFocusedRuleId}
            />
          </td>
        </tr>
      ));
    }
  };

  useEffect(() => {
    // set up DOM refs for each rule
    setRuleRefs((refs) => {
      selectedRules.forEach((rule) => {
        refs[rule.id] = createRef<HTMLTableRowElement>();
      });
      return refs;
    });
  }, [selectedRules]);

  useEffect(() => {
    // scroll to focused rule when it changes
    if (!focusedRuleId || !setFocusedRuleId) return;
    ruleRefs[focusedRuleId]?.current?.scrollIntoView();
    setFocusedRuleId(null);
  }, [focusedRuleId, ruleRefs, setFocusedRuleId]);

  return (
    <div>
      {supoortedRules.length !== 0 && (
        <div>
          <div className="flex flex-row items-center">
            <label className="mx-4">
              <input
                className="hidden "
                type="checkbox"
                checked={
                  getRulesFromChapter(chapter).filter((rule) => rule.selected)
                    .length > 0
                }
                onChange={(e) => onSelectChapter(chapter, e.target.checked)}
              />
              <RSCheckboxIcon
                max={getRulesFromChapter(chapter).length}
                cur={
                  getRulesFromChapter(chapter).filter((rule) => rule.selected)
                    .length
                }
              />
            </label>
            <div
              className={"flex h-12 w-full items-center justify-between"}
              onClick={() => {
                setCollapsed(
                  (prev) => new Map(prev.set(chapter.name, !currentCollapsed))
                );
              }}
            >
              <label className="inline-block w-full cursor-pointer font-bold text-sky-600">
                <RSArrorIcon
                  className="ml-4 mr-4 inline-block w-4"
                  direction={collapsed.get(chapter.name) ? "right" : "down"}
                  color="blue"
                />
                {chapter.name}
              </label>
            </div>
          </div>
          {!currentCollapsed && (
            <div className="flex flex-row">
              <div className="ml-1 w-12"></div>
              <table className="w-full border-collapse">
                <tbody>{generateChapter(chapter)}</tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ChapterMenu;
