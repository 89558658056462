/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";

import { ScanTaskStep } from "../common/ScanTask";
import { DateTime } from "../common/DateTime";
import { ClassNames } from "../common/classnames";

function Line(props: { status: string; stepIdx: number }) {
  return (
    <div
      className="absolute inset-0 flex w-8 flex-col items-center"
      aria-hidden="true"
    >
      <div
        className={ClassNames(
          props.status === "completed" || props.status === "running"
            ? "bg-green-500"
            : props.status === "error"
            ? "bg-red-500"
            : "bg-gray-200",
          "h-full w-0.5"
        )}
      />
    </div>
  );
}

function Text(props: { status: string; time: string; name: string }) {
  return (
    <div className="ml-4 text-xs">
      {props.status === "pending" ? (
        <div>等待中</div>
      ) : props.status === "error" ? (
        <div>未完成</div>
      ) : props.status === "running" ? (
        <div>进行中</div>
      ) : Date.parse(props.time) > 0 ? (
        <DateTime time={Date.parse(props.time)}></DateTime>
      ) : (
        <div>已完成</div>
      )}
      <div className="text-gray-400">{props.name}</div>
    </div>
  );
}

function StepsDetail(props: { steps: ScanTaskStep[] }) {
  return (
    <nav aria-label="Progress">
      <ol className="flex flex-col items-start">
        {props.steps.map((step, stepIdx) => (
          <li
            key={step.name}
            className={ClassNames(
              stepIdx > 0 ? "pt-8" : "",
              "relative flex items-start"
            )}
          >
            <div>
              <Line status={step.status} stepIdx={stepIdx} />
              <div
                className={ClassNames(
                  step.status === "completed"
                    ? "bg-green-500"
                    : step.status === "error"
                    ? "bg-red-600"
                    : step.status === "running"
                    ? "border-2 border-green-500 bg-white"
                    : "group border-2 border-gray-300 bg-white",
                  "relative flex h-8 w-8 items-center justify-center rounded-full"
                )}
              >
                {step.status === "completed" ? (
                  <CheckIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                ) : step.status === "error" ? (
                  <XMarkIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                ) : step.status === "running" ? (
                  <span
                    className="h-2 w-2 animate-pulse rounded-full bg-green-500"
                    aria-hidden="true"
                  />
                ) : (
                  <span
                    className="h-2 w-2 rounded-full bg-transparent group-hover:bg-gray-300"
                    aria-hidden="true"
                  />
                )}
                <span className="sr-only">{step.name}</span>
              </div>
            </div>
            <Text
              status={step.status}
              time={step.createOn ? step.createOn : "0001-01-01"}
              name={step.name}
            />
          </li>
        ))}
      </ol>
    </nav>
  );
}

export default StepsDetail;
