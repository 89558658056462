import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { api } from "../api";
import RSArrorIcon from "../rulesettings/components/RSArrorIcon";
import { HelpText, TextField } from "../uilib/forms";
import SlideOver from "./SlideOver";

export interface GitlabSettings extends api.GitlabSettings {}

export function GitlabSettingsForm(props: {
  settings: GitlabSettings;
  updateSettings: (_: GitlabSettings) => void;
  portError: string;
  setPortError: (_: string) => void;
  updateForm?: boolean;
}) {
  const [showGitlabAdvancedSettings, setShowGitlabAdvancedSettings] =
    useState(false);

  return (
    <>
      <div className="sm:col-span-5">
        <TextField
          id="gitlab-git-clone-url"
          value={props.settings.gitCloneURL}
          onChange={(v) =>
            props.updateSettings({ ...props.settings, gitCloneURL: v })
          }
          disabled={props.updateForm}
        >
          git clone 地址
        </TextField>
      </div>
      <div className="sm:col-span-4">
        <TextField
          id="gitlab-project-access-token"
          type="password"
          value={props.settings.projectAccessToken}
          onChange={(v) =>
            props.updateSettings({ ...props.settings, projectAccessToken: v })
          }
        >
          项目访问令牌
        </TextField>
      </div>
      <div className="sm:col-span-3">
        <TextField
          id="gitlab-project-port"
          type="number"
          value={props.settings.port}
          onChange={(v) =>
            props.updateSettings({ ...props.settings, port: parseInt(v) })
          }
          error={props.portError}
          setError={props.setPortError}
        >
          SSH 端口
        </TextField>
        <HelpText>
          <div>
            如果 GitLab 实例存在另外配置的 SSH 端口，则需要填写为相应的端口号
          </div>
        </HelpText>
      </div>
      <div className="sm:col-span-8">
        <div
          className="flex items-center"
          onClick={() => {
            setShowGitlabAdvancedSettings((prev) => !prev);
          }}
        >
          <RSArrorIcon
            className="mr-2 inline-block w-4"
            direction={showGitlabAdvancedSettings ? "down" : "right"}
            color="blue"
          />
          <label className="text-sm font-black text-sky-600">高级设置</label>
        </div>
      </div>
      {showGitlabAdvancedSettings && (
        <div className="sm:col-span-8">
          <TextField
            id="gitlab-branches"
            value={props.settings.branches}
            onChange={(v) =>
              props.updateSettings({ ...props.settings, branches: v })
            }
          >
            提交后扫描触发分支
          </TextField>
          <HelpText>
            <div>
              选填，需要触发提交后扫描的分支名，多个分支则通过逗号（,）分开，留空则默认使用
              master 分支
            </div>
            <div>如 master, postsubmit</div>
          </HelpText>
        </div>
      )}
    </>
  );
}

export function GitlabHelp(props: {
  show: boolean;
  set: (_: React.SetStateAction<boolean>) => void;
  webhookProxy: string;
}) {
  return (
    <SlideOver
      open={props.show}
      setOpen={props.set}
      title="如何配置一个专用于触发提交前/提交后代码扫描的 Webhook？"
    >
      <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <p className="text-sm text-yellow-700">
              该操作必须由<span className="mx-0.5">Gitlab</span>项目
              <span className="mx-0.5">Owner</span>执行。
            </p>
          </div>
        </div>
      </div>
      <div className="text-base font-semibold leading-4 text-gray-900">
        <p className="mt-4">创建项目访问令牌</p>
      </div>
      <div className="text-sm text-gray-700">
        <p className="mt-4">
          进入项目，在左侧边栏中，选择 <b>设置 {`>`} 访问令牌</b>，点击{" "}
          <b>添加新令牌</b>。
        </p>
        <p className="mt-4">
          选择角色选项需 <b>Developer 或以上</b>
          ，否则可能无法正常添加评论；选择范围选项选择 <b>api</b>
          ；其他选项按需求填写，完成后点击 <b>创建 项目访问令牌</b>。
        </p>
      </div>
      <div className="text-base font-semibold leading-4 text-gray-900">
        <p className="mt-4">创建个人访问令牌</p>
      </div>
      <div className="text-sm text-gray-700">
        <p className="mt-4">
          如果项目访问令牌不可用，也可考虑使用个人访问令牌代替。
        </p>
        <p className="mt-4">
          点击用户头像，选择 <b>偏好设置</b>。进入后在左侧边栏选择{" "}
          <b>访问令牌</b>，点击 <b>添加新令牌</b>。
        </p>
        <p className="mt-4">
          选择范围选项选择 <b>api</b>；其他选项按需求填写，完成后点击{" "}
          <b>创建 个人访问令牌</b>。
        </p>
      </div>
      <div className="text-base font-semibold leading-4 text-gray-900">
        <p className="mt-4">提交前扫描</p>
      </div>
      <div className="text-sm text-gray-700">
        <p className="mt-4">
          进入需要触发提交前代码扫描的项目，在左侧边栏中，选择{" "}
          <b>设置 {`>`} Webhooks</b>。
        </p>
        <p className="mt-4">
          进入后点击 <b>添加新的 webhook</b>（GitLab 16.3 及之后的版本）。
        </p>
        <p className="mt-4">
          在 <b>URL</b> 中，输入{" "}
          <b>{props.webhookProxy + "change_hook/gitlab"}</b>
        </p>
        <p className="mt-4">
          在 <b>触发来源</b> 中，选择 <b>合并请求事件</b>。
        </p>
        <p className="mt-4">
          点击 <b>添加 webhook</b>，完成 webhook 配置。
        </p>
      </div>
      <div className="text-base font-semibold leading-4 text-gray-900">
        <p className="mt-4">提交后扫描</p>
      </div>
      <div className="text-sm text-gray-700">
        <p className="mt-4">
          进入需要触发提交后代码扫描的项目，在左侧边栏中，选择{" "}
          <b>设置 {`>`} Webhooks</b>。
        </p>
        <p className="mt-4">
          进入后点击 <b>添加新的 webhook</b>（GitLab 16.3 及之后的版本）。
        </p>
        <p className="mt-4">
          在 <b>URL</b> 中，输入{" "}
          <b>{props.webhookProxy + "change_hook/gitlab"}</b>
        </p>
        <p className="mt-4">
          在 <b>触发来源</b> 中，选择 <b>推送事件 {`>`} 所有分支</b>
          （默认 master 分支触发提交后扫描，可在高级设置中进行配置）。
        </p>
        <p className="mt-4">
          点击 <b>添加 webhook</b>，完成 webhook 配置。
        </p>
      </div>
      <div className="text-base font-semibold leading-4 text-gray-900">
        <p className="mt-4">webhook 关闭 SSL 验证</p>
      </div>
      <div className="text-sm text-gray-700">
        <p className="mt-4">
          如果没有为 {props.webhookProxy} 配置证书，需要在配置 webhook 时，在{" "}
          <b>SSL验证</b> 中，取消勾选 <b>启用SSL验证</b>。
        </p>
        <p className="mt-4">
          此外，如果 {props.webhookProxy} 是本地 IP 地址，且 GitLab
          实例也是本地部署，需要实例管理员手动添加 IP 地址：
        </p>
        <p className="mt-4">
          进入管理员面板，在左侧边栏中选择 <b>设置 {`>`} 网络</b>，展开{" "}
          <b>出站请求</b>，在 <b>Webhook 和集成可以访问的本地 IP 地址和域名</b>{" "}
          中输入 {props.webhookProxy} 的对应 IP 地址。
        </p>
      </div>
    </SlideOver>
  );
}
