/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import {
  AdjustmentsHorizontalIcon,
  DocumentChartBarIcon,
  ComputerDesktopIcon,
  CommandLineIcon,
  Cog6ToothIcon,
  FolderIcon,
} from "@heroicons/react/24/outline";
import { PropsWithChildren, useEffect, useState } from "react";
import { DarkSidebarLayout, NavigationItem } from "../uilib/layouts";
import {
  MyDashboardIcon,
  MyAPIIcon,
  MyScanIcon,
  MyBranchIcon,
} from "./ProjectLayoutIcon";

export function HomeLayout(props: PropsWithChildren) {
  const [navigation, setNavigation] = useState<NavigationItem[]>([]);

  useEffect(() => {
    const path = window.location.pathname;
    setNavigation([
      {
        name: "项目列表",
        href: "/projects",
        icon: FolderIcon,
        current: path === "/" || path === "/projects",
      },
      // TODO(r/13130): implement user_management
      /* {
      name: "用户管理",
      href: "/user_management",
      icon: Cog6ToothIcon,
      current: path === "/user_management",
    }, */
    ]);
  }, []);

  return (
    <DarkSidebarLayout navigation={navigation}>
      {props.children}
    </DarkSidebarLayout>
  );
}

function ProjectLayout(props: PropsWithChildren<{ currentSubName?: string }>) {
  const [navigation, setNavigation] = useState<NavigationItem[]>([]);

  useEffect(() => {
    const path = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    const projectID = params.get(path === "/project" ? "id" : "project_id");
    if (projectID === null) {
      throw new Error("project id is missing");
    }
    setNavigation([
      {
        name: "仪表盘",
        href: `/project?id=${projectID}`,
        icon: MyDashboardIcon,
        current: path === "/project",
      },
      {
        name: "扫描任务",
        icon: MyScanIcon,
        current:
          path === "/presubmit" ||
          path === "/postsubmit" ||
          path === "/developing" ||
          path === "/prerelease" ||
          path === "/scan_task_detail" ||
          path === "/scan_task" ||
          path === "/prerelease/newscantask" ||
          path === "/code_measurement",
        children: [
          {
            name: "开发中",
            href: `/developing?project_id=${projectID}`,
            current:
              path === "/developing" || props.currentSubName === "developing",
          },
          {
            name: "提交前",
            href: `/presubmit?project_id=${projectID}`,
            current:
              path === "/presubmit" || props.currentSubName === "presubmit",
          },
          {
            name: "提交后",
            href: `/postsubmit?project_id=${projectID}`,
            current:
              path === "/postsubmit" || props.currentSubName === "postsubmit",
          },
          {
            name: "发布前",
            href: `/prerelease?project_id=${projectID}`,
            current:
              path === "/prerelease" || props.currentSubName === "prerelease",
          },
        ],
      },
      {
        name: "分支",
        href: `/branches?project_id=${projectID}`,
        icon: MyBranchIcon,
        current: path === "/branches",
      },
      // TODO(kevin): 暂时隐藏
      // {
      //   name: "问题",
      //   href: `/resultsviewer?project_id=${projectID}`,
      //   icon: ExclamationTriangleIcon,
      //   current: path === "/resultsviewer",
      // },
      {
        name: "执行器",
        href: `/runners?project_id=${projectID}`,
        icon: CommandLineIcon,
        current: path === "/runners",
      },
      {
        name: "桌面扫描",
        href: `/desktopscan?project_id=${projectID}`,
        icon: ComputerDesktopIcon,
        current: path === "/desktopscan",
      },
      {
        name: "API",
        href: `/apisettings?project_id=${projectID}`,
        icon: MyAPIIcon,
        current: path === "/apisettings",
      },
      {
        name: "报告",
        href: `/reports?project_id=${projectID}`,
        icon: DocumentChartBarIcon,
        current: path === "/reports",
      },
      {
        name: "规则设置",
        href: `/rulesettings?project_id=${projectID}`,
        icon: AdjustmentsHorizontalIcon,
        current: path === "/rulesettings",
      },
      {
        name: "项目设置",
        href: `/projectsettings?project_id=${projectID}`,
        icon: Cog6ToothIcon,
        current: path === "/projectsettings",
      },
    ]);
  }, [props.currentSubName]);

  return (
    <DarkSidebarLayout navigation={navigation}>
      {props.children}
    </DarkSidebarLayout>
  );
}

export default ProjectLayout;
