/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import { PlusIcon, ArrowPathIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { ClassNames } from "../common/classnames";
import ProjectLayout from "../components/ProjectLayout";
import { ToolbarButton } from "../uilib/buttons";
import {
  BreadcrumbItem,
  Breadcrumbs,
  MainContentWithTitle,
} from "../uilib/layouts";
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHead,
  TableRow,
} from "../uilib/tables";

enum TaskState {
  pending = 0,
  success,
  failed,
}

enum RunnerState {
  active = 0,
  inactive,
}

interface Task {
  id: number;
  state: TaskState;
}

interface Runner {
  state: RunnerState; // 0: active, 1: inactive
  name: string;
  recentTasks: Task[];
  admin: string;
  adminEmail: string;
  host: string;
  version: string;
}

interface APIPortalProjectRunners {
  runnersList: Runner[];
  pageSize: number;
  itemCount: number;
  totalCount: number;
  customRunnersEnabled: boolean;
  projectName: string;
}

declare global {
  interface Window {
    apiPortalProjectRunners: APIPortalProjectRunners;
  }
}

function ScanTaskCell(props: { id: number; color: string }) {
  return (
    <span
      className={ClassNames(
        "mr-2 rounded-full px-2 pb-px text-xs",
        `bg-${props.color}-100 text-${props.color}-800`
      )}
    >
      {`扫描任务 ${props.id}`}
    </span>
  );
}

function ProjectRunners() {
  const [projectId, setProjectId] = useState("");
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);
  const [runners, setRunners] = useState<Runner[]>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [itemCount, setItemCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [searchStr, setSearchStr] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("project_id") === null) {
      throw new Error("project id is missing");
    } else {
      setProjectId(params.get("project_id")!);
    }
    setBreadcrumbs([
      {
        name: window.apiPortalProjectRunners.projectName,
        href: "/project?id=" + params.get("project_id"),
        current: false,
      },
      {
        name: "执行器",
        href: "",
        current: true,
      },
    ]);
    setRunners(window.apiPortalProjectRunners.runnersList);
    const pageStr = params.get("page");
    if (pageStr === null) {
      setPage(1);
    } else {
      setPage(parseInt(pageStr));
    }
    setSearchStr(window.location.search);
    setPageSize(window.apiPortalProjectRunners.pageSize);
    setItemCount(window.apiPortalProjectRunners.itemCount);
    setTotalCount(window.apiPortalProjectRunners.totalCount);
  }, []);

  return (
    <ProjectLayout>
      <Breadcrumbs pages={breadcrumbs} />
      <MainContentWithTitle title="执行器">
        {window.apiPortalProjectRunners.customRunnersEnabled ? (
          <>
            <div className="my-4">
              <a href={`/newrunner?project_id=${projectId}`}>
                <ToolbarButton icon={PlusIcon} disabled={false}>
                  添加执行器
                </ToolbarButton>
              </a>
            </div>
            <Table
              page={page}
              pageSize={pageSize}
              itemCount={itemCount}
              totalCount={totalCount}
              searchStr={searchStr}
            >
              <TableHead>
                <TableColumn first textAlign="center">
                  状态
                </TableColumn>
                <TableColumn textAlign="center">执行器</TableColumn>
                <TableColumn textAlign="center">最近建立</TableColumn>
                <TableColumn textAlign="center">管理员</TableColumn>
                <TableColumn textAlign="center">主机</TableColumn>
                <TableColumn last textAlign="center">
                  版本
                </TableColumn>
              </TableHead>
              <TableBody>
                {runners.map((runner, idx) => (
                  <TableRow key={idx}>
                    <TableCell first textAlign="center">
                      {runner.state === RunnerState.active ? (
                        <ArrowPathIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      ) : (
                        <XMarkIcon
                          className="h-5 w-5 text-red-400"
                          aria-hidden="true"
                        />
                      )}
                    </TableCell>
                    <TableCell textAlign="center">
                      <div>{runner.name}</div>
                    </TableCell>
                    <TableCell>
                      <div className="flex flex-wrap gap-y-1">
                        {runner.recentTasks.map((task, i) => {
                          switch (task.state) {
                            case TaskState.pending:
                              return (
                                <ScanTaskCell
                                  id={task.id}
                                  color="yellow"
                                  key={i}
                                />
                              );
                            case TaskState.success:
                              return (
                                <ScanTaskCell
                                  id={task.id}
                                  color="green"
                                  key={i}
                                />
                              );
                            case TaskState.failed:
                              return (
                                <ScanTaskCell
                                  id={task.id}
                                  color="red"
                                  key={i}
                                />
                              );
                            default:
                              break;
                          }
                          return <></>;
                        })}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div>{runner.admin}</div>
                      <div>{`<${runner.adminEmail}>`}</div>
                    </TableCell>
                    <TableCell textAlign="center">
                      <div>{runner.host}</div>
                    </TableCell>
                    <TableCell last textAlign="center">
                      <div>{runner.version}</div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        ) : (
          <div className="my-4 text-gray-500">
            当前使用许可不包括自定义执行器功能
          </div>
        )}
      </MainContentWithTitle>
    </ProjectLayout>
  );
}

export default ProjectRunners;
