interface RSCheckboxIconProps {
  max: number;
  cur: number;
}

const RSCheckboxIcon: React.FC<RSCheckboxIconProps> = ({ max, cur }) => {
  const emptyBoxSVG = () => {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2313_12279)">
          <rect width="18" height="18" fill="white" rx="4" />
          <rect
            x="0"
            y="0"
            width="18"
            height="18"
            rx="4"
            stroke="#D1D5DB"
            strokeWidth="0.747991"
          />
        </g>
        <defs>
          <clipPath id="clip0_2313_12279">
            <rect width="18" height="18" fill="white" rx="4" />
          </clipPath>
        </defs>
      </svg>
    );
  };
  const checkedboxSVG = () => {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="18" height="18" fill="#0EA5E9" rx="4" />
        <path
          d="M12.6836 5.34375C12.876 5.53612 12.9999 5.77409 12.9999 6.03125C12.9999 6.28842 12.876 6.52639 12.6836 6.71875L7.71875 11.6836C7.52639 11.876 7.28842 11.9999 7.03125 11.9999C6.77409 11.9999 6.53612 11.876 6.34375 11.6836L4.03125 9.37109C3.83888 9.17872 3.71496 8.94075 3.71496 8.68359C3.71496 8.42642 3.83888 8.18845 4.03125 7.99609C4.22362 7.80372 4.46159 7.67981 4.71875 7.67981C4.97592 7.67981 5.21389 7.80372 5.40625 7.99609L7.03125 9.62109L11.3438 5.34375C11.5361 5.15139 11.7741 5.02747 12.0312 5.02747C12.2884 5.02747 12.5264 5.15139 12.6836 5.34375Z"
          fill="white"
        />
      </svg>
    );
  };
  const dashLineSVG = () => {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2306_12288)">
          <rect width="18" height="18" fill="white" rx="4" />
          <rect width="18" height="18" fill="#0EA5E9" rx="4" />
          <rect
            x="2.38672"
            y="8.09375"
            width="13.2266"
            height="2.38672"
            rx="1.19336"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_2306_12288">
            <rect width="18" height="18" fill="white" rx="4" />
          </clipPath>
        </defs>
      </svg>
    );
  };
  return (
    <div className="h-4 w-4 cursor-pointer text-sky-600">
      {cur === 0
        ? emptyBoxSVG()
        : cur === max
        ? checkedboxSVG()
        : dashLineSVG()}
    </div>
  );
};

export default RSCheckboxIcon;
