export interface ScanTaskStep {
  name: string;
  status: string; // 'pending', 'running', 'completed', 'error'
  progress?: string; // a status string or xx% done ratio
  createOn?: string;
}

export interface ScanTask {
  id: string;
  repoKind: string; // 'git', 'zip'
  revision: string;
  refName?: string;
  subject?: string;
  steps: ScanTaskStep[];
  submittedAt: string; // must be in ISO 8601 format
  durationSeconds?: number;
  durationSecondsStr?: string;
  linesOfCode?: number;
  highestSeverityCount?: number;
  highSeverityCount?: number;
  mediumSeverityCount?: number;
  lowSeverityCount?: number;
  lowestSeverityCount?: number;
  unknownSeverityCount?: number;
  compileErrorCounter?: number;
  hasCheckRules?: boolean;
  builderID?: string;
  hasBuilderConfig?: boolean;
}

export const ScanSubStep: Map<string, string> = new Map([
  ["presubmit", "提交前"],
  ["postsubmit", "提交后"],
  ["developing", "开发中"],
  ["prerelease", "发布前"],
]);
