import { PropsWithChildren } from "react";
import { ClassNames } from "../common/classnames";

export default function Modal(
  props: PropsWithChildren<{
    showModal: boolean;
    clickHandler: (e: React.MouseEvent<HTMLElement>) => void;
    title: string;
    disabled?: boolean;
  }>
) {
  return (
    <>
      {props.showModal && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            ></span>
            <div className="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  {props.title}
                </h3>
                <div className="mt-4 flex content-center justify-center text-left">
                  {props.children}
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  className={ClassNames(
                    "inline-flex items-center rounded-md border border-gray-300 px-3 py-2 text-sm font-medium leading-4 shadow-sm focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2",
                    props.disabled
                      ? "bg-gray-100 text-gray-300"
                      : "bg-white text-gray-700 hover:bg-gray-50"
                  )}
                  onClick={props.clickHandler}
                  disabled={props.disabled}
                >
                  确定
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
