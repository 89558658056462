/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import { PropsWithChildren } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

function linkToPage(page: number, searchStr: string): string {
  const params = new URLSearchParams(searchStr);
  params.set("page", page.toString());
  return "?" + params;
}

function Pagination(props: {
  page: number;
  pageSize: number;
  itemCount: number;
  totalCount: number;
  searchStr: string;
}) {
  const firstItem = (props.page - 1) * props.pageSize + 1;
  const lastItem = firstItem + props.itemCount - 1;
  const lastPage = Math.ceil(props.totalCount / props.pageSize);
  const pages: number[] = [];
  if (lastPage <= 7) {
    // all page numbers are directly visible
    for (let i = 1; i <= lastPage; i++) {
      pages.push(i);
    }
  } else {
    const p = props.page;
    const n = lastPage;

    // < *1*  2   3  ...  6   7   8  >
    // <  1   2   3  *4*  5  ...  8  >
    // <  1  ...  4  *5*  6   7   8  >

    // < *1*  2   3  ...  8   9   10  >
    // <  1  *2*  3  ...  8   9   10  >
    // <  1   2  *3* ...  8   9   10  >
    // <  1   2   3  *4*  5  ...  10  >
    // <  1  ...  4  *5*  6  ...  10  >
    // <  1  ...  5  *6*  7  ...  10  >
    // <  1  ...  6  *7*  8   9   10  >
    // <  1   2   3  ... *8*  9   10  >

    if (p <= 3) {
      pages.push(1, 2, 3, 0, n - 2, n - 1, n);
    } else if (p === 4) {
      pages.push(1, 2, 3, 4, 5, 0, n);
    } else if (p < n - 3) {
      pages.push(1, 0, p - 1, p, p + 1, 0, n);
    } else if (p === n - 3) {
      pages.push(1, 0, p - 1, p, p + 1, n - 1, n);
    } else {
      pages.push(1, 2, 3, 0, n - 2, n - 1, n);
    }
  }
  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        {props.page > 1 ? (
          <a
            href={linkToPage(props.page - 1, props.searchStr)}
            className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            上一页
          </a>
        ) : (
          <span className="relative inline-flex cursor-not-allowed items-center rounded-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-300">
            上一页
          </span>
        )}
        {props.page < lastPage ? (
          <a
            href={linkToPage(props.page + 1, props.searchStr)}
            className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            下一页
          </a>
        ) : (
          <span className="relative ml-3 inline-flex cursor-not-allowed items-center rounded-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-300">
            下一页
          </span>
        )}
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            当前显示第<span className="ml-0.5 font-medium">{firstItem}</span>-
            <span className="mr-0.5 font-medium">{lastItem}</span>条结果（共
            <span className="mx-0.5 font-medium">{props.totalCount}</span>条）
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            {props.page > 1 ? (
              <a
                href={linkToPage(props.page - 1, props.searchStr)}
                className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
              >
                <span className="sr-only">上一页</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </a>
            ) : (
              <span className="relative inline-flex cursor-not-allowed items-center rounded-l-md border border-gray-300 bg-gray-50 px-2 py-2 text-sm font-medium text-gray-300">
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </span>
            )}
            {pages.map((p) => {
              return p === props.page ? (
                <a
                  href={linkToPage(p, props.searchStr)}
                  aria-current="page"
                  className="relative z-10 inline-flex items-center border border-sky-500 bg-sky-50 px-4 py-2 text-sm font-medium text-sky-600 focus:z-20"
                >
                  {p}
                </a>
              ) : p === 0 ? (
                <span className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700">
                  ...
                </span>
              ) : (
                <a
                  href={linkToPage(p, props.searchStr)}
                  className="relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 md:inline-flex"
                >
                  {p}
                </a>
              );
            })}
            {props.page < lastPage ? (
              <a
                href={linkToPage(props.page + 1, props.searchStr)}
                className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
              >
                <span className="sr-only">下一页</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </a>
            ) : (
              <span className="relative inline-flex cursor-not-allowed items-center rounded-r-md border border-gray-300 bg-gray-50 px-2 py-2 text-sm font-medium text-gray-300">
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </span>
            )}
          </nav>
        </div>
      </div>
    </div>
  );
}

export function Table(
  props: PropsWithChildren<{
    page?: number;
    pageSize?: number;
    itemCount?: number;
    totalCount?: number;
    searchStr?: string;
  }>
) {
  return (
    <div className="flex flex-col">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              {props.children}
            </table>
            {props.page !== undefined &&
              props.page > 0 &&
              props.pageSize !== undefined &&
              props.pageSize > 0 &&
              props.itemCount !== undefined &&
              props.totalCount !== undefined &&
              props.searchStr !== undefined && (
                <Pagination
                  page={props.page}
                  pageSize={props.pageSize}
                  itemCount={props.itemCount}
                  totalCount={props.totalCount}
                  searchStr={props.searchStr}
                />
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export function TableHead(props: PropsWithChildren<{}>) {
  return (
    <thead className="bg-gray-50">
      <tr>{props.children}</tr>
    </thead>
  );
}

export function TableColumn(
  props: PropsWithChildren<{
    first?: boolean;
    last?: boolean;
    textAlign?: string;
  }>
) {
  const align = props.textAlign ? ` text-${props.textAlign}` : " text-left";
  return (
    <th
      scope="col"
      className={
        (props.first
          ? "pl-4 pr-3 sm:pl-6"
          : props.last
          ? "relative pl-3 pr-4 sm:pr-6"
          : "px-3") +
        " py-3.5 text-sm font-semibold text-gray-900" +
        align
      }
    >
      {props.children}
    </th>
  );
}

export function TableBody(props: PropsWithChildren<{}>) {
  return (
    <tbody className="divide-y divide-gray-200 bg-white">
      {props.children}
    </tbody>
  );
}

export function TableRow(props: PropsWithChildren<{}>) {
  return <tr>{props.children}</tr>;
}

export function TableCell(
  props: PropsWithChildren<{
    first?: boolean;
    last?: boolean;
    colSpan?: number;
    textAlign?: string;
    highlight?: boolean;
  }>
) {
  const align = props.textAlign ? ` text-${props.textAlign}` : " text-left";
  return (
    <td
      className={
        (props.first
          ? "pl-4 pr-3 sm:pl-6"
          : props.last
          ? "pl-3 pr-4 sm:pr-6"
          : "px-3") +
        (props.highlight
          ? props.colSpan
            ? " bg-gray-50 font-semibold"
            : " border-r border-gray-300 bg-gray-50 font-semibold"
          : " bg-white text-gray-500") +
        " relative whitespace-nowrap py-4 text-sm" +
        align
      }
      colSpan={props.colSpan}
    >
      {props.children}
    </td>
  );
}
