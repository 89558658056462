/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import {
  GitBranchIcon,
  GitCommitIcon,
  UploadIcon,
} from "@primer/octicons-react";
import { useEffect, useState } from "react";
import ProjectLayout from "../components/ProjectLayout";
import { formatBytes } from "./ProjectNewScanTask";
import {
  BreadcrumbItem,
  Breadcrumbs,
  MainContentWithTitle,
} from "../uilib/layouts";
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHead,
  TableRow,
} from "../uilib/tables";

const ascii85 = require("ascii85");

interface Report {
  id: string;
  revision: string;
  refName?: string;
  subject?: string;
  createdTime: string;
  format: string;
  status: string;
  size: number;
}

interface APIPortalProjectReports {
  reportList: Report[];
  pageSize: number;
  itemCount: number;
  totalCount: number;
  reportsEnabled: boolean;
  projectName: string;
}

declare global {
  interface Window {
    apiPortalProjectReports: APIPortalProjectReports;
  }
}

const statusMap: Map<string, string> = new Map([
  ["pending", "待生成"],
  ["running", "生成中"],
  ["completed", "成功"],
  ["error", "失败"],
]);

function cleanRefName(s: string): string {
  if (s.startsWith("refs/heads/refs/changes/")) {
    return s.substring("refs/heads/".length);
  }
  return s;
}

export function ProjectReports() {
  const [reports, setReports] = useState<Report[]>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [itemCount, setItemCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [searchStr, setSearchStr] = useState("");
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);
  const [projectID, setProjectID] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const projectID = params.get("project_id");
    if (projectID === null) {
      throw new Error("project id is missing");
    }
    setProjectID(projectID);
    setBreadcrumbs([
      {
        name: window.apiPortalProjectReports.projectName,
        href: "/project?id=" + projectID,
        current: false,
      },
      {
        name: "报告",
        href: "",
        current: true,
      },
    ]);
    setReports(window.apiPortalProjectReports.reportList);
    const pageStr = params.get("page");
    if (pageStr === null) {
      setPage(1);
    } else {
      setPage(parseInt(pageStr));
    }
    setPageSize(window.apiPortalProjectReports.pageSize);
    setItemCount(window.apiPortalProjectReports.itemCount);
    setTotalCount(window.apiPortalProjectReports.totalCount);
    setSearchStr(window.location.search);
  }, []);

  return (
    <ProjectLayout>
      <Breadcrumbs pages={breadcrumbs} />
      <MainContentWithTitle title="报告">
        {window.apiPortalProjectReports.reportsEnabled ? (
          <div className="my-4">
            <Table
              page={page}
              pageSize={pageSize}
              itemCount={itemCount}
              totalCount={totalCount}
              searchStr={searchStr}
            >
              <TableHead>
                <TableColumn first></TableColumn>
                <TableColumn>描述</TableColumn>
                <TableColumn>报告生成时间</TableColumn>
                <TableColumn>格式</TableColumn>
                <TableColumn>文件大小</TableColumn>
                <TableColumn>状态</TableColumn>
              </TableHead>
              <TableBody>
                {reports.map((report) => (
                  <TableRow key={report.id}>
                    <TableCell first>
                      <input
                        type="checkbox"
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-sky-500 focus:ring-sky-500"
                      />
                    </TableCell>
                    <TableCell>
                      <div className="max-w-[35ch] truncate font-bold">
                        {report.status === "completed" ? (
                          <a
                            href={`/report?project_id=${projectID}&id=${report.id}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {report.subject || "暂无信息"}
                          </a>
                        ) : (
                          <>{report.subject || "暂无信息"}</>
                        )}
                      </div>
                      <div className="mt-1">
                        {report.revision ? (
                          <span>
                            <GitCommitIcon className="mr-1" />
                            {report.revision.substring(0, 12)}
                          </span>
                        ) : (
                          <span>
                            <UploadIcon className="mr-1" />
                            通过zip上传
                          </span>
                        )}
                        {report.refName &&
                          report.refName !== report.revision && (
                            <span className="ml-3.5">
                              <GitBranchIcon className="mr-1" />
                              {cleanRefName(report.refName)}
                            </span>
                          )}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center">
                        {report.status === "completed"
                          ? new Date(report.createdTime).toLocaleString(
                              "en-US",
                              {
                                hour12: false,
                                timeZoneName: "short",
                              }
                            )
                          : "-"}
                      </div>
                    </TableCell>
                    <TableCell>{report.format}</TableCell>
                    <TableCell>{formatBytes(report.size, 2)}</TableCell>
                    <TableCell>
                      {/* TODO: use icons for status */}
                      {statusMap.has(report.status)
                        ? statusMap.get(report.status)
                        : "-"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <div className="my-4 text-gray-500">
            当前使用许可不包括项目报告功能
          </div>
        )}
      </MainContentWithTitle>
    </ProjectLayout>
  );
}

interface APIPortalProjectReport {
  reportContents: string;
  reportFormatType: string;
  reportsEnabled: boolean;
}

declare global {
  interface Window {
    apiPortalProjectReport: APIPortalProjectReport;
  }
}

export function ProjectReport() {
  if (window.apiPortalProjectReport.reportsEnabled) {
    let blobType: string, fileName: string;
    switch (window.apiPortalProjectReport.reportFormatType) {
      case "html":
        blobType = "text/html";
        fileName = "report.html";
        break;
      case "pdf":
        blobType = "application/octet-stream";
        fileName = "report.pdf";
        break;
      case "word":
        blobType = "application/octet-stream";
        fileName = "report.docx";
        break;
      case "excel":
        blobType = "application/octet-stream";
        fileName = "report.csv";
        break;
      case "xml":
        blobType = "application/octet-stream";
        fileName = "report.xml";
        break;
      default:
        console.error(
          `invalid format: ${window.apiPortalProjectReport.reportFormatType}`
        );
        return <div />;
    }

    const enc = new TextEncoder();
    const report = new Uint8Array(
      ascii85.decode(enc.encode(window.apiPortalProjectReport.reportContents))
    );
    const url = URL.createObjectURL(
      new Blob([report], {
        type: blobType,
      })
    );

    if (window.apiPortalProjectReport.reportFormatType !== "html") {
      const anchorElement = document.createElement("a");
      document.body.appendChild(anchorElement);
      anchorElement.href = url;
      anchorElement.download = fileName;
      anchorElement.click();
      window.close();
    } else {
      window.location.replace(url);
    }
  }

  return window.apiPortalProjectReport.reportsEnabled ? (
    <div className="my-4 text-gray-500">正在读取报告</div>
  ) : (
    <div className="my-4 text-gray-500">当前使用许可不包括项目报告功能</div>
  );
}
