/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import { XCircleIcon, CheckCircleIcon } from "@heroicons/react/20/solid";
import { FormEvent, useEffect, useState } from "react";
import { api } from "../api";
import {
  BreadcrumbItem,
  Breadcrumbs,
  MainContentWithTitle,
} from "../uilib/layouts";
import ProjectLayout from "../components/ProjectLayout";
import { SettingField, RepoHelpText } from "../components/SettingField";
import { BasicButton } from "../components/BasicButton";
import OptionalModal from "../components/OptionalModal";
import { PrimaryButton } from "../uilib/buttons";
import {
  HelpText,
  SelectField,
  TextAreaField,
  TextField,
  CheckboxField,
} from "../uilib/forms";
import {
  GerritHelp,
  GerritSettings,
  GerritSettingsForm,
} from "../components/GerritSettings";
import {
  GitlabHelp,
  GitlabSettings,
  GitlabSettingsForm,
} from "../components/GitlabSettings";
import {
  GithubHelp,
  GithubSettings,
  GithubSettingsForm,
} from "../components/GithubSettings";

interface APIPortalProjectSettings {
  buildbotPublicKey: string;
  webhookProxy: string;
  projectSettingsEnabled: boolean;
  gerritSupportEnabled: boolean;
  gitlabSupportEnabled: boolean;
  githubSupportEnabled: boolean;
  otherLanguagesEnabled: boolean;
  projectName: string;
  projectType: string;
  analyzeSrcDir: string;
  qtProPath: string;
  buildScript: string;
  initSubmodule: boolean;
  repoKind: string;
  gerritSettings: string;
  gitlabSettings: string;
  githubSettings: string;
  connectionFail: boolean;
}

declare global {
  interface Window {
    apiPortalProjectSettings: APIPortalProjectSettings;
  }
}

function ProjectSettings() {
  const [projectID, setProjectID] = useState("");
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteProjectError, setDeleteProjectError] = useState("");
  const [projectType, setProjectType] = useState("");
  const [qtProPath, setQtProPath] = useState("");
  const [qtProPathError, setQtProPathError] = useState("");
  const [buildScript, setBuildScript] = useState("");
  const [buildScriptError, setBuildScriptError] = useState("");
  const [initSubmodule, setInitSubmodule] = useState(false);
  const [repoKind, setRepoKind] = useState("");
  const [analyzeSrcDir, setAnalyzeSrcDir] = useState("");
  const [analyzeSrcDirError, setAnalyzeSrcDirError] = useState("");
  const [submitPending, setSubmitPending] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState("");
  const [showGerritCreateAccountHelp, setShowGerritCreateAccountHelp] =
    useState(false);
  const [showGitlabCreateWebhookHelp, setShowGitlabCreateWebhookHelp] =
    useState(false);
  const [showGithubCreateWebhookHelp, setShowGithubCreateWebhookHelp] =
    useState(false);
  const [gerritSettings, setGerritSettings] = useState<GerritSettings>({
    sshPublicKey: window.apiPortalProjectSettings.buildbotPublicKey,
    username: "naivesystems",
    host: "",
    port: 29418,
    project: "",
    branch: "master",
    eventType: "patchset-created",
  });
  const [gerritUsernameError, setGerritUsernameError] = useState("");
  const [gerritHostError, setGerritHostError] = useState("");
  const [gerritPortError, setGerritPortError] = useState("");
  const [gerritProjectError, setGerritProjectError] = useState("");
  const [gerritBranchError, setGerritBranchError] = useState("");
  const [gitlabSettings, setGitlabSettings] = useState<GitlabSettings>({
    gitCloneURL: "",
    projectAccessToken: "",
    branches: "master",
    port: 22,
  });
  const [gitlabPortError, setGitlabPortError] = useState("");
  const [githubSettings, setGithubSettings] = useState<GithubSettings>({
    gitCloneURL: "",
    personalAccessToken: "",
    branches: "master",
  });
  const webhookProxy = window.apiPortalProjectSettings.webhookProxy.endsWith(
    "/"
  )
    ? window.apiPortalProjectSettings.webhookProxy
    : window.apiPortalProjectSettings.webhookProxy + "/";
  const connectionFail = window.apiPortalProjectSettings.connectionFail;

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let hasErrors = false;
    if (analyzeSrcDir.length > 255) {
      setAnalyzeSrcDirError("文件路径的长度不能超过255");
      hasErrors = true;
    } else if (
      analyzeSrcDir.startsWith("/") ||
      analyzeSrcDir.startsWith("\\")
    ) {
      setAnalyzeSrcDirError("请填写相对路径");
      hasErrors = true;
    }
    if (projectType === "qmake") {
      if (qtProPath.length > 255) {
        setQtProPathError("文件路径的长度不能超过255");
        hasErrors = true;
      }
    } else if (projectType === "script") {
      if (buildScript.length > 1000) {
        setBuildScriptError("自定义构建脚本的长度不能超过1000");
        hasErrors = true;
      }
    }
    if (repoKind === "gerrit") {
      if (!window.apiPortalProjectSettings.gerritSupportEnabled) {
        setSubmitError("当前使用许可不包括 Gerrit 相关功能");
        hasErrors = true;
      }
      if (gerritSettings.username === "") {
        setGerritUsernameError("请填写 Gerrit 账户名");
        hasErrors = true;
      }
      if (gerritSettings.username.length > 255) {
        setGerritUsernameError("账户名的长度不能超过255");
        hasErrors = true;
      }
      if (gerritSettings.host === "") {
        setGerritHostError("请填写服务器地址");
        hasErrors = true;
      }
      if (gerritSettings.host.length > 253) {
        setGerritHostError("服务器地址的长度不能超过253");
        hasErrors = true;
      }
      if (gerritSettings.port <= 0) {
        setGerritPortError("端口号必须大于0");
        hasErrors = true;
      }
      if (gerritSettings.port > 65535) {
        setGerritPortError("端口号不能超过65535");
        hasErrors = true;
      }
      if (gerritSettings.project === "") {
        setGerritProjectError("请填写 Gerrit 项目 ID");
        hasErrors = true;
      }
      if (gerritSettings.project.length > 255) {
        setGerritProjectError("项目 ID 的长度不能超过255");
        hasErrors = true;
      }
      if (gerritSettings.branch === "") {
        setGerritBranchError("请填写分支");
        hasErrors = true;
      }
      if (gerritSettings.branch.length > 255) {
        setGerritBranchError("分支的长度不能超过255");
        hasErrors = true;
      }
    }
    if (repoKind === "gitlab") {
      if (!window.apiPortalProjectSettings.gitlabSupportEnabled) {
        setSubmitError("当前使用许可不包括 GitLab 相关功能");
        hasErrors = true;
      }
      if (gitlabSettings.port <= 0) {
        setGitlabPortError("端口号必须大于0");
        hasErrors = true;
      }
      if (gitlabSettings.port > 65535) {
        setGitlabPortError("端口号不能超过65535");
        hasErrors = true;
      }
    }
    if (repoKind === "github") {
      if (!window.apiPortalProjectSettings.githubSupportEnabled) {
        setSubmitError("当前使用许可不包括 GitHub 相关功能");
        hasErrors = true;
      }
    }
    if (hasErrors) {
      return;
    }
    setSubmitPending(true);
    api
      .NewService()
      .UpdateProject({
        projectID,
        projectType,
        analyzeSrcDir,
        qtProPath,
        buildScript,
        initSubmodule,
        repoKind,
        gerritSettings,
        gitlabSettings,
        githubSettings,
      })
      .then((reply) => {
        if (reply.status === "ok") {
          setSubmitSuccess("保存成功");
        } else if (reply.status === "error") {
          setSubmitError("保存失败：" + reply.reason);
        }
        setSubmitPending(false);
      })
      .catch((err) => {
        console.error(err);
        setSubmitError("网络错误");
        setSubmitPending(false);
      });
  };

  useEffect(() => {
    const path = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    const projectID = params.get(path === "/project" ? "id" : "project_id");
    if (projectID === null) {
      throw new Error("project id is missing");
    }
    setProjectID(projectID);
    setBreadcrumbs([
      {
        name: window.apiPortalProjectSettings.projectName!,
        href: "/project?id=" + params.get("project_id"),
        current: false,
      },
      {
        name: "设置",
        href: "",
        current: true,
      },
    ]);
    switch (window.apiPortalProjectSettings.repoKind) {
      case "gerrit":
        const gerrit = JSON.parse(
          window.apiPortalProjectSettings.gerritSettings
        );
        setGerritSettings({
          sshPublicKey: window.apiPortalProjectSettings.buildbotPublicKey,
          username: gerrit.username,
          host: gerrit.host,
          port: gerrit.port,
          project: gerrit.project,
          branch: gerrit.branch,
          eventType: gerrit.eventType,
        });
        break;
      case "gitlab":
        const gitlab = JSON.parse(
          window.apiPortalProjectSettings.gitlabSettings
        );
        setGitlabSettings({
          gitCloneURL: gitlab.gitCloneURL,
          projectAccessToken: gitlab.projectAccessToken,
          branches: gitlab.branches,
          port: gitlab.port,
        });
        break;
      case "github":
        const github = JSON.parse(
          window.apiPortalProjectSettings.githubSettings
        );
        setGithubSettings({
          gitCloneURL: github.gitCloneURL,
          personalAccessToken: github.personalAccessToken,
          branches: github.branches,
        });
        break;
    }
    setProjectType(window.apiPortalProjectSettings.projectType);
    setQtProPath(window.apiPortalProjectSettings.qtProPath);
    setBuildScript(window.apiPortalProjectSettings.buildScript);
    setInitSubmodule(window.apiPortalProjectSettings.initSubmodule);
    setRepoKind(window.apiPortalProjectSettings.repoKind);
    setAnalyzeSrcDir(window.apiPortalProjectSettings.analyzeSrcDir);
  }, []);

  function confirmHandler() {
    setOpenDialog(false);
    api
      .NewService()
      .DeleteProject({
        projectID,
      })
      .then((reply) => {
        if (reply.status === "ok") {
          window.location.replace("/projects");
        } else {
          setDeleteProjectError("删除失败：" + reply.reason);
        }
      })
      .catch((error) => {
        console.log(error);
        setDeleteProjectError("网络错误");
      });
  }

  return (
    <ProjectLayout>
      <Breadcrumbs pages={breadcrumbs} />
      <MainContentWithTitle title="项目设置">
        {window.apiPortalProjectSettings.projectSettingsEnabled ? (
          <div className="my-6 flex w-full flex-col divide-y rounded-lg bg-white dark:text-slate-200">
            <div className="flex-grow p-6">
              <div className="space-y-8 divide-y divide-gray-200">
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                  <div className="col-span-full font-semibold">项目信息</div>
                  <div className="grid grid-cols-3 text-sm sm:col-span-4">
                    <div className="col-span-2">项目名称</div>
                    <div className="col-span-1">
                      {window.apiPortalProjectSettings.projectName}
                    </div>
                  </div>
                  <div className="hidden sm:col-span-8 sm:block"></div>
                </div>
                <form onSubmit={submit}>
                  <div className="mt-4 font-semibold">项目设置</div>
                  <div className="mt-6 grid grid-cols-1 gap-x-2 gap-y-4 space-y-4 divide-y divide-gray-200 rounded-lg border-[1px] border-solid border-gray-200 p-4 sm:grid-cols-12">
                    <div className="col-span-full grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-12">
                      <SettingField span="col-span-4" space="col-span-8">
                        <SelectField
                          id="project-type"
                          label="项目类型"
                          value={projectType}
                          onChange={setProjectType}
                        >
                          <option value="none">暂不设置</option>
                          <option value="makefile">Makefile</option>
                          <option value="cmake">CMake</option>
                          <option value="qmake">Qt</option>
                          <option value="keil">Keil MDK</option>
                          <option value="script">自定义构建脚本</option>
                          {window.apiPortalProjectSettings
                            .otherLanguagesEnabled && (
                            <option value="maven">Java (Maven)</option>
                          )}
                          {window.apiPortalProjectSettings
                            .otherLanguagesEnabled && (
                            <option value="other">其他</option>
                          )}
                        </SelectField>
                      </SettingField>
                      <SettingField span="col-span-8" space="col-span-4">
                        <TextField
                          id="analyze-src-dir"
                          value={analyzeSrcDir}
                          onChange={setAnalyzeSrcDir}
                          error={analyzeSrcDirError}
                          setError={setAnalyzeSrcDirError}
                        >
                          项目构建脚本所在目录
                        </TextField>
                        <HelpText>
                          <div>
                            选填，从项目根目录到构建脚本所在目录的相对路径，留空则使用项目根目录
                          </div>
                          <div>
                            如构建脚本为 subproject1/Makefile，则填 subproject1/
                          </div>
                        </HelpText>
                      </SettingField>
                      <SettingField
                        span="col-span-8"
                        space="col-span-4"
                        hidden={projectType !== "qmake"}
                      >
                        <TextField
                          id="qt-pro-path"
                          value={qtProPath}
                          onChange={setQtProPath}
                          error={qtProPathError}
                          setError={setQtProPathError}
                        >
                          Qt 项目文件 (*.pro) 路径
                        </TextField>
                        <HelpText>选填，留空则使用默认值</HelpText>
                      </SettingField>
                      <SettingField
                        span="col-span-8"
                        space="col-span-4"
                        hidden={projectType !== "script"}
                      >
                        <TextAreaField
                          rows={5}
                          id="build-script"
                          value={buildScript}
                          onChange={setBuildScript}
                          error={buildScriptError}
                          setError={setBuildScriptError}
                        >
                          自定义构建脚本内容
                        </TextAreaField>
                      </SettingField>
                      <div
                        className={
                          !connectionFail
                            ? "hidden"
                            : "col-span-full mb-4 rounded-md bg-red-50 p-4"
                        }
                      >
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <XCircleIcon
                              className="h-5 w-5 text-red-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-3">
                            <p className="text-sm text-red-800">
                              代码仓库连接失败，请检查代码仓库配置
                            </p>
                          </div>
                        </div>
                      </div>
                      <SettingField span="col-span-4">
                        <SelectField
                          id="repo-kind"
                          label="代码仓库类型"
                          value={repoKind}
                          onChange={setRepoKind}
                          disabled={true}
                        >
                          <option value="none">暂不设置</option>
                          <option value="gerrit">Gerrit</option>
                          <option value="gitlab">GitLab</option>
                          <option value="github">GitHub</option>
                        </SelectField>
                      </SettingField>
                      <SettingField span="col-span-8" className="ml-4">
                        <RepoHelpText
                          repoKind={repoKind}
                          gerritSupportEnabled={
                            window.apiPortalProjectSettings.gerritSupportEnabled
                          }
                          gitlabSupportEnabled={
                            window.apiPortalProjectSettings.gitlabSupportEnabled
                          }
                          githubSupportEnabled={
                            window.apiPortalProjectSettings.githubSupportEnabled
                          }
                          setShowGerritCreateAccountHelp={
                            setShowGerritCreateAccountHelp
                          }
                          setShowGitlabCreateWebhookHelp={
                            setShowGitlabCreateWebhookHelp
                          }
                          setShowGithubCreateWebhookHelp={
                            setShowGithubCreateWebhookHelp
                          }
                        ></RepoHelpText>
                      </SettingField>
                      <SettingField span="col-span-8">
                        {repoKind !== "none" && (
                          <>
                            <div className="sm:col-span-4">
                              <CheckboxField
                                id="init-submodule"
                                label="是否克隆子模块"
                                defaultChecked={initSubmodule}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setInitSubmodule(event.target.checked);
                                }}
                              />
                            </div>
                            <div className="hidden sm:col-span-8 sm:block"></div>
                          </>
                        )}
                      </SettingField>
                      <div
                        className={
                          repoKind === "gerrit" &&
                          window.apiPortalProjectSettings.gerritSupportEnabled
                            ? "contents"
                            : "hidden"
                        }
                      >
                        <GerritSettingsForm
                          settings={gerritSettings}
                          updateSettings={setGerritSettings}
                          usernameError={gerritUsernameError}
                          setUsernameError={setGerritUsernameError}
                          hostError={gerritHostError}
                          setHostError={setGerritHostError}
                          portError={gerritPortError}
                          setPortError={setGerritPortError}
                          projectError={gerritProjectError}
                          setProjectError={setGerritProjectError}
                          branchError={gerritBranchError}
                          setBranchError={setGerritBranchError}
                        />
                      </div>

                      <div
                        className={
                          repoKind === "gitlab" &&
                          window.apiPortalProjectSettings.gitlabSupportEnabled
                            ? "contents"
                            : "hidden"
                        }
                      >
                        <GitlabSettingsForm
                          settings={gitlabSettings}
                          updateSettings={setGitlabSettings}
                          portError={gitlabPortError}
                          setPortError={setGitlabPortError}
                          updateForm
                        />
                      </div>

                      <div
                        className={
                          repoKind === "github" &&
                          window.apiPortalProjectSettings.githubSupportEnabled
                            ? "contents"
                            : "hidden"
                        }
                      >
                        <GithubSettingsForm
                          settings={githubSettings}
                          updateSettings={setGithubSettings}
                          updateForm
                        />
                      </div>
                    </div>

                    <div className="flex justify-end sm:col-span-full">
                      <PrimaryButton
                        type="submit"
                        pending={submitPending}
                        className="mt-4"
                      >
                        保存
                      </PrimaryButton>
                    </div>
                  </div>
                </form>
                <div
                  className={
                    submitError === ""
                      ? "hidden"
                      : "mb-4 rounded-md bg-red-50 p-4"
                  }
                >
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <XCircleIcon
                        className="h-5 w-5 text-red-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-red-800">{submitError}</p>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    submitSuccess === ""
                      ? "hidden"
                      : "mb-4 rounded-md bg-green-50 p-4"
                  }
                >
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon
                        className="h-5 w-5 text-green-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-green-800">{submitSuccess}</p>
                    </div>
                  </div>
                </div>
                <div className="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                  <div
                    className={
                      deleteProjectError === ""
                        ? "hidden"
                        : "mb-4 rounded-md bg-red-50 p-4"
                    }
                  >
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <XCircleIcon
                          className="h-5 w-5 text-red-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-red-800">
                          {deleteProjectError}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-full mt-8 font-semibold">
                    危险区域
                  </div>
                  <p className="col-span-8 flex justify-between rounded border border-red-500 px-7">
                    <p className="my-2 text-sm text-gray-900">
                      <p className="pb-1 font-semibold">删除项目</p>
                      项目删除后，项目信息不可以恢复，请小心删除
                    </p>
                    <p className="flex items-center">
                      <BasicButton
                        disabled={false}
                        onClick={() => {
                          setOpenDialog(true);
                        }}
                      >
                        <span className="text-[#dc2626]">删除项目</span>
                      </BasicButton>
                    </p>
                  </p>
                  <OptionalModal
                    showModal={openDialog}
                    confirmHandler={confirmHandler}
                    cancelHandler={() => setOpenDialog(false)}
                    content="确定要删除这个项目吗？"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="my-4 text-gray-500">
            当前使用许可不包括项目设置功能
          </div>
        )}
      </MainContentWithTitle>
      <GerritHelp
        show={showGerritCreateAccountHelp}
        set={setShowGerritCreateAccountHelp}
        settings={gerritSettings}
      />
      <GitlabHelp
        show={showGitlabCreateWebhookHelp}
        set={setShowGitlabCreateWebhookHelp}
        webhookProxy={webhookProxy}
      />
      <GithubHelp
        show={showGithubCreateWebhookHelp}
        set={setShowGithubCreateWebhookHelp}
        webhookProxy={webhookProxy}
      />
    </ProjectLayout>
  );
}

export default ProjectSettings;
