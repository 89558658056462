import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { api } from "../api";
import { HelpText, TextAreaField, TextField } from "../uilib/forms";
import SlideOver from "./SlideOver";

export interface GerritSettings extends api.GerritSettings {
  sshPublicKey: string;
  eventType: string;
}

function makeValidGerritPort(p: number): number {
  if (isNaN(p) || p <= 0 || p > 65535) {
    return 29418;
  }
  return p;
}

function makeValidGerritUsername(u: string): string {
  if (u === "" || u.length > 255) {
    return "naivesystems";
  }
  return u;
}

export function GerritSettingsForm(props: {
  settings: GerritSettings;
  updateSettings: (_: GerritSettings) => void;
  usernameError: string;
  setUsernameError: (_: string) => void;
  hostError: string;
  setHostError: (_: string) => void;
  portError: string;
  setPortError: (_: string) => void;
  projectError: string;
  setProjectError: (_: string) => void;
  branchError: string;
  setBranchError: (_: string) => void;
}) {
  return (
    <>
      <div className="sm:col-span-12">
        <TextAreaField
          rows={1}
          id="gerrit-ssh-public-key"
          value={props.settings.sshPublicKey}
        >
          SSH 公钥
        </TextAreaField>
        <HelpText>请将该公钥添加到用于访问 Gerrit 的账户中</HelpText>
      </div>

      <div className="sm:col-span-4">
        <TextField
          id="gerrit-username"
          value={props.settings.username}
          onChange={(v) =>
            props.updateSettings({ ...props.settings, username: v.trim() })
          }
          error={props.usernameError}
          setError={props.setUsernameError}
        >
          Gerrit 账户名
        </TextField>
      </div>
      <div className="sm:col-span-5">
        <TextField
          id="gerrit-host"
          value={props.settings.host}
          onChange={(v) =>
            props.updateSettings({ ...props.settings, host: v.trim() })
          }
          error={props.hostError}
          setError={props.setHostError}
        >
          服务器地址
        </TextField>
      </div>
      <div className="sm:col-span-3">
        <TextField
          id="gerrit-port"
          type="number"
          value={props.settings.port}
          onChange={(v) =>
            props.updateSettings({ ...props.settings, port: parseInt(v) })
          }
          error={props.portError}
          setError={props.setPortError}
        >
          端口
        </TextField>
      </div>

      <div className="sm:col-span-4">
        <TextField
          id="gerrit-project"
          value={props.settings.project}
          onChange={(v) =>
            props.updateSettings({ ...props.settings, project: v.trim() })
          }
          error={props.projectError}
          setError={props.setProjectError}
        >
          Gerrit 项目 ID
        </TextField>
      </div>
      <div className="sm:col-span-4">
        <TextField
          id="gerrit-branch"
          value={props.settings.branch}
          onChange={(v) =>
            props.updateSettings({ ...props.settings, branch: v.trim() })
          }
          error={props.branchError}
          setError={props.setBranchError}
        >
          分支
        </TextField>
      </div>
      <div className="sm:col-span-4">
        <TextField id="gerrit-event-type" value={props.settings.eventType}>
          触发事件类型
        </TextField>
      </div>

      {/* TODO(b/10600): labels and scores are also configurable in Gerrit */}
    </>
  );
}

export function GerritHelp(props: {
  show: boolean;
  set: (_: React.SetStateAction<boolean>) => void;
  settings: GerritSettings;
}) {
  return (
    <SlideOver
      open={props.show}
      setOpen={props.set}
      title="如何创建一个专用于代码扫描的 Gerrit 账户？"
    >
      <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <p className="text-sm text-yellow-700">
              该操作必须由<span className="mx-0.5">Gerrit</span>管理员执行。
            </p>
          </div>
        </div>
      </div>
      <div className="text-sm text-gray-700">
        <p className="mt-4">
          假设平时从<span className="mx-0.5">Gerrit</span>克隆仓库的命令是：
          <pre className="mt-2 whitespace-pre-wrap break-all rounded-md bg-gray-100 px-3 py-2 text-gray-500">
            <code>
              git clone ssh://
              <span className="bg-lime-100">admin@hostname</span>:
              <span className="bg-yellow-100">
                {makeValidGerritPort(props.settings.port)}
              </span>
              /my_proj.git
            </code>
          </pre>
        </p>
        <p className="mt-4">
          先运行下面的命令，确认可以正常连接。
          <pre className="mt-2 whitespace-pre-wrap break-all rounded-md bg-gray-100 px-3 py-2 text-gray-500">
            <code>
              ssh -p
              <span className="bg-yellow-100">
                {makeValidGerritPort(props.settings.port)}
              </span>{" "}
              <span className="bg-lime-100">admin@hostname</span> gerrit version
            </code>
          </pre>
        </p>
        <p className="mt-4">
          如果此前没有创建过专用于代码扫描的
          <span className="mx-0.5">Gerrit</span>
          账户，可以运行下面的命令。新创建的账户名为
          <code className="ml-0.5 text-sky-700">
            {makeValidGerritUsername(props.settings.username)}
          </code>
          。
          <pre className="mt-2 whitespace-pre-wrap break-all rounded-md bg-gray-100 px-3 py-2 text-gray-500">
            <code>
              ssh -p
              <span className="bg-yellow-100">
                {makeValidGerritPort(props.settings.port)}
              </span>{" "}
              <span className="bg-lime-100">admin@hostname</span> gerrit
              create-account{" "}
              <span className="text-sky-700">
                {makeValidGerritUsername(props.settings.username)}
              </span>{" "}
              "<span className="text-red-700">--full-name=</span>'Analyze'" "
              <span className="text-red-700">--group=</span>'Service Users'" "
              <span className="text-red-700">--ssh-key=</span>'
              {window.apiPortalCreateProject.buildbotPublicKey}'"
            </code>
          </pre>
        </p>
        <p className="mt-4">
          如果此前已经创建过专用于代码扫描的
          <span className="mx-0.5">Gerrit</span>
          账户（例如
          <code className="ml-0.5 text-sky-700">
            {makeValidGerritUsername(props.settings.username)}
          </code>
          ），可以运行下面的命令为该账户添加新的
          <code className="mx-0.5">SSH</code>公钥：
          <pre className="mt-2 whitespace-pre-wrap break-all rounded-md bg-gray-100 px-3 py-2 text-gray-500">
            <code>
              ssh -p
              <span className="bg-yellow-100">
                {makeValidGerritPort(props.settings.port)}
              </span>{" "}
              <span className="bg-lime-100">admin@hostname</span> gerrit
              set-account{" "}
              <span className="text-sky-700">
                {makeValidGerritUsername(props.settings.username)}
              </span>{" "}
              "<span className="text-red-700">--add-ssh-key=</span>'
              {window.apiPortalCreateProject.buildbotPublicKey}'"
            </code>
          </pre>
        </p>
      </div>
    </SlideOver>
  );
}
