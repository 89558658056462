/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import { PropsWithChildren } from "react";

export function BasicButton(
  props: PropsWithChildren<{
    icon?: (props: any) => JSX.Element;
    disabled?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
  }>
) {
  return (
    <button
      className={
        "inline-flex items-center rounded-md border border-gray-300 px-3 py-2 text-sm font-medium leading-4 shadow-sm focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2" +
        (props.disabled
          ? " bg-gray-100 text-gray-300"
          : " bg-white text-gray-700 hover:bg-gray-50")
      }
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.icon && (
        <props.icon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
      )}
      {props.children}
    </button>
  );
}
