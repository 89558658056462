/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

interface Charset {
  name: string;
  displayName: string;
}

export const CharsetList: Charset[] = [
  { name: "utf8", displayName: "UTF-8" },
  { name: "gbk", displayName: "GBK/GB2312" },
  { name: "latin1", displayName: "ISO-8859-1" },
  { name: "us", displayName: "ASCII" },
];
