/*
Copyright 2023 Naive Systems Ltd.

This software contains information and intellectual property that is
confidential and proprietary to Naive Systems Ltd. and its affiliates.
*/

import { RadioGroup } from "@headlessui/react";
import { ArrowDownTrayIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { CodeBlock, CreateRunnerStep, RatioButton } from "../components/Steps";
import ProjectLayout from "../components/ProjectLayout";
import {
  BreadcrumbItem,
  Breadcrumbs,
  MainContentWithTitle,
} from "../uilib/layouts";
import { BasicButton } from "../components/BasicButton";
import { TextField } from "../uilib/forms";

interface APIPortalCreateRunner {
  customRunnersEnabled: boolean;
  projectName: string;
}

declare global {
  interface Window {
    apiPortalCreateRunner: APIPortalCreateRunner;
  }
}

function ProjectCreateRunner() {
  const [projectId, setProjectId] = useState("");
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);
  const [system, setSystem] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [label, setLabel] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("project_id") === null) {
      throw new Error("project id is missing");
    } else {
      setProjectId(params.get("project_id")!);
    }
    setBreadcrumbs([
      {
        name: window.apiPortalCreateRunner.projectName,
        href: "/project?id=" + params.get("project_id"),
        current: false,
      },
      {
        name: "执行器",
        href: "/runners",
        current: false,
      },
      {
        name: "添加执行器",
        href: "",
        current: true,
      },
    ]);
  }, []);

  return (
    <ProjectLayout>
      <Breadcrumbs pages={breadcrumbs} />
      <MainContentWithTitle title="添加执行器">
        {window.apiPortalCreateRunner.customRunnersEnabled ? (
          <>
            <CreateRunnerStep
              id={1}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              stepDisplay="01"
              stepTitle="请选择操作系统"
              disabled={system === ""}
            >
              <RadioGroup value={system} onChange={setSystem}>
                <RatioButton value="linux" first>
                  Linux
                </RatioButton>
                <RatioButton value="windows">Windows</RatioButton>
                <RatioButton value="macOS">macOS</RatioButton>
              </RadioGroup>
            </CreateRunnerStep>
            <CreateRunnerStep
              id={2}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              stepDisplay="02"
              stepTitle="下载NaiveSystems Analyze执行器"
            >
              <div className="mb-4">
                下载NaiveSystems Analyze执行器文件并将其添加至项目根目录。
              </div>
              <BasicButton icon={ArrowDownTrayIcon}>
                下载NaiveSystems Analyze执行器
              </BasicButton>
            </CreateRunnerStep>
            <CreateRunnerStep
              id={3}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              stepDisplay="03"
              stepTitle="你为分析而建立的podman图像的标签是什么？"
            >
              <TextField
                id="podman-label"
                value={label}
                onChange={setLabel}
              ></TextField>
            </CreateRunnerStep>
            <CreateRunnerStep
              id={4}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              stepDisplay="04"
              stepTitle="用以下命令运行执行器"
            >
              <CodeBlock
                code={`./runner -logtostderr \\\n  -api_service_url serviceURL \\\n  -api_access_key runnerKey \\\n  -analyzer-image TODO \\\n  -project_type TODO`}
              />
            </CreateRunnerStep>
            <CreateRunnerStep
              id={5}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              stepDisplay="05"
              stepTitle="准备完成"
              last
            >
              <div className="mb-4">执行器已经准备完成。</div>
              <div>
                请访问
                <a
                  href={`/prerelease?project_id=${projectId}`}
                  className="text-blue-500"
                >
                  扫描任务
                </a>
                以查看状态
              </div>
            </CreateRunnerStep>
          </>
        ) : (
          <div className="my-4 text-gray-500">
            当前使用许可不包括自定义执行器功能
          </div>
        )}
      </MainContentWithTitle>
    </ProjectLayout>
  );
}

export default ProjectCreateRunner;
